import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Section } from '../../StatePopup/Section';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ImBin } from "react-icons/im";
import './styles.css';
import { getStateToolTipInfo } from './helper';

export const StateColumnComponent = ({ stateObject, last, padding, width, setSelectedStates }) => {
    const [age, setAge] = React.useState('');
    const deleteState = () => {
        
        if (localStorage.getItem("favStates")) {
            let tmp = localStorage.getItem("favStates").split(",");
            tmp = tmp.filter(value => value !== stateObject.FIPSState.toString());
            localStorage.setItem("favStates", tmp);
            setSelectedStates(tmp);
        }
    }
    return (
        <Grid className='tableColumn' sx={{ marginLeft:"5px", minWidth: 220, width: width, paddingRight: padding, borderRight: last === "last" ? "" : "1px solid rgba(0, 0, 0, 0.12)" }}>
            <Stack direction="row" alignItems="center" sx={{justifyContent: 'space-between'}}>
                <div className='tableHeader'>
                    <h4 className='tableStateName'><u>{stateObject.name}</u></h4>
                    <Typography sx={{display:"flex"}}>
                        <Tooltip disableInteractive title={getStateToolTipInfo(stateObject.name)}>
                            <HelpOutlineIcon className='tableQuetionMark' sx={{marginLeft:"2px", width:"0.6em"}} />
                        </Tooltip>
                    </Typography>
                </div>
                <ImBin className='binIcon' onClick={deleteState}/>
            </Stack>
            <Stack sx={{paddingBottom: '20px'}}>
                <Section
                    heading={"Code"}
                    values={[stateObject.abrv]}
                />
                <Section
                    heading={"Sub Region"}
                    values={[stateObject.subRegion]}
                />
                <Section
                    heading={"2019 Population"}
                    values={[stateObject.population]}
                />
                <Section
                    heading={"Most Populous Metro Areas"}
                    values={
                        [stateObject.m1 + ", " + stateObject.p1, stateObject.m2 + ", " + stateObject.p2, stateObject.m3 + ", " + stateObject.p3]
                    }
                />
                <Section
                    heading={"Total table wine consumption (’000 9L cases)"}
                    values={[stateObject.totalTableWineConsumption9LCases]}
                />
                <Section
                    heading={"Per-capita wine consumption (L/person)"}
                    values={[stateObject.perCapitaWineConsumption]}
                />
                <Section
                    heading={"Imported share of table wine"}
                    values={[stateObject.importedShareTableWine]}
                />
                <Section
                    heading={"Australian share of imported table wine"}
                    values={[stateObject.australianShareImportedTableWine]}
                />
                <Section
                    heading={"Specialist Wine Chains"}
                    values={[stateObject.specialistWineChains]}
                />
                <Section
                    heading={"Stability of price point Below $7.50"}
                    values={[stateObject.below750TextualContent]}
                />
                <Section
                    heading={"Stability of price point $7.50 to $14.99"}
                    values={[stateObject.between750_1499TextualContent]}
                />
                <Section
                    heading={"Stability of price point $15 & Above"}
                    values={[stateObject.fifteenAndAboveTextualContent]}
                />
            </Stack>
        </Grid>
    )
}