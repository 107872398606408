import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider, Typography } from '@mui/material';
import { savePDF, printPDF, printComparisonTable } from "../../PDF/ProvinceGeneration";
import {ReactComponent as PrinterIcon} from '../../assets/images/print@1.svg';
import './style.css';
import { IoIosArrowDown } from "react-icons/io";

export function PDFMenu({ formData, forceUpdate, stateData, selectedStateData }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClickOption = (selected, print, printComparison) => {
        let res = [],
            data = null;

        var selectedStatesFromFilter = [];

        
        for (let i = 0; i < selectedStateData.length; i++) {
            let foundState = stateData.find(s => s.FIPSState === parseInt(selectedStateData[i]))
            res.push(foundState)
        }

        if(selected == 0) {
            var state = null;
            var safeToAdd = true;
            
            for(var i = 0; i < stateData.length; i++) {
                safeToAdd = true;
                state = stateData[i];

                if(formData.directlySelfDistributingToRetailers) {
                    if(!state.directlySelfDistributingToRetailers) {
                        safeToAdd = false;
                    }
                }
                if(formData.statesAllowSalesDTCFromOutOfStateRetailers) {
                    if(!state.statesAllowSalesDTCFromOutOfStateRetailers) {
                        safeToAdd = false;
                    }
                }
                if(formData.sellingDTCViaIntrastateRetailers) {
                    if(!state.sellingDTCViaIntrastateRetailers) {
                        safeToAdd = false;
                    }
                }
                if(formData.sellingDirectlyToUSConsumersMyself) {
                    if(!state.sellingDirectlyToUSConsumersMyself) {
                        safeToAdd = false;
                    }
                }
                if(formData.sellingToRetailersWhereTheRetailerSelfDistributes) {
                    if(!state.sellingToRetailersWhereTheRetailerSelfDistributes) {
                        safeToAdd = false;
                    }
                }
                if(formData.sellingThroughThreeTierDistribution) {
                    if(!state.sellingThroughThreeTierDistribution) {
                        safeToAdd = false;
                    }
                }
                if(formData.shippingViaLibDib) {
                    if(!state.shippingViaLibDib) {
                        safeToAdd = false;
                    }
                }
                if(formData.havingPotentialToShipViaVivino247Solution) {
                    if(!state.havingPotentialToShipViaVivino247Solution) {
                        safeToAdd = false;
                    }
                }
                if(formData.havingPotentialToShipViaWineDotCom) {
                    if(!state.havingPotentialToShipViaWineDotCom) {
                        safeToAdd = false;
                    }
                }
                if(formData.nonControlStateForWine) {
                    if(!state.nonControlStateForWine) {
                        safeToAdd = false;
                    }
                }
                if(formData.nonFranchiseStateForWine) {
                    if(!state.nonFranchiseStateForWine) {
                        safeToAdd = false;
                    }
                }

                if(safeToAdd) {
                    selectedStatesFromFilter.push(state);
                }
            }
        }
        
        switch (selected) {
            case 0: data = selectedStatesFromFilter; break;
            case 1: data = res; break;
            case 2: data = stateData; break;
            case 3: data = res; break;
        }

        if(!printComparison) {
            print ? printPDF(data, true) : savePDF(data, true);
        } else {
            printComparisonTable(data);
        }
        
        handleClose()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => { }, [forceUpdate]);

    return (
        <div className='pdfMenu'>
            <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{
                    border: '1px solid white', 
                    background: "transparent",
                    color: "black",
                    marginBottom: '10px',
                    position: 'absolute',
                    top: 65, 
                    zIndex: 1000, 
                    right: 20, 
                    backgroundColor: 'white', 
                    borderRadius: '5px',
                    fontSize: '0.8vw',
                    width: '13vw',
                    padding: '7.5px 0px'
                }}
            >
                <PrinterIcon className='icon'/><Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw !important', margin: '0px !important' }}>Printing Options </Typography><IoIosArrowDown className='icon arrowIcon' style={{marginLeft: '5px', marginRight: '-7px'}}/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem className='printOption' onClick={() => handleClickOption(2, false, false)}>Save All States</MenuItem>
                <MenuItem className='printOption' onClick={() => handleClickOption(2, true, false)}>Print All States</MenuItem>
                <Divider className='printDivider'/>
                <MenuItem className='printOption' onClick={() => handleClickOption(3, true, true)}>Print Comparison Table</MenuItem>
            </Menu>
        </div>
    );
}