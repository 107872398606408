import React, { useState, useEffect } from 'react';
import { API_URL } from "../../../App";
import axios from 'axios';

const MenuItemsEditor = () => {
    const [menuItems, setMenuItems] = useState([]);


    useEffect(() => {
        // Fetch the menu items when the component mounts
        axios.get(`${API_URL}/api/cms/menu-items`)
            .then(response => {
                setMenuItems(response.data.components);
            })
            .catch(error => {
                console.error('There was an error fetching the menu items!', error);
            });
    }, []);

    const handleNameChange = (index, newName) => {
        const newMenuItems = [...menuItems];
        newMenuItems[index].name = newName;
        setMenuItems(newMenuItems);
    };

    const handleVisibilityChange = (index) => {
        const newMenuItems = [...menuItems];
        newMenuItems[index].isVisible = newMenuItems[index].isVisible === true ? false : true;
        setMenuItems(newMenuItems);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${API_URL}/api/cms/menu-items`, { menuItems })
            .then(response => {
                alert('Menu items updated successfully');
            })
            .catch(error => {
                console.error('There was an error updating the menu items!', error);
            });
    };

    return (
        <div className="editor-body" style={{ margin: "10px 5%" }}>
            <form onSubmit={handleSubmit}>
                {menuItems.map((menuItem, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <input
                            type="text"
                            value={menuItem.name}
                            style={{ width: '370px' }}
                            onChange={(e) => handleNameChange(index, e.target.value)}
                        />
                        <label style={{ marginLeft: '20px' }}>
                            <input
                                type="checkbox"
                                checked={menuItem.isVisible === true}
                                onChange={() => handleVisibilityChange(index)}
                            />
                            Visible
                        </label>
                    </div>
                ))}
                <button type="submit" id="saveBtn"
                    style={{ marginTop: "10px", fontSize: "18px" }}>Save</button>
            </form>
        </div>
    );
};

export default MenuItemsEditor;