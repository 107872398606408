import React, { useState, useEffect } from 'react';
import { CollapsableCard } from "./CollapsableCard"
import { Button, Drawer, List, Stack, ListItem, Typography } from '@mui/material';
import { API_URL } from "../../App";
import axios from 'axios';
import "./style.css"

const filters = ["State Laws"];
const filterValues = [
    [
        [
            "Non-Control State for Wine",
            "nonControlStateForWine",
            "Checkbox",
            false,
            (<Typography>Content</Typography>)
        ],
        [
            "Non-Franchise State for Wine",
            "nonFranchiseStateForWine",
            "Checkbox",
            false,
            (<Typography>Content</Typography>)
        ],
        [
            "States that allow sales DTC from Out of State Retailers",
            "statesAllowSalesDTCFromOutOfStateRetailers",
            "Checkbox",
            false,
            (<Typography>Content</Typography>)
        ],
        [
            "States that allow sales DTC from Intrastate Retailers",
            "statesAllowSalesDTCFromIntrastateRetailers",
            "Checkbox",
            false,
            (<Typography>Content</Typography>)
        ]
    ]
];
const heatFilters = ["Overlay", "Markers", "I'm Interested In", "Constraints"];

const heatFilterValues = [
    [
        ["Overlay", "Overlay", "Radio", [
            ["Show Median Household Income", "houseHoldIncome", `In the USA wine consumption increases with wealth and education. Higher median household income correlates with higher purchase frequency, higher wine involvement, higher curiosity, higher spend per occasion and higher use of online channels, (statistica.com, Wine Intelligence) `],
            ["Show County Population", "population"],
            ["Show Australia Visa Trends", "googleTrendsAustraliaVisa", `Searches for “Australian visa” on Google between 26/11/2020 and 25/112021.`],
            ["None", "none"],
        ]],
    ],
    [
        ["Show Large Wine Sellers", "ShowLargeSellers", "Checkbox", false, `Large Wine sellers are multi-store sellers that vary from state-to-state. Details of each of the multi-store sellers are included in the fact sheets for each state.`],
        ["Small wine restaurants and bars (from Google 2022)", "ShowSmallSellers", "Checkbox", false, `Small wine and sellers and wine bars are merchants that do not have a large chain presence.`],
        ["Small wine restaurants and bars (LeadsDeposit 2023)", "", "Title", false, `Small wine and sellers and wine bars are merchants that do not have a large chain presence.`],
        ["NorthEast", "ShowSmallSellers2023Northeast", "Checkbox", false],
        ["MidWest", "ShowSmallSellers2023Midwest", "Checkbox", false],
        ["South", "ShowSmallSellers2023South", "Checkbox", false],
        ["West", "ShowSmallSellers2023West", "Checkbox", false],

        ["Show Apple Stores", "ShowAppleStores", "Checkbox", false, `Apple store penetration is a function of both population and wealth. Apple places it stores in areas with sufficient spending power, high retail traffic and adjacency to other ‘popular luxury’ brands.`],

    ],
    [
        ["Directly self distributing to retailers", "directlySelfDistributingToRetailers", "Checkbox", false, `Direct self-distribution to retailers is targeted at smaller producers and subject to a range of rules and restrictions. If you are self-distributing, consider working with SevenFifty to support your sales and distribution efforts.
        In CA only licensed instate manufacturers and wine growers may sell directly to retailers.
        In CT only licensed wineries producing less than 100,000 gallons per year can self-distribute. 
        In DC by holders of an importation permit where the ABRA (Alcoholic Beverage Regulation Administration, District of Columbia) has been satisfied that the brands being distributed are not otherwise available.
        In IL by holders of a valid wine shippers license that do not produce more than 25,000 gallons of wine annually solely or combined with another group and does not sell more than 5,000 gallons to retailers annually.
        In ME only parties licensed as a small winery (who may not produce more than 50,000 gallons annually) may self-distribute.
        In MD by holders of a Non-Resident Winery Permit, who may not produce more than 27,500 gallons annually.
        In MO holders of an “Out of State Winery License” may sell to both wholesalers and retailers.
        In NJ wineries producing less than 250,000 gallons per year may apply for an Out-of-State winery license permitting DTC and direct to retail sales. Wineries can also become licensed as New Jersey wholesalers.
        In NY out of state wineries that establish a business location in New York can become licensed as a wholesaler with the right to distribute to other wholesalers and retailers.
        In ND, licensed wineries that produce no more than 50,000 gallons per year may apply for additional licensing to sell directly to retailers up to 4,500 cases per annum.
        In OH only wineries that qualify for a Federal Wine Tax credit and produce no more that 250,000 gallons per year can self-distribute.
        In OK wineries that produce no more than 15,000 gallons may apply for a Winemaker Self-Distribution License but only if the winery uses its own vehicles.
        In OR wineries holding a certificate of approval may apply for a Wine Self-Distribution license to retailers authorised by the OLCC (Oregon Liquor and Cannabis Commission).
        In TN any party granted a Tennessee Winery License can sell up to 5 cases of wine per day to any individual retailer.
        In VT holders of a Vermont Direct Ship to Retail License may sell up to 5,000 gallons a year directly to retailers, but no more than 100 gallons to any individual retailer.
        In WA holders of a Certificate of Approval can sell directly to retailers if they receive an additional permit.
        In WY holders of Out-of-State Shipper License for DTC shipping may also sell directly to Wyoming retailers, but only wines not already listed for sale by the Beverage Control Division.
        `, true],
        ["Selling DTC via Out of State Retailers", "statesAllowSalesDTCFromOutOfStateRetailers", "Checkbox", false, `Selling DTC via out-of-state retailers is a strategy that you can use to get into a state if you are already listed in a neighbouring state, either through your own DTC efforts, but more likely through an eCommerce or trade platform like Wine.com, Vivino or SevenFifty.`, true],
        ["Selling DTC via Intrastate Retailers", "sellingDTCViaIntrastateRetailers", "Checkbox", false, `Selling DTC via intrastate retailers is a strategy that you can within a state, if you are already distributed there, through your own DTC efforts or through an eCommerce or trade platform like Wine.com, Vivino or SevenFifty.`, true],
        ["Selling directly to US consumers myself", "sellingDirectlyToUSConsumersMyself", "Checkbox", false, `If you want to sell directly to consumers yourself through a DTC platform or your own eCommerce you can set up in places like California with a retail or winery license. Examples are:
        Type 85 online retail retailer can ship to 14 states 
        Type 17/20 retail license can ship to 16 states
        Type 02 under Alternating Proprietorship can ship to as many as 44 states
        Self-importers can set these structures up themselves, otherwise you will need to work with an importer who is structured to sell DTC through one of these mechanisms. If you choose to do this, seek your own legal advice before proceeding.
        `, true],
        ["Selling to retailers where the retailer self distributes", "sellingToRetailersWhereTheRetailerSelfDistributes", "Checkbox", false, `In the small number of states where you can sell directly to retail under some circumstances, there are opportunities for both large and small sellers.
        For large sellers, where there are very substantial direct to retail opportunities with large retailers, the retailer may choose to direct import, meaning that you do not need to build distribution infrastructure into that state.
        For small sellers, there is a practice in those states of specialist retailers going out to seek products that they want to stock and direct importing them. It may be possible for you to generate such an opportunity.
        `, true],
        ["Selling through 3 tier distribution", "sellingThroughThreeTierDistribution", "Checkbox", false, `In the USA, all wine producers must sell into the three-tier distribution system. This system consists of the production tier (wine producers and importers), the distribution tier and retail tier. It is generally not possible for one company to own businesses in more than one of the three tiers.
        However, there are a lot of exceptions, which is what makes the USA 51+ wine markets and at least 6 distinct channels (direct to consumer, eCommerce, traditional distribution, direct to retail, direct to monopoly and self-distribution) each market is a unique blend of entry rules.
        The purpose of this project is to help you to break the market down and make choices about what suits you. If you look at the populations of many individual states, you will see why we see it as 51 countries, not just 1.
        `, true],
        ["Having potential to ship via Wine.com", "havingPotentialToShipViaWineDotCom", "Checkbox", false, `Wine.com is headquartered in San Francisco, CA and operates Fulfillment Centres in seven states, listed by order of size: Westbury NY (Long Island), Berkeley CA, Medley, FL (Miami Metro), Houston TX, Maplewood NJ (New York Suburb), Avon MA (Boston Metro) and Columbus OH. These seven retail-licensed locations operate as distribution centres that legally sell and ship to over 96% of the U.S. wine drinking population. They operate under the Three-Tier Ecommerce (3TE) business model – as a licensed retailer, sourcing from local wholesalers in multiple states, carrying inventory, conducting pick/pack/ship operations, and delivering to the end-consumer. 
        “For items to be considered for our assortment, they must be available to us from state-licensed wholesalers who are capable of delivering to our Wine.com fulfillment centres on a weekly basis. Items are not required to be available in all markets but covering our two key markets of California and New York is critical for success. Note: Wine.com will not facilitate wholesaler or importer introductions on your behalf. 
        All Suppliers are required to submit vintage-specific content to support their items and brands—see Content Checklist file. If you are new to Wine.com and your items meet these criteria for addition to our assortment, email trade@wine.com with the following information: 
        •	Name of winery 
        •	Name of wine(s) 
        •	Importer (if applicable) 
        •	Licensed Wholesaler for our seven markets (NY, CA, TX, FL, NJ, MA, OH)”
        Wine.com requires and will link vintage-specific, ratings from Wine Spectator, Whiskey Advocate, Wine & Spirits, Robert Parker’s The Wine Advocate, Wine Enthusiast, Connoisseur’s Guide, Allen Meadows’ Burghound.com, The Tasting Panel, Decanter, JamesSuckling.com, Jeb Dunnuck, Vinous and or Tim Atkin. They also require good quality imagery, bottle shots and a brand video.
        Wilfred Wong is Wine.com’s Chief Storyteller. Email him at Wilfred Wong, Wine.com’s storyteller at wilfred@wine.com to determine if his schedule allows for reviewing your wine. His reviews are a filter search option, as well as other reviews, like Wine Spectator.
        `, true],
        ["Having potential to sell via Vivino", "havingPotentialToShipViaVivino247Solution", "Checkbox", false, `Wineries can be available to be purchased by consumers through various retailers (merchants) who are registered on Vivino or can set themselves up as merchants (marketplace partners) on Vivino if they are appropriately licensed to sell in the USA. Vivino takes advantage of a range of DTC laws including companies selling directly to US consumers, out of state shipping, intrastate shipping and direct importing to make products as widely available as possible. They will work with you to set up as effectively as possible including the 24Seven shipping labels solution in the USA. 
        Vivino has potential to work internationally, however it is best to get yourself set up in Australia as a Vivino merchant to prepare for your international launch, by contacting the Australian team:  james.fildes@vivino.com 
        `, true],
        ["Shipping via Lib Dib", "shippingViaLibDib", "Checkbox", false, `LibDib is a one-stop distribution partner that specialises in ‘innovative wine and spirits brands’ seeking to access new markets. It provides common carriers, just in time fulfillment and a platform for ordering and re-ordering by on- and off- trade.
        As of December 2021, LibDib was available in CA, CO, CT, FL, NY, WI, Il, TX and MD. They are growing quickly so it is worth checking on https://libdib.com/states/ where they are available
        Wine producers should check out LibDib here: https://libdib.com/sellers/ 
        `, true],
    ],
    [
        ["Non-Control State for Wine", "nonControlStateForWine", "Checkbox", false, `There are 17 states and four local jurisdictions that operate some form of control market for alcohol sales, both at wholesale and off-sale retail levels. Where retail is controlled as well as wholesale, the markets operate in a similar manner to the Scandinavian and Canadian provincial monopolies.
        The US control jurisdictions mostly control the sale of spirits. However, some control wine sales as well and four jurisdictions have government operated retail sales of wine (Pennsylvania, Utah, New Hampshire and Montgomery County, Maryland). 
        The most interesting of these is Pennsylvania, as its state control body, the Pennsylvania Liquor Control Board (PLCB), is one of the top five largest purchasers of wine in the world and it is one of the USA’s top five most populous states. Sales of wine there have been on the increase since grocery stores were authorised to sell wine on a limited basis in 2016 (the stores still must purchase wine only through the PLCB). 
        Giving consumers easier access to wine purchases has benefited the sales of wine generally. All of this makes the Pennsylvania market a target worth considering. It is not easy to obtain a listing with the state, but with a listing and an in-state marketing approach, it can be very profitable.
        (Adapted from Wine Australia Market Bulleting 240 “A brief guide to the USA’s three tier system”)
        `],
        ["Non-Franchise State for Wine", "nonFranchiseStateForWine", "Checkbox", false, `Franchise laws create a legal relationship between a seller or brand of wine and its in-state wholesaler that make it extremely difficult to terminate, change or refuse to sell to a wholesaler to whom you have previously sold in a particular market. 
        In combination with laws only allowing one wholesaler to sell a brand by market, a wine can be tied up with a wholesaler who does not necessarily have much buy-in to your brand. These laws exist in around 19 states for wine, including some key markets like Massachusetts and Nevada. 
        Many of the laws require large compensation payments for terminated wholesalers, or simply prohibit termination other than in exceptional circumstances. For example, in Georgia, if you wish to change wholesaler because they aren’t performing, you can be forced to leave the market for four years before changing. 
        (Adapted from Wine Australia Market Bulleting 240 “A brief guide to the USA’s three tier system”)
        `],
    ]
]
export const FilterDrawer = ({ formData, setFormData, setFilterChange, selectedPage, setSelectedPage, drawerWidth, open, DrawerHeader, mobileOpen, isLoading, setIsLoading }) => {
    const [selectedFilters, setSelectedFilters] = useState(filters)
    const [selectedFilterValues, setSelectedFilterValues] = useState(filterValues)
    const [toggleOpen, setToggleOpen] = useState(false);
    const [clickedReset, setClickedReset] = useState(false);
    var user = navigator.userAgent;

    const handleFormChange = (val) => {
        setFormData(val);
        setToggleOpen(!toggleOpen);
    };

    const handleReset = () => {
        let tempFormData = formData;
        heatFilterValues.map((temp) => {
            temp.map((value) => {
                switch (value[2]) {
                    case "Checkbox": tempFormData[value[1]] = value[3]; break;
                    case "Toggle": tempFormData[value[1]] = value[3]; break;
                    case "Radio": tempFormData[value[1]] = value[3][0][1]; break;
                }
            })
        });

        setFormData(tempFormData)
        setToggleOpen(!toggleOpen);
        setClickedReset(true);
        setFilterChange();
    };

    useEffect(() => {
        let tempFormData = formData;

        filterValues.map((temp) => {
            temp.map((value) => {
                switch (value[2]) {
                    case "Checkbox": tempFormData[value[1]] = value[3]; break;
                    case "Toggle": tempFormData[value[1]] = value[3]; break;
                    case "Radio": tempFormData[value[1]] = value[3][0][1]; break;
                }
            })
        })
        heatFilterValues.map((temp) => {
            temp.map((value) => {
                switch (value[2]) {
                    case "Checkbox": tempFormData[value[1]] = value[3]; break;
                    case "Toggle": tempFormData[value[1]] = value[3]; break;
                    case "Radio": tempFormData[value[1]] = value[3][0][1]; break;
                }
            })
        });

        var interestedInMenuItems = heatFilterValues[2];

        axios.get(`${API_URL}/api/cms/menu-items`)
            .then(response => {
                response.data.components.map((item, index) => {
                    interestedInMenuItems[index][0] = item.name;
                    interestedInMenuItems[index][5] = item.isVisible;
                })

            })
            .catch(error => {
                console.error('There was an error fetching the menu items!', error);
            });
        heatFilterValues[2] = interestedInMenuItems;
        setFormData(tempFormData);


    }, []);

    const [alignment, setAlignment] = useState('heat');

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment) {
            setSelectedPage(newAlignment);
        }
    };

    useEffect(() => {
        setSelectedFilters(heatFilters)
        setSelectedFilterValues(heatFilterValues)
        setAlignment(selectedPage)
    }, [selectedPage]);

    return (
        <Drawer
            className='filterDrawer'
            sx={{
                width: "27.5vw",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "27.5vw",
                },
                height: "calc(100% - 140px)",
                overflow: "hidden",
                zIndex: 86
            }}
            variant="persistent"
            anchor="right"
            open={window.matchMedia('(orientation: portrait)').matches && (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) ? mobileOpen : open}
        >
            <DrawerHeader className='drawerHeader' sx={{ backgroundColor: 'white', marginBottom: '24px' }}></DrawerHeader>

            <List className='filterList' sx={{
                overflow: "auto", backgroundColor: '#f9f9f9'
            }}>
                {selectedFilters.map((label, index) =>
                    <ListItem className='filterDropDown' key={index} sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
                        <CollapsableCard
                            title={label}
                            selectedFilterValues={selectedFilterValues}
                            reset={toggleOpen}
                            index={index}
                            setFormData={handleFormChange}
                            formData={formData}
                            clickedReset={clickedReset}
                            setClickedReset={(val) => setClickedReset(val)}
                        />
                    </ListItem>
                )}
            </List>
            <Stack className='buttons' sx={{ width: '95%', margin: "15px auto" }} spacing={0} direction="row">
                <Button className='reset' variant="contained" sx={{ margin: "auto", width: "45%", minWidth: "150px", backgroundColor: "white" }} onClick={handleReset}>
                    <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                        Reset Filters
                    </Typography>
                </Button>
                <Button className='apply' variant="contained" sx={{ margin: "auto", width: "45%", minWidth: "150px" }} onClick={() => {
                    if (formData && (formData.ShowSmallSellers || formData.ShowSmallSellers2023Midwest || formData.ShowSmallSellers2023Northeast || formData.ShowSmallSellers2023South || formData.ShowSmallSellers2023West)) {
                        setIsLoading(true);
                    }
                    setFilterChange();
                }}>
                    <Typography variant="body2" color={"white"} sx={{ fontSize: '0.8vw' }} style={{ display: isLoading ? 'none' : 'block' }}>
                        Apply
                    </Typography>
                    <div class="loader" style={{ display: isLoading ? 'block' : 'none' }}></div>
                </Button>
            </Stack>
        </Drawer>
    )
}