import { createTheme  } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgb(120 191 69)',
      light: "rgb(143 230 81)",
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: 'rgb(180, 30, 142)',
      highlight:"#db3e9c"
    },
    background: {
      default: '#FEFEFE',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#55e05a',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
  typography: {
    fontFamily: 'Gadugi',
    h1: {
      fontWeight: 1000,
    },
    h2: {
      fontWeight: 1000,
    },
    h4: {
      fontWeight: 1000,
    },
    h3: {
      fontWeight: 1000,
    },
    h5: {
      fontWeight: 1000,
    },
    h6: {
      fontWeight: 1000,
    },
  },
  shape: {
    borderRadius: 4,
  },
});