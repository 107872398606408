import L from "leaflet";
import {
    getGenericStateColors,
    getHavingPotentialToShipViaVivino247SolutionStateColors,
    getHavingPotentialToShipViaWineDotComStateColors,
    getNonControlStateForWineStateColors,
    getNonFranchiseStateForWineStateColors,
    getSelfDistributionStateColors,
    getSellingDirectlyToUSConsumersMyselfStatesOverlayStateColors,
    getSellingDTCViaOutOfStateRetailersStateColors,
    getSellingThroughThreeTierDistributionStateColors,
    getSellingToRetailersWhereTheRetailerSelfDistributesStateColors,
    getShippingViaLibDibStateColors,
    getStatesAllowDTCFromUSWineryStateColors,
    getStatesAllowSalesDTCFromIntrastateRetailersStateColors
} from "./heatMapColorSelection";

export const prepSelfDistributionStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getSelfDistributionStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
            //add this line // Line below causes issues when navigating between overlays
            //layer._leaflet_id = feature.id;
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay);
    setOverlay(group);
};

export const prepNonControlStateForWineStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getNonControlStateForWineStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepSellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getSellingToRetailersWhereTheRetailerSelfDistributesStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepSellingDTCViaOutOfStateRetailersStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getSellingDTCViaOutOfStateRetailersStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepNonFranchiseStateForWineStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getNonFranchiseStateForWineStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
            //add this line // Line below causes issues when navigating between overlays
            //layer._leaflet_id = feature.id;
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepStatesAllowSalesDTCFromIntrastateRetailersStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getStatesAllowSalesDTCFromIntrastateRetailersStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
            //add this line // Line below causes issues when navigating between overlays
            //layer._leaflet_id = feature.id;
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepSellingDirectlyToUSConsumersMyselfStatesOverlayStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getSellingDirectlyToUSConsumersMyselfStatesOverlayStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
            //add this line // Line below causes issues when navigating between overlays
            //layer._leaflet_id = feature.id;
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepStatesAllowDTCFromUSWineryStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getStatesAllowDTCFromUSWineryStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepSellingThroughThreeTierDistributionStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getSellingThroughThreeTierDistributionStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepHavingPotentialToShipViaWineDotComStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getHavingPotentialToShipViaWineDotComStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepHavingPotentialToShipViaVivino247SolutionStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend) => {
    function styleStates(feature) {
        return {
            fillColor: getHavingPotentialToShipViaVivino247SolutionStateColors(feature.properties, googleTrends),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepShippingViaLibDibStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend, additionalFilters = []) => {
    function styleStates(feature) {
        return {
            fillColor: getShippingViaLibDibStateColors(feature.properties, googleTrends, additionalFilters),
            weight: 1,
            opacity: 0.5,
            color: "#fff",
            fillOpacity: 0.8
        };
    };

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(myoverlay)
    setOverlay(group);
};

export const prepGenericStatesOverlay = (googleTrends, allStates, setOverlay, setSelectedStateFromHeatMap, myoverlay, mylegend, additionalFilters = []) => {
    function styleStates(feature) {
        return {
            fillColor: getGenericStateColors(feature.properties, googleTrends, additionalFilters),
            weight: 1,
            opacity: 0.5,
            stroke: true,
            color: 'black',
            dashArray: '',
            fillOpacity: 0.65
        };
    };

    var borderLine = L.geoJSON(allStates, {
        style: {
            weight: 1,
            color: "black",
            fillColor: "#ece1cb",
            fillOpacity: 0
        }
    });

    myoverlay = L.geoJSON(allStates, {
        style: styleStates,
        onEachFeature: function (feature, layer) {
            layer.on({
                click: function () {
                    setSelectedStateFromHeatMap(feature.properties.name);
                }
            });
        }
    });

    let group = new L.FeatureGroup();
    group.addLayer(borderLine);
    group.addLayer(myoverlay);
    setOverlay(group);
};