import { AK_String } from "../assets/images_as_base64/states/AK";
import { AL_String } from "../assets/images_as_base64/states/AL";
import { AR_String } from "../assets/images_as_base64/states/AR";
import { AZ_String } from "../assets/images_as_base64/states/AZ";
import { CA_String } from "../assets/images_as_base64/states/CA";
import { CO_String } from "../assets/images_as_base64/states/CO";
import { CT_String } from "../assets/images_as_base64/states/CT";
import { DC_String } from "../assets/images_as_base64/states/DC";
import { DE_String } from "../assets/images_as_base64/states/DE";
import { FL_String } from "../assets/images_as_base64/states/FL";
import { GA_String } from "../assets/images_as_base64/states/GA";
import { HI_String } from "../assets/images_as_base64/states/HI";
import { IA_String } from "../assets/images_as_base64/states/IA";
import { ID_String } from "../assets/images_as_base64/states/ID";
import { IL_String } from "../assets/images_as_base64/states/IL";
import { IN_String } from "../assets/images_as_base64/states/IN";
import { KS_String } from "../assets/images_as_base64/states/KS";
import { KY_String } from "../assets/images_as_base64/states/KY";
import { LA_String } from "../assets/images_as_base64/states/LA";
import { MA_String } from "../assets/images_as_base64/states/MA";
import { MD_String } from "../assets/images_as_base64/states/MD";
import { ME_String } from "../assets/images_as_base64/states/ME";
import { MI_String } from "../assets/images_as_base64/states/MI";
import { MN_String } from "../assets/images_as_base64/states/MN";
import { MO_String } from "../assets/images_as_base64/states/MO";
import { MS_String } from "../assets/images_as_base64/states/MS";
import { MT_String } from "../assets/images_as_base64/states/MT";
import { NC_String } from "../assets/images_as_base64/states/NC";
import { ND_String } from "../assets/images_as_base64/states/ND";
import { NE_String } from "../assets/images_as_base64/states/NE";
import { NH_String } from "../assets/images_as_base64/states/NH";
import { NJ_String } from "../assets/images_as_base64/states/NJ";
import { NM_String } from "../assets/images_as_base64/states/NM";
import { NV_String } from "../assets/images_as_base64/states/NV";
import { NY_String } from "../assets/images_as_base64/states/NY";
import { OH_String } from "../assets/images_as_base64/states/OH";
import { OK_String } from "../assets/images_as_base64/states/OK";
import { OR_String } from "../assets/images_as_base64/states/OR";
import { PA_String } from "../assets/images_as_base64/states/PA";
import { RI_String } from "../assets/images_as_base64/states/RI";
import { SC_String } from "../assets/images_as_base64/states/SC";
import { SD_String } from "../assets/images_as_base64/states/SD";
import { TN_String } from "../assets/images_as_base64/states/TN";
import { TX_String } from "../assets/images_as_base64/states/TX";
import { UT_String } from "../assets/images_as_base64/states/UT";
import { VA_String } from "../assets/images_as_base64/states/VA";
import { VT_String } from "../assets/images_as_base64/states/VT";
import { WA_String } from "../assets/images_as_base64/states/WA";
import { WI_String } from "../assets/images_as_base64/states/WI";
import { WV_String } from "../assets/images_as_base64/states/WV";
import { WY_String } from "../assets/images_as_base64/states/WY";

export const getImageForState = (state) => {
    if(state.abrv == "DC") return DC_String();
    if(state.abrv == "AK") return AK_String();
    if(state.abrv == "AL") return AL_String();
    if(state.abrv == "AR") return AR_String();
    if(state.abrv == "AZ") return AZ_String();
    if(state.abrv == "CA") return CA_String();
    if(state.abrv == "CO") return CO_String();
    if(state.abrv == "CT") return CT_String();
    if(state.abrv == "DE") return DE_String();
    if(state.abrv == "FL") return FL_String();
    if(state.abrv == "GA") return GA_String();
    if(state.abrv == "HI") return HI_String();
    if(state.abrv == "IA") return IA_String();
    if(state.abrv == "ID") return ID_String();
    if(state.abrv == "IL") return IL_String();
    if(state.abrv == "IN") return IN_String();
    if(state.abrv == "KS") return KS_String();
    if(state.abrv == "KY") return KY_String();
    if(state.abrv == "LA") return LA_String();
    if(state.abrv == "MA") return MA_String();
    if(state.abrv == "MD") return MD_String();
    if(state.abrv == "ME") return ME_String();
    if(state.abrv == "MI") return MI_String();
    if(state.abrv == "MN") return MN_String();
    if(state.abrv == "MO") return MO_String();
    if(state.abrv == "MS") return MS_String();
    if(state.abrv == "MT") return MT_String();
    if(state.abrv == "NC") return NC_String();
    if(state.abrv == "ND") return ND_String();
    if(state.abrv == "NE") return NE_String();
    if(state.abrv == "NH") return NH_String();
    if(state.abrv == "NJ") return NJ_String();
    if(state.abrv == "NM") return NM_String();
    if(state.abrv == "NV") return NV_String();
    if(state.abrv == "NY") return NY_String();
    if(state.abrv == "OH") return OH_String();
    if(state.abrv == "OK") return OK_String();
    if(state.abrv == "OR") return OR_String();
    if(state.abrv == "PA") return PA_String();
    if(state.abrv == "RI") return RI_String();
    if(state.abrv == "SC") return SC_String();
    if(state.abrv == "SD") return SD_String();
    if(state.abrv == "TN") return TN_String();
    if(state.abrv == "TX") return TX_String();
    if(state.abrv == "UT") return UT_String();
    if(state.abrv == "VA") return VA_String();
    if(state.abrv == "VT") return VT_String();
    if(state.abrv == "WA") return WA_String();
    if(state.abrv == "WI") return WI_String();
    if(state.abrv == "WV") return WV_String();
    if(state.abrv == "WY") return WY_String();
    
}