import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../App";
import "./stateList.css";

const StateList = (param) => {
  const navigate = useNavigate();
  var user = navigator.userAgent;
  const [stateData, setStateData] = useState([]);
  const [checkboxChanges, setCheckboxChanges] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/api/states`)
      .then((res) => res.json())
      .then((res) => {
        setStateData(res.data);
      });
  }, []);

  const handleCheckboxChange = (abrv, isChecked) => {
    setCheckboxChanges((prevChanges) => ({
      ...prevChanges,
      [abrv]: isChecked,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      setSaving(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(checkboxChanges),
      };

      var url = "";
      if (param.type === "havingPotentialToShipViaWineDotCom") {
        url = "update-state-wine";
      } else if (param.type === "havingPotentialToShipViaVivino247Solution") {
        url = "update-state-vivino";
      } else if (param.type === "shippingViaLibDib") {
        url = "/update-state-libdib";
      } else if (param.type === "nonControlStateForWine") {
        url = "/update-state-nonControl";
      } else if (param.type === "nonFranchiseStateForWine") {
        url = "/update-state-nonFranchise";
      } else if (param.type === "directlySelfDistributingToRetailers") {
        url = "/update-state-directlySelfDistributingToRetailers";
      } else if (param.type === "statesAllowSalesDTCFromOutOfStateRetailers") {
        url = "/update-state-statesAllowSalesDTCFromOutOfStateRetailers";
      } else if (param.type === "sellingDTCViaIntrastateRetailers") {
        url = "/update-state-sellingDTCViaIntrastateRetailers";
      } else if (param.type === "sellingDirectlyToUSConsumersMyself") {
        url = "/update-state-sellingDirectlyToUSConsumersMyself";
      } else if (param.type === "sellingToRetailersWhereTheRetailerSelfDistributes") {
        url = "/update-state-sellingToRetailersWhereTheRetailerSelfDistributes";
      } else if (param.type === "sellingThroughThreeTierDistribution") {
        url = "/update-state-sellingThroughThreeTierDistribution";
      }

      fetch(`${API_URL}/api/states/${url}`, requestOptions)
        .then((data) => {
          setSaving(false);
          alert("Updated successfully");
        })
        .catch((err) => {
          setSaving(false);
          alert("Update failed, please try again later");
        });
      console.log("States updated successfully");
    } catch (error) {
      console.error("Error updating states:", error);
    }
  };

  return (
    <div className="stateList-body">
      <form>
        <div className="checkbox-group">
          {stateData.map((state) => {
            var defaultChecked;
            if (param.type === "havingPotentialToShipViaWineDotCom") {
              defaultChecked = state.havingPotentialToShipViaWineDotCom;
            } else if (param.type === "havingPotentialToShipViaVivino247Solution") {
              defaultChecked = state.havingPotentialToShipViaVivino247Solution;
            } else if (param.type === "shippingViaLibDib") {
              defaultChecked = state.shippingViaLibDib;
            } else if (param.type === "nonControlStateForWine") {
              defaultChecked = state.nonControlStateForWine;
            } else if (param.type === "nonFranchiseStateForWine") {
              defaultChecked = state.nonFranchiseStateForWine;
            } else if (param.type === "directlySelfDistributingToRetailers") {
              defaultChecked = state.directlySelfDistributingToRetailers;
            } else if (
              param.type === "statesAllowSalesDTCFromOutOfStateRetailers"
            ) {
              defaultChecked = state.statesAllowSalesDTCFromOutOfStateRetailers;
            } else if (param.type === "sellingDTCViaIntrastateRetailers") {
              defaultChecked = state.sellingDTCViaIntrastateRetailers;
            } else if (param.type === "sellingDirectlyToUSConsumersMyself") {
              defaultChecked = state.sellingDirectlyToUSConsumersMyself;
            } else if (
              param.type === "sellingToRetailersWhereTheRetailerSelfDistributes"
            ) {
              defaultChecked =
                state.sellingToRetailersWhereTheRetailerSelfDistributes;
            } else if (param.type === "sellingThroughThreeTierDistribution") {
              defaultChecked = state.sellingThroughThreeTierDistribution;
            }
            return (
              <div key={state.abrv} className="checkbox-label">
                <label>
                  {state.name}
                  <input
                    type="checkbox"
                    defaultChecked={defaultChecked}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onChange={(e) =>
                      handleCheckboxChange(state.abrv, e.target.checked)
                    }
                  />
                </label>
              </div>
            );
          })}
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <button type="submit" id="saveBtn" onClick={handleSave}>
            {saving ? "Saving" : "Save"}
          </button>
          {/* {isSuccessVisible && (
            <p
              className="success-message"
              style={{
                color: "green",
                marginBottom: "0px",
                marginLeft: "10px",
              }}
            >
              {successMessage}
            </p>
          )}
          {isErrorVisible && (
            <p
              className="error-message"
              style={{
                color: "green",
                marginBottom: "0px",
                marginLeft: "10px",
              }}
            >
              {errorMessage}
            </p>
          )} */}
        </div>
      </form>
    </div>
  );
};

export default StateList;
