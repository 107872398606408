import { Button, Card, CardActionArea, CardContent, CardMedia, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFileExcel } from "react-icons/fa";
import './calculators.css';
import threeTier from '../../assets/excel/USA three tier model.xlsx';
import directToRetail from '../../assets/excel/USA Direct to retail.xlsx';
import eCommerceToConsumer from '../../assets/excel/USA eCommerce and Direct to consumer.xlsx';
import libDib from '../../assets/excel/USA LibDib.xlsx';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { API_URL } from "../../App";
import parse from 'html-react-parser';

const Calculators = () => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [content, setContent] = useState(''); // Initialize with an empty string
    const [editorComponents, setEditorComponents] = useState([]);
    const openMobileMenu = () => {
        setOpenMenu(!openMenu);
    }

    useEffect(() => {
        fetch(`${API_URL}/api/cms`)
            .then((res) => res.json())
            .then((res) => {
                if (res.data.length > 0 && res.data[0].priceCal) {
                    setContent(res.data[0].priceCal.content); // Set content for page description
                    setEditorComponents(res.data[0].priceCal.components); // Set editor components
                }
            });
    }, []);

    return (
        <div className='calculators'>
            <Toolbar className='topBar' sx={{ backgroundColor: '#ffffff', paddingTop: '5px' }}>
                <Link to="/" ><img className='logo' src="/images/AGW.png" /></Link>
                {/*<Link to="/" ><img className='logo' src="/images/HYDRA+LOGO_CMYK transparent.png"/></Link>*/}
                <MenuIcon className='mobileHamburger' onClick={openMobileMenu} />
                <Button onClick={() => navigate("/")} className='navBar' sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap sx={{ color: "black" }} component="div">
                        <Link to="/" className="link">Home</Link>
                    </Typography>
                </Button>
                <Button onClick={() => navigate("/calculators")} className='navBar' sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap sx={{ color: "black" }} component="div">
                        <Link to="/calculators" className="link">US retail price calculators</Link>
                    </Typography>
                </Button>
                <Button onClick={() => navigate("/otherresources")} className='navBar' sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ display: "flex", color: "black" }} >
                        <Link to="/otherresources" className="link">Other Resources</Link>
                    </Typography>
                </Button>
                <Button onClick={() => navigate("/faq")} className='navBar' sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ display: "flex", color: "black" }} >
                        <Link to="/faq" className="link">FAQ</Link>
                    </Typography>
                </Button>
            </Toolbar>
            <div className={openMenu ? 'mobileMenu active' : 'mobileMenu'}>
                <Button className='mobileMenuButton' onClick={() => navigate("/")} sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap sx={{ color: "black" }} component="div">
                        <Link to="/" className="link">Home</Link>
                    </Typography>
                </Button>
                <Button className='mobileMenuButton' onClick={() => navigate("/calculators")} sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap sx={{ color: "black" }} component="div">
                        <Link to="/calculators" className="link" onClick={() => setOpenMenu(false)}>US retail price calculators</Link>
                    </Typography>
                </Button>
                <Button className='mobileMenuButton' onClick={() => navigate("/otherresources")} sx={{ display: "flex", color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ display: "flex", color: "black" }} >
                        <Link to="/otherresources" className="link">Other Resources</Link>
                    </Typography>
                </Button>
                <Button className='mobileMenuButton' onClick={() => navigate("/faq")} sx={{ display: "flex", textAlign: 'left', color: '#fefefe' }} >
                    <Typography className='topBarText' variant="body1" noWrap sx={{ Wcolor: "black" }} component="div">
                        <Link to="/faq" className="link">FAQ</Link>
                    </Typography>
                </Button>
            </div>
            <div className='body'>
                <Typography variant="h4" component="div" sx={{ width: '100%', textAlign: 'left' }}>
                    US Retail Price Calculators
                </Typography>
                <Typography variant="h7" component="div" sx={{ width: '100%', textAlign: 'left' }}>
                    {content ? parse(content) : 'We have created the USA price calculators to help you get started on working out your USA retail price from your FOB. These should be used as an estimate only. Final process can only be determined in consultation with your distribution partners.'}
                </Typography>
                <div className='excels'>
                    {editorComponents.length>0 ? (editorComponents.map((component, index) => (
                        <Card>
                            <CardActionArea>
                                <CardContent className='calculatorCard' sx={{ padding: '16px 10px 16px 10px !important' }} component="div">
                                    <Typography gutterBottom variant="h6" component="div">
                                        {parse(component.content)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))) : (<div style={{display:'flex'}}> <Card>
                        <CardActionArea>
                            <CardContent className='calculatorCard' sx={{ padding: '16px 10px 16px 10px !important' }} component="div">
                                <Typography gutterBottom variant="h6" component="div">
                                    USA - Three Tier System
                                </Typography>
                                <Typography variant="body2" color="text.secondary" component="div">
                                    <div className='excel'><FaFileExcel /><Link className='download' to={threeTier} target="_blank" download>USA three tier model.xlsx</Link></div>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                        <Card>
                            <CardActionArea>
                                <CardContent className='calculatorCard' sx={{ padding: '16px 10px 16px 10px !important' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        USA - Direct to retailer
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <div className='excel'><FaFileExcel /><Link className='download' to={directToRetail} target="_blank" download>USA Direct to retail.xlsx</Link></div>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card>
                            <CardActionArea>
                                <CardContent className='calculatorCard' sx={{ padding: '16px 10px 16px 10px !important' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        USA - eCommerce and DTC
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <div className='excel'><FaFileExcel /><Link className='download' to={eCommerceToConsumer} target="_blank" download>USA eCommerce and Direct to consumer.xlsx</Link></div>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <Card>
                            <CardActionArea>
                                <CardContent className='calculatorCard' sx={{ padding: '16px 10px 16px 10px !important' }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        USA - LibDib Model
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <div className='excel'><FaFileExcel /><Link className='download' to={libDib} target="_blank" download>USA LibDib.xlsx</Link></div>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card></div>)}
                </div>
            </div>

        </div>
    )
}

export default Calculators
