export const getSelfDistributionStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.directlySelfDistributingToRetailers));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getNonControlStateForWineStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.nonControlStateForWine));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getNonFranchiseStateForWineStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.nonFranchiseStateForWine));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getSellingDTCViaOutOfStateRetailersStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.sellingDTCViaOutOfStateRetailers));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getStatesAllowSalesDTCFromIntrastateRetailersStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.statesAllowSalesDTCFromIntrastateRetailers));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getSellingDirectlyToUSConsumersMyselfStatesOverlayStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.sellingDirectlyToUSConsumersMyself));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getStatesAllowDTCFromUSWineryStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.statesAllowDTCFromUSWinery));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getSellingToRetailersWhereTheRetailerSelfDistributesStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.sellingToRetailersWhereTheRetailerSelfDistributes));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getSellingThroughThreeTierDistributionStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.sellingThroughThreeTierDistribution));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getHavingPotentialToShipViaWineDotComStateColors = (item, data) => {
    let res = data.find(s => (s.state === item.name && s.havingPotentialToShipViaWineDotCom));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getHavingPotentialToShipViaVivino247SolutionStateColors = (item, data) => {
    let res = data.find(s => (s.name === item.name && s.havingPotentialToShipViaVivino247Solution));
    return res ? 'rgb(219, 62, 156)' : null;
};

export const getShippingViaLibDibStateColors = (item, data) => {

    var res = data.find(s => (s.name === item.name && s.shippingViaLibDib));

    return res ? 'rgb(219, 62, 156)' : null;
};

export const getGenericStateColors = (item, data, additionalFilters = []) => {
    var res = null;
    var safeToAdd = true;

    if(data && data.length > 0) {
        for(var i = 0; i < data.length; i++) {
            safeToAdd = true;
            if(data[i] && data[i].name == item.name) {
                for(var j = 0; j < additionalFilters.length; j++) {
                    var additionalFilter = additionalFilters[j];

                    if(additionalFilter == "directlySelfDistributingToRetailers") {
                        if(!data[i].directlySelfDistributingToRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "statesAllowSalesDTCFromOutOfStateRetailers") {
                        if(!data[i].statesAllowSalesDTCFromOutOfStateRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingDTCViaIntrastateRetailers") {
                        if(!data[i].sellingDTCViaIntrastateRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingDirectlyToUSConsumersMyself") {
                        if(!data[i].sellingDirectlyToUSConsumersMyself) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingToRetailersWhereTheRetailerSelfDistributes") {
                        if(!data[i].sellingToRetailersWhereTheRetailerSelfDistributes) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingThroughThreeTierDistribution") {
                        if(!data[i].sellingThroughThreeTierDistribution) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "shippingViaLibDib") {
                        if(!data[i].shippingViaLibDib) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "havingPotentialToShipViaVivino247Solution") {
                        if(!data[i].havingPotentialToShipViaVivino247Solution) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "havingPotentialToShipViaWineDotCom") {
                        if(!data[i].havingPotentialToShipViaWineDotCom) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "nonControlStateForWine") {
                        if(!data[i].nonControlStateForWine) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "nonFranchiseStateForWine") {
                        if(!data[i].nonFranchiseStateForWine) {
                            safeToAdd = false;
                        }
                    }
                }

                if(safeToAdd) {
                    res = data[i];
                }
            }
        }

        return res ? 'rgb(120, 191, 69, 1)' : "#ece1cb";
    }


    return res ? 'rgb(219, 62, 156)' : null;
}

export const getStateImageColors = (item, data, additionalFilters = []) => {
    var res = null;
    var safeToAdd = true;

    if(data && data.length > 0) {
        for(var i = 0; i < data.length; i++) {
            safeToAdd = true;
            if(data[i] && data[i].name == item) {
                for(var j = 0; j < additionalFilters.length; j++) {
                    var additionalFilter = additionalFilters[j];
                    if(additionalFilter == "directlySelfDistributingToRetailers") {
                        if(!data[i].directlySelfDistributingToRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "statesAllowSalesDTCFromOutOfStateRetailers") {
                        if(!data[i].statesAllowSalesDTCFromOutOfStateRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingDTCViaIntrastateRetailers") {
                        if(!data[i].sellingDTCViaIntrastateRetailers) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingDirectlyToUSConsumersMyself") {
                        if(!data[i].sellingDirectlyToUSConsumersMyself) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingToRetailersWhereTheRetailerSelfDistributes") {
                        if(!data[i].sellingToRetailersWhereTheRetailerSelfDistributes) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "sellingThroughThreeTierDistribution") {
                        if(!data[i].sellingThroughThreeTierDistribution) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "shippingViaLibDib") {
                        if(!data[i].shippingViaLibDib) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "havingPotentialToShipViaVivino247Solution") {
                        if(!data[i].havingPotentialToShipViaVivino247Solution) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "havingPotentialToShipViaWineDotCom") {
                        if(!data[i].havingPotentialToShipViaWineDotCom) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "nonControlStateForWine") {
                        if(!data[i].nonControlStateForWine) {
                            safeToAdd = false;
                        }
                    }

                    if(additionalFilter == "nonFranchiseStateForWine") {
                        if(!data[i].nonFranchiseStateForWine) {
                            safeToAdd = false;
                        }
                    }
                }

                if(safeToAdd) {
                    res = data[i];
                }
            }
        }

        return res ? 'rgb(160, 203, 116, 1)' : "rgb(236, 225, 203, 1)";
    }


    return res ? 'rgb(219, 62, 156)' : null;
}