import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  CardContent,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import {
  BsFillArrowDownSquareFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";
import parse from "html-react-parser";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { API_URL } from "../../App";
import "./otherResources.css";

const OtherResources = () => {
  const [openFindingDistributors, setFindingDistributors] = useState(false);
  const [openUsaMarket, setUsaMarket] = useState(false);
  const [openGettingReviewed, setGettingReviewed] = useState(false);
  const [openEducationalResouces, setEducationalResouces] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  var user = navigator.userAgent;

  const [openCompareList, setOpenCompareList] = useState([]);
  const [content, setContent] = useState(""); // Initialize with an empty string
  const [editorComponents, setEditorComponents] = useState([]);

  const openMobileMenu = () => {
    setOpenMenu(!openMenu);
  };

  const move = (str) => {
    let tmp = "." + str;
    if (str === "findingDistributors") {
      setFindingDistributors(true);
    }
    if (str === "UsaMarket") {
      setUsaMarket(true);
    }
    if (str === "gettingReviewed") {
      setGettingReviewed(true);
    }
    if (str === "educationalResources") {
      setEducationalResouces(true);
    }
    console.log(tmp);
    $("html,body").animate({ scrollTop: $(tmp).offset().top }, "slow");
  };

  const backToTop = () => {
    $("html,body").animate(
      { scrollTop: $(".otherResources").offset().top },
      "slow"
    );
  };

  useEffect(() => {
    fetch(`${API_URL}/api/cms`)
      .then((res) => res.json())
      .then((res) => {
        if (
          res.data.length > 0 &&
          res.data[0].otherResources.components.length > 0
        ) {
          setContent(res.data[0].otherResources.content); // Set content for page description
          setEditorComponents(res.data[0].otherResources.components); // Set editor components
        }
      });
  }, []);

  return (
    <div className="otherResources">
      <Toolbar
        className="topBar"
        sx={{ backgroundColor: "#ffffff", paddingTop: "5px" }}
      >
        <Link to="/">
          <img className="logo" src="/images/AGW.png" />
        </Link>
        {/*<Link to="/" ><img className='logo' src="/images/HYDRA+LOGO_CMYK transparent.png"/></Link>*/}
        <MenuIcon className="mobileHamburger" onClick={openMobileMenu} />
        <Button
          onClick={() => navigate("/")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/" className="link">
              Home
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/calculators")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/calculators" className="link">
              US retail price calculators
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/otherresources")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link to="/otherresources" className="link">
              Other Resources
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/faq")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link to="/faq" className="link">
              FAQ
            </Link>
          </Typography>
        </Button>
      </Toolbar>
      <div className={openMenu ? "mobileMenu active" : "mobileMenu"}>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/" className="link">
              Home
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/calculators")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/calculators" className="link">
              US retail price calculators
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/otherresources")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link
              to="/otherresources"
              className="link"
              onClick={() => setOpenMenu(false)}
            >
              Other Resources
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/faq")}
          sx={{ display: "flex", textAlign: "left", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ Wcolor: "black" }}
            component="div"
          >
            <Link to="/faq" className="link">
              FAQ
            </Link>
          </Typography>
        </Button>
      </div>
      <div className="body">
        <Typography variant="h4" component="div">
          {content ? parse(content) : "Other Resources Links"}
        </Typography>
        
        {/* <div className="fastLinks">
          Fast links:
          <a className="fastLink" onClick={() => move("findingDistributors")}>
            Finding distributors
            <BsFillArrowDownSquareFill className="arrow" />
          </a>
          |
          <a className="fastLink" onClick={() => move("UsaMarket")}>
            Understanding the USA market
            <BsFillArrowDownSquareFill className="arrow" />
          </a>
          |
          <a className="fastLink" onClick={() => move("gettingReviewed")}>
            Getting Reviewed
            <BsFillArrowDownSquareFill className="arrow" />
          </a>
          |
          <a className="fastLink" onClick={() => move("educationalResources")}>
            Educational resources
            <BsFillArrowDownSquareFill className="arrow" />
          </a>
        </div> */}
        {editorComponents.length > 0 ? (
          editorComponents.map((component, index) => (
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                className={component.title}
                sx={{
                  backgroundColor: openCompareList[index]
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => {
                  // Toggle the open state for the clicked dropdown
                  const updatedOpenCompareList = [...openCompareList];
                  updatedOpenCompareList[index] =
                    !updatedOpenCompareList[index];
                  setOpenCompareList(updatedOpenCompareList);
                }}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openCompareList[index]
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  variant="h6"
                  component="div"
                  className="drop-down-title"
                >
                  {parse(component.title)}
                </Typography>
              </ListItemButton>
              {openCompareList[index] && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    {parse(component.content)}
                  </Typography>
                </CardContent>
              )}
            </Card>
          ))
        ) : (
          <div>
            <div className="findingDistributors">
              <Card className="dropDown" variant="outlined">
                <ListItemButton
                  sx={{
                    backgroundColor: openFindingDistributors
                      ? "rgb(221 221 221)"
                      : "#eeeeee",
                  }}
                  onClick={() =>
                    setFindingDistributors(!openFindingDistributors)
                  }
                >
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      transform: openFindingDistributors
                        ? "rotate(-180deg)"
                        : "rotate(0)",
                      transition: "0.2s",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="h6" component="div">
                    FINDING DISTRIBUTORS
                  </Typography>
                </ListItemButton>
                {openFindingDistributors && (
                  <CardContent className="otherResourceContent">
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Below are some resources you can use to help find
                      distributors and importers in the US market.
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine Enthusiast Importer Connection:{" "}
                      <Link
                        className="resource"
                        to="//www.importer-connection.com"
                        target={"_blank"}
                      >
                        https://www.importer-connection.com
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Beverage Trade Network:{" "}
                      <Link
                        className="resource"
                        to="//beveragetradenetwork.com/en/digital-directory/US/wine-wholesaler-4/page-1/"
                        target={"_blank"}
                      >
                        https://beveragetradenetwork.com/en/digital-directory/US/wine-wholesaler-4/page-1/
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine-Searcher Trade Index:{" "}
                      <Link
                        className="resource"
                        to="//www.wine-searcher.com/biz?bs=w"
                        target={"_blank"}
                      >
                        https://www.wine-searcher.com/biz?bs=w
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Commerce Interact:{" "}
                      <Link
                        className="resource"
                        to="//commerceinteract.com/event/worlds-leading-wines/"
                        target={"_blank"}
                      >
                        https://commerceinteract.com/event/worlds-leading-wines/
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      International Beverage Network:{" "}
                      <Link
                        className="resource"
                        to="//www.internationalbeveragenetwork.com"
                        target={"_blank"}
                      >
                        https://www.internationalbeveragenetwork.com
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Independent Distributors Network:{" "}
                      <Link
                        className="resource"
                        to="//www.idndist.com"
                        target={"_blank"}
                      >
                        https://www.idndist.com
                      </Link>
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
            <div className="UsaMarket">
              <Card className="dropDown" variant="outlined">
                <ListItemButton
                  sx={{
                    backgroundColor: openUsaMarket
                      ? "rgb(221 221 221)"
                      : "#eeeeee",
                  }}
                  onClick={() => setUsaMarket(!openUsaMarket)}
                >
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      transform: openUsaMarket
                        ? "rotate(-180deg)"
                        : "rotate(0)",
                      transition: "0.2s",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="h6" component="div">
                    UNDERSTANGING THE USA MARKET
                  </Typography>
                </ListItemButton>
                {openUsaMarket && (
                  <CardContent className="otherResourceContent">
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Below are some resources you can use to help find reports.
                    </Typography>
                    <Typography variant="h6" component="div">
                      Silicon Valley Bank
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Silicon Valley Bank State of the US Wine Industry Report
                      2022
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.svb.com/trends-insights/reports/wine-report"
                        target={"_blank"}
                      >
                        https://www.svb.com/trends-insights/reports/wine-report
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Silicon Valley Bank State of the US Direct to Consumer
                      Wine Survey 2021
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.svb.com/trends-insights/reports/dtc-wine-survey-report-2021"
                        target={"_blank"}
                      >
                        https://www.svb.com/trends-insights/reports/dtc-wine-survey-report-2021
                      </Link>
                    </Typography>
                    <Typography variant="h6" component="div">
                      Tablas Creek
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      An insider view on DTC from US Wine producers Tablas
                      Creeks’ Wine Shipping State of the Union
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//tablascreek.typepad.com/tablas/2021/01/wine-shipping-state-of-the-union-2021-edition.html"
                        target={"_blank"}
                      >
                        https://tablascreek.typepad.com/tablas/2021/01/wine-shipping-state-of-the-union-2021-edition.html
                      </Link>
                    </Typography>
                    <Typography variant="h6" component="div">
                      Wine Australia (www.wineaustralia.com){" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA On-trade wine sales trend reports from Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/us-on-trade-wine-market-report"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/us-on-trade-wine-market-report
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA Off-trade wine sales report, Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/us-wine-market-report"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/us-wine-market-report
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA Market update Nov 2021, Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/market-update-united-states"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/market-update-united-states
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA Market insights reports, Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/united-states-market-insights-report"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/united-states-market-insights-report
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA Wine Market Import Report, Wine Australia (scroll to
                      bottom)
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/wine-import-market-reports"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/wine-import-market-reports
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      USA wine exports at $10 per litre and above (April 2021),
                      Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/usa-wine-exports-at-10-dollars-per-litre"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/usa-wine-exports-at-10-dollars-per-litre
                      </Link>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      A brief guide to the US three tier system, Wine Australia
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/news/market-bulletin/issue-240"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/news/market-bulletin/issue-240
                      </Link>
                    </Typography>

                    <Typography variant="h6" component="div">
                      Wine Intelligence
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine Intelligence Seminar on Consumer wine consumption
                      during and post COVID-19 webinar (May 2020)
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/market-insights/consumer-wine-consumption-behaviour"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/market-insights/consumer-wine-consumption-behaviour
                      </Link>
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
            <div className="gettingReviewed">
              <Card className="dropDown" variant="outlined">
                <ListItemButton
                  sx={{
                    backgroundColor: openGettingReviewed
                      ? "rgb(221 221 221)"
                      : "#eeeeee",
                  }}
                  onClick={() => setGettingReviewed(!openGettingReviewed)}
                >
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      transform: openGettingReviewed
                        ? "rotate(-180deg)"
                        : "rotate(0)",
                      transition: "0.2s",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="h6" component="div">
                    GETTING REVIEWED IN THE USA
                  </Typography>
                </ListItemButton>
                {openGettingReviewed && (
                  <CardContent className="otherResourceContent">
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      The Wine Advocate (USA): Joe Czerwinski, Managing Editor
                      (+1 845-800-7122)
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine Spectator (USA): MaryAnn Worobiec, Senior Editor;
                      Augustus Weed, Tasting Coordinator, Napa (+1 707 299
                      3999); How to submit wines
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine Enthusiast (USA): Christina Pickard, Contributing
                      Editor; Wine Enthusiast tasting program
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Vinous (USA): Joshua Raynolds, Editor
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Wine & Spirits (USA): Joshua Greene, Publisher & Editor
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
            <div className="educationalResources">
              <Card className="dropDown" variant="outlined">
                <ListItemButton
                  sx={{
                    backgroundColor: openEducationalResouces
                      ? "rgb(221 221 221)"
                      : "#eeeeee",
                  }}
                  onClick={() =>
                    setEducationalResouces(!openEducationalResouces)
                  }
                >
                  <KeyboardArrowDown
                    sx={{
                      mr: -1,
                      transform: openEducationalResouces
                        ? "rotate(-180deg)"
                        : "rotate(0)",
                      transition: "0.2s",
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="h6" component="div">
                    EDUCATIONAL RESOURCES FOR SUPPORTING DISTRIBUTION
                  </Typography>
                </ListItemButton>
                {openEducationalResouces && (
                  <CardContent className="otherResourceContent">
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      Australian Wine Discovered (Wine Australia):{" "}
                      <Link
                        className="resource"
                        to="//www.wineaustralia.com/education"
                        target={"_blank"}
                      >
                        https://www.wineaustralia.com/education
                      </Link>
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
          </div>
        )}
        <BsFillArrowUpCircleFill className="moveTop" onClick={backToTop} />
      </div>
    </div>
  );
};

export default OtherResources;
