import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import threeTier from "../../assets/images/ATMAC.png";
import "./faq.css";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import $ from "jquery";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import parse from "html-react-parser";
import { API_URL } from "../../App";

const Faq = () => {
  const [openCompare, setOpenCompare] = useState(false);
  const [openCompareList, setOpenCompareList] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const [openFactSheet, setOpeFactSheet] = useState(false);
  const [openGreenOutline, setGreenOutline] = useState(false);
  const [openChangeFilter, setChangeFilter] = useState(false);
  const [openRemoveFilter, setRemoveFilter] = useState(false);
  const [openCantSeeFilter, setCantSeeFilter] = useState(false);
  const [openWorkOut, setWorkOut] = useState(false);
  const [openLargestSeller, setLargestSeller] = useState(false);
  const [openPopulation, setPopulation] = useState(false);
  const [openCountyWealth, setCountyWealth] = useState(false);
  const [openEducationLevel, setEducationLevel] = useState(false);
  const [openAustralianVisa, setAustralianVisa] = useState(false);
  const [openAppleStore, setAppleStore] = useState(false);
  const [openUsaComplex, setUsaComplex] = useState(false);
  const [openThreeTierDistribution, setThreeTierDistribution] = useState(false);
  const [openDtc, setDtc] = useState(false);
  const [openSelfDistribute, setSelfDistribute] = useState(false);
  const [openSelfImport, setSelfImport] = useState(false);
  const [openImporter, setImporter] = useState(false);
  const [openControlState, setControlState] = useState(false);
  const [openFranchiseState, setFranchiseState] = useState(false);
  const [openSmallAndLarge, setSmallAndLarge] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [content, setContent] = useState(""); // Initialize with an empty string
  const [editorComponents, setEditorComponents] = useState([]);

  const navigate = useNavigate();
  var user = navigator.userAgent;

  const openMobileMenu = () => {
    setOpenMenu(!openMenu);
  };

  const backToTop = () => {
    $("html,body").animate({ scrollTop: $(".faq").offset().top }, "slow");
  };

  useEffect(() => {
    fetch(`${API_URL}/api/cms`)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length > 0 && res.data[0].faq) {
          setContent(res.data[0].faq.content); // Set content for page description
          setEditorComponents(res.data[0].faq.components); // Set editor components
        }
      });
  }, []);

  return (
    <div className="faq">
      <Toolbar
        className="topBar"
        sx={{ backgroundColor: "#ffffff", paddingTop: "5px" }}
      >
        <Link to="/">
          <img className="logo" src="/images/AGW.png" />
        </Link>
        {/*<Link to="/" ><img className='logo' src="/images/HYDRA+LOGO_CMYK transparent.png"/></Link>*/}
        <MenuIcon className="mobileHamburger" onClick={openMobileMenu} />
        <Button
          onClick={() => navigate("/")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/" className="link">
              Home
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/calculators")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/calculators" className="link">
              US retail price calculators
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/otherresources")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link to="/otherresources" className="link">
              Other Resources
            </Link>
          </Typography>
        </Button>
        <Button
          onClick={() => navigate("/faq")}
          className="navBar"
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link to="/faq" className="link">
              FAQ
            </Link>
          </Typography>
        </Button>
      </Toolbar>
      <div className={openMenu ? "mobileMenu active" : "mobileMenu"}>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/" className="link">
              Home
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/calculators")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ color: "black" }}
            component="div"
          >
            <Link to="/calculators" className="link">
              US retail price calculators
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/otherresources")}
          sx={{ display: "flex", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            component="div"
            sx={{ display: "flex", color: "black" }}
          >
            <Link to="/otherresources" className="link">
              Other Resources
            </Link>
          </Typography>
        </Button>
        <Button
          className="mobileMenuButton"
          onClick={() => navigate("/faq")}
          sx={{ display: "flex", textAlign: "left", color: "#fefefe" }}
        >
          <Typography
            className="topBarText"
            variant="body1"
            noWrap
            sx={{ Wcolor: "black" }}
            component="div"
          >
            <Link to="/faq" className="link" onClick={() => setOpenMenu(false)}>
              FAQ
            </Link>
          </Typography>
        </Button>
      </div>
      <div className="body">
        <Typography variant="h4" component="div">
          {content ? parse(content) : "Frequently Asked Questions (FAQs)"}
        </Typography>
        {editorComponents.length>0 ? (
          editorComponents.map((component, index) => (
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openCompareList[index]
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => {
                  // Toggle the open state for the clicked dropdown
                  const updatedOpenCompareList = [...openCompareList];
                  updatedOpenCompareList[index] =
                    !updatedOpenCompareList[index];
                  setOpenCompareList(updatedOpenCompareList);
                }}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openCompareList[index]
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div" className="drop-down-title">
                  {parse(component.title)}
                </Typography>
              </ListItemButton>
              {openCompareList[index] && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    {parse(component.content)}
                  </Typography>
                </CardContent>
              )}
            </Card>
          ))
        ) : (
          <div>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openCompare ? "rgb(221 221 221)" : "#eeeeee",
                }}
                onClick={() => setOpenCompare(!openCompare)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openCompare ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  What is the COMPARISON TABLE?
                </Typography>
              </ListItemButton>
              {openCompare && (
                <CardContent className="faqContent">
                  <Typography variant="h7" component="div">
                    How do I compare states?
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The COMPARISON TABLE will show you a comparison of any
                    states that you have selected to compare.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To add or remove a state from a comparison, click on the
                    state and click on the ADD TO COMPARISON TABLE button. It
                    will be added to the COMPARISON TABLE, which can be seen by
                    clicking on the SEE COMPARISON TABLE button.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To remove a state from a comparison, click on the state,
                    then on the REMOVE FROM COMPARISON button. It will be
                    removed from the table. You can also delete a state using
                    the bin logo to the right of the state name on the
                    COMPARISON TABLE.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To see a comparison, click on the SEE COMPARISON TABLE
                    button.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To print the comparison table you can click the PRINT
                    COMPARISON TABLE button within the comparison table or using
                    the PRINTING OPTION button on the home page.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openMore ? "rgb(221 221 221)" : "#eeeeee",
                }}
                onClick={() => setOpenMore(!openMore)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openMore ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How do I get a Fact Sheet on a state?
                </Typography>
              </ListItemButton>
              {openMore && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    If you left click on a state, you will see a PRINT STATE
                    DATA button on the left-hand side of the screen. This will
                    take you to a printable PDF fact sheet on that state.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The PRINTING OPTIONS button allows you to print or save an
                    individual state fact sheet or all of the state fact sheets.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openChangeFilter
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setChangeFilter(!openChangeFilter)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openChangeFilter
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How do I change the filters?
                </Typography>
              </ListItemButton>
              {openChangeFilter && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To change the filters you are using, select the filters that
                    you want and click on the APPLY button on the bottom right
                    of your screen.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openRemoveFilter
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setRemoveFilter(!openRemoveFilter)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openRemoveFilter
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How do I remove any filters that I have applied?
                </Typography>
              </ListItemButton>
              {openRemoveFilter && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To remove filters and go back to a blank screen click on the
                    RESET FILTERS.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openCantSeeFilter
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setCantSeeFilter(!openCantSeeFilter)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openCantSeeFilter
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  I can’t see the filters.
                </Typography>
              </ListItemButton>
              {openCantSeeFilter && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The filters should appear on the right-hand side of the
                    screen. If you can’t see them, it means they are minimised.
                    Look for an arrow on the top right of your screen and click
                    it to reveal the filters.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    You may be able to see the filter headings, but not the
                    check boxes to select filters. This will mean that you have
                    minimised them. In each of the heading boxes there is a down
                    arrow in the right corner. Click this to reveal the filters.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openWorkOut ? "rgb(221 221 221)" : "#eeeeee",
                }}
                onClick={() => setWorkOut(!openWorkOut)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openWorkOut ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How do I work out which state is best for me?
                </Typography>
              </ListItemButton>
              {openWorkOut && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We would like to be able to tell you that a particular state
                    has a higher opportunity for small family-owned companies
                    selling Australian Shiraz into the on-trade for $20, but the
                    market and the (lack of) data simply do support that
                    approach. Maybe one day.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    With this tool, you can investigate demographics and wealth
                    distribution, interest in Australia, state market structures
                    and paths to markets, all of which together may create a
                    market opportunity. You can also see where the big and the
                    small outlets are located to get a sense for the balance in
                    each state. You can overlay preferred approach to market and
                    identify which state you might prefer to enter.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We are not trying to give you the answer – just trying to
                    help you understand the opportunity and make more effective
                    and targeted decisions.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openLargestSeller
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setLargestSeller(!openLargestSeller)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openLargestSeller
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  The largest sellers of wine in the USA are (in order) Walmart,
                  Costco, Total Wine and More, Target, Kroger, State Liquor
                  stores, Trader Joe’s, Publix, Sam’s Club, Aldi, Whole Foods,
                  Safeway, Walgreens, BevMo, Wine.com, HEB, CVS, 7-Eleven and
                  ABC Fine Wine. Why do you only map locations for the wine
                  specialists?
                </Typography>
              </ListItemButton>
              {openLargestSeller && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We are trying to map opportunity for producers. The large
                    specialist wine retailers shown on the map are organisations
                    that all producers can access through all scales of
                    conventional distributor and sometimes via
                    self-distribution. We have included Total Wines and More
                    (multi-state), BevMo (CA, WA, AZ), Spec’s (TX), Goody Goody
                    Liquor (TX), ABC Fine Wine and Spirits (FL), Liquor Barn
                    (KY) and Binny’s (IL).(NBote that each outlet has its own
                    coloured pins).
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The large non-specialist retailers like Walmart, CostCo,
                    Target et al are widely distributed across the country and
                    have internal infrastructure for selecting wine. They are
                    either working with the largest distributors, direct
                    importing from large brands or using international networks
                    of scouts to source wine. Your approach to them needs to be
                    B2B with a focus on head offices, trade buyers and large
                    distributors; so they are not mapped.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openPopulation
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setPopulation(!openPopulation)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openPopulation ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why should I care about county population?
                </Typography>
              </ListItemButton>
              {openPopulation && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We have included county population as a filter to help you
                    understand where major centres of population are located.
                    Major centres of population tend to be areas of high
                    education and centres of industry. The 20 metropolitan areas
                    with the highest population in the USA are (12 of these are
                    also the wealthiest):
                  </Typography>
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        New York-Newark-Jersey City NY-NJ-PA population 19.2m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Chicago-Naperville-Elgin IL -IN-WI population 9.5m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Dallas-Fort Worth-Arlington TX population 7.6m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Houston-The Woodlands-Sugar Land TX population 7.1m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Washington-Arlington-Alexandria DC-VA-MD population 6.3m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Miami-Fort Lauderdale-Pompano Beach FL population 6.2m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Philadelphia-Camden-Wilmington PA-NJ-DE-MD population
                        6.1m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Atlanta-Sandy Springs-Alpharetta GA population 6.0m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Phoenix-Mesa-Chandler AZ population 4.9m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Boston-Cambridge-Newton MA-NH population 4.9m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        San Francisco-Oakland-Berkley CA population 4.7m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Riverside-San Bernardina-Ontario CA population 4.5m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Detroit-Warren-Dearborn MI population 4.3m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Seattle-Tacoma-Bellevue WA population 4.0m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Minneapolis-St. Paul-Bloomington MN-WI population 3.6m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        San Diego-Chula Vista-Carlsbad CA population 3.3m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Tampa-St Petersburg-Clearwater FL population3.2m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Denver-Aurora-Lakewood CO population 3.0m
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        St Louis MO-IL population 2.8m
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To explore population and all US statistics in more detail,
                    use:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//statisticalatlas.com/United-States/Overview"
                      target={"_blank"}
                    >
                      https://statisticalatlas.com/United-States/Overview
                    </Link>
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openCountyWealth
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setCountyWealth(!openCountyWealth)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openCountyWealth
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why should I care about county wealth?
                </Typography>
              </ListItemButton>
              {openCountyWealth && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Higher median household income correlates with higher
                    educational attainment, higher purchase frequency, higher
                    wine involvement, higher curiosity, higher spend per
                    occasion, higher overall spend and higher use of online
                    channels, (statistica.com, Wine Intelligence).
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Regional wealth disparity in the USA has been declining over
                    time, but the Northeast and West coast remain the wealthiest
                    places in the USA. An interesting article on regional wealth
                    can be accessed here.
                    <Link
                      className="resource"
                      to="//www.visualcapitalist.com/comparing-wealth-u-s-geographic-regions-time/"
                      target={"_blank"}
                    >
                      https://www.visualcapitalist.com/comparing-wealth-u-s-geographic-regions-time/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The Silicon Valley Bank notes a change in US cities during
                    COVID “For wineries selling off-premise in large cities,
                    certain stores might likely decline in importance for their
                    brand, while stores in suburban regions where the winery’s
                    brand hasn’t been dominant might pop up as better sources of
                    business.”{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//www.svb.com/globalassets/trendsandinsights/reports/wine/sotwi-2021/svb-state-of-the-wine-industry-report-2021.pdf"
                      target={"_blank"}
                    >
                      https://www.svb.com/globalassets/trendsandinsights/reports/wine/sotwi-2021/svb-state-of-the-wine-industry-report-2021.pdf
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The 20 metropolitan areas with the highest average per
                    capita income in the USA are:
                  </Typography>
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        San Jose-Sunnyvale-Santa Clara CA (tech and aerospace)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Washington-Arlington-Alexandria DC-VA-MD (government,
                        professions)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        San Francisco-Oakland-Berkley CA (finance and tech)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Boston-Cambridge-Newton MA-NH (education, professions)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Seattle-Tacoma-Bellevue WA (tech)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Baltimore-Colombia-Towson MD (government, professions)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Hartford-Middletown CT (finance, insurance, real estate,
                        adjacent to NY)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Minneapolis-St. Paul-Bloomington MN-WI (agriculture
                        value adding, food, health)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        New York-Newark-Jersey City NY-NJ-PA (finance, tech,
                        insurance, health, publishing)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Denver-Aurora-Lakewood CO (defence, finance, tech,
                        health, mining, energy)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        San Diego-Chula Vista-Carlsbad CA (defence, tourism,
                        research, manufacturing)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Austin-Round Rock-Georgetown TX (manufacturing,
                        telecoms, finance, defence, IT)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Raleigh-Cary NC (manufacturing, IT, finance, business
                        services, education, health)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Salt Lake City UT (education, mining, agriculture,
                        mineral and oil processing, health)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Philadelphia-Camden-Wilmington PA-NJ-DE-MD (education,
                        manufacturing, refining, food, biotechnology,
                        telecommunications)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Chicago-Naperville-Elgin IL -IN-WI (manufacturing,
                        publishing, insurance, finance)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Portland -Vancouver-Hillsboro OR-WA (manufacturing, ag,
                        tourism, health, ed)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Los Angeles-Long Beach-Anaheim CA (entertainment,
                        finance, insurance, business services, agriculture, oil,
                        gas, education)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Sacramento-Roseville-Folsom CA (government,
                        agribusiness, manufacturing, tech, clean energy)
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Dallas-Fort Worth-Arlington TX (tech, financial
                        services, business services, defence, IT,
                        telecommunications, transport, inland port, education)
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To explore wealth and all US statistics in more detail, use:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//statisticalatlas.com/United-States/Overview"
                      target={"_blank"}
                    >
                      https://statisticalatlas.com/United-States/Overview
                    </Link>
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openEducationLevel
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setEducationLevel(!openEducationLevel)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openEducationLevel
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why didn’t you look at education levels?
                </Typography>
              </ListItemButton>
              {openEducationLevel && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    This is a good question as education is correlated with both
                    wealth and with spend on alcohol and increasing education
                    correlates with wine as the preferred drink. Annual alcohol
                    spend for people with less than high school education is
                    $102/annum, $276 for high school graduates, $475 if they
                    have a bachelor’s degree and $992 if they have a post
                    graduate qualification (all below my annual spend ed).
                    Visualisation here:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//www.visualcapitalist.com/how-americans-make-and-spend-their-money-by-education-level/"
                      target={"_blank"}
                    >
                      https://www.visualcapitalist.com/how-americans-make-and-spend-their-money-by-education-level/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In addition, more qualified buyers append a higher
                    percentage on wine. Having a degree doubles the likelihood
                    of preferring wine and reduces the likelihood of preferring
                    beer by 25% (although beer remains slightly preferred by
                    graduates with bachelor’s degrees.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    (
                    <Link
                      className="resource"
                      to="//www.usatoday.com/story/money/2019/08/13/beer-wine-americas-favorite-alcoholic-drink-varies-class-gender/39937723/"
                      target={"_blank"}
                    >
                      https://www.usatoday.com/story/money/2019/08/13/beer-wine-americas-favorite-alcoholic-drink-varies-class-gender/39937723/
                    </Link>
                    ).
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    However, the attainment of higher qualifications, correlates
                    with wealth. The 20 most educated cities in the USA are:
                  </Typography>
                  <List>
                    <ListItemText
                      primary={
                        "1.	Washington with 49.4% having bachelor’s degrees or higher and 2nd wealthiest"
                      }
                    />
                    <ListItemText
                      primary={
                        "2.	San Jose area 48.3% with bachelor’s degrees and the wealthiest metro area"
                      }
                    />
                    <ListItemText
                      primary={"3.	San Francisco 46.6%, 	ranked 3rd for wealth"}
                    />
                    <ListItemText
                      primary={"4.	Boston area 45.4%, 	4th wealthiest"}
                    />
                    <ListItemText
                      primary={"5.	Raleigh area 44.3%, 	13th wealthiest"}
                    />
                    <ListItemText
                      primary={"6.	Austin area 42.0%, 	12th wealthiest"}
                    />
                    <ListItemText
                      primary={"7.	Denver area 41.3%, 	10th wealthiest "}
                    />
                    <ListItemText
                      primary={"8.	Seattle area 40.1%, 	5th wealthiest"}
                    />
                    <ListItemText
                      primary={"9.	Minneapolis 40.0%, 	8th wealthiest"}
                    />
                    <ListItemText
                      primary={"10.	New York area 38.1%, 9th wealthiest"}
                    />
                    <ListItemText
                      primary={"11.	Baltimore 38.1%, 	6th wealthiest"}
                    />
                    <ListItemText
                      primary={"12.	Hartford area 37.7%, 	7th wealthiest"}
                    />
                    <ListItemText
                      primary={"13.	Portland area 36.8%, 	17th wealthiest"}
                    />
                    <ListItemText
                      primary={"14.	San Diego 36.5%, 	11th wealthiest"}
                    />
                    <ListItemText
                      primary={"15.	Atlanta area 36.5%, 	25th wealthiest"}
                    />
                    <ListItemText
                      primary={"16.	Chicago area 36.1%, 	16th wealthiest"}
                    />
                    <ListItemText
                      primary={"17.	Philadelphia 35.5%, 	15th wealthiest"}
                    />
                    <ListItemText
                      primary={"18.	Kansas City 35%, 	24th wealthiest"}
                    />
                    <ListItemText
                      primary={"19.	Columbus area 34.8%, 27th wealthiest"}
                    />
                    <ListItemText
                      primary={"20.	Richmond Area 34.3%, 21st wealthiest"}
                    />
                  </List>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openAustralianVisa
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setAustralianVisa(!openAustralianVisa)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openAustralianVisa
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why did you choose Australian Visa as Google search term as a
                  filter?
                </Typography>
              </ListItemButton>
              {openAustralianVisa && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We researched a range of google search terms and other data
                    to identify if there was a way of measuring interest in
                    travelling to Australia or in Australiana in the USA. In
                    terms of accessing data, the Google search data was the only
                    information that is current, and the term ‘Australian Visa’
                    was selected as that has currency with intent to travel to
                    Australia. It is useful to note that this data will include
                    areas with high travel to Australia for business, military
                    purposes and recreation.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The state with the highest population of Australians and New
                    Zealanders is Hawaii (1.76%), with the next closest Utah at
                    0.3%. Check it out here:{" "}
                    <Link
                      className="resource"
                      to="//statisticalatlas.com/United-States/National-Origin#figure/state"
                      target={"_blank"}
                    >
                      https://statisticalatlas.com/United-States/National-Origin#figure/state
                    </Link>{" "}
                    (just go to the map, choose Australia and run your mouse
                    along the scale at the top of the map)
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The counties with over 1% Australian and a total population
                    of over 10,000 are: all of the Hawaiian Counties (surfers),
                    Garfield OK, Washington AR, Pitkin CO (Skiing, Aspen), and
                    MacDonald MO. Check it out here:{" "}
                    <Link
                      className="resource"
                      to="//statisticalatlas.com/United-States/National-Origin#figure/county"
                      target={"_blank"}
                    >
                      https://statisticalatlas.com/United-States/National-Origin#figure/county
                    </Link>
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openAppleStore
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setAppleStore(!openAppleStore)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openAppleStore ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why should I care about Apple Stores?
                </Typography>
              </ListItemButton>
              {openAppleStore && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Apple store penetration is a function of both population and
                    wealth. Apple places it stores in areas with sufficient
                    spending power, high retail traffic and adjacency to other
                    ‘popular luxury’ brands.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openUsaComplex
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setUsaComplex(!openUsaComplex)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openUsaComplex ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Why is the USA complex?
                </Typography>
              </ListItemButton>
              {openUsaComplex && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The main reasons for the USA's market structure reach back
                    to the 1930s. In ending Prohibition in 1933, the 21st
                    Amendment to the US Constitution granted primary control
                    over the regulation of production, transport, sale and
                    marketing of liquor, including wine, to the states. This
                    means that each state today has its own legislation and its
                    own regulatory model and regulatory bodies for controlling
                    the production and sale of wine.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    It means realistically that it is not possible to speak of a
                    single USA market for wine; rather it is more like 50
                    different markets, each with its own quirks (not to mention
                    regulations in other jurisdictions like Washington D.C., and
                    at regional and local government levels). It also makes the
                    cross-border traffic of wine between states far more
                    complex.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    However, the system makes a targeted single state or
                    state-by-state market approach very worthwhile.
                    Population-wise there are two states that are considerably
                    larger than Australia and there are a further eight states
                    with 10 million or more inhabitants, and wine consumption
                    per person is at around 9.8 litres per person.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In addition to creating a mass of conflicting legal systems,
                    the fallout from Prohibition also created a mandatory
                    three-tier system for the sale of alcohol.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    (From Wine Australia Market Bulleting 240 “A brief guide to
                    the USA’s three tier system”)
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openThreeTierDistribution
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() =>
                  setThreeTierDistribution(!openThreeTierDistribution)
                }
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openThreeTierDistribution
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  What is three-tier distribution?
                </Typography>
              </ListItemButton>
              {openThreeTierDistribution && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    To get to market, and into the hands of retailers and
                    consumers, producers (tier 1) must sell wine through an
                    in-state wholesaler or distributor (tier 2) in each state,
                    who is responsible for distributing the wine to retailers
                    (Tier 3). Wine cannot enter the USA without consignment to a
                    licensed importer which pouts the wine in Tier 1 of the US
                    three tier system.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The diagram below shows the basic model of three-tier
                    distribution. In this case the importer stands in as the
                    equivalent to a local producer under the three-tier rules.
                  </Typography>
                  <img className="threeTier" src={threeTier}></img>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    This video provides an explainer for the three-tier system
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//youtu.be/7GY3WsCpUXQ"
                      target={"_blank"}
                    >
                      https://youtu.be/7GY3WsCpUXQ
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    It is generally not possible for one company to own
                    businesses in more than one of the three tiers. However,
                    there are a lot of exceptions, which is what makes the USA
                    51+ wine markets and at least 6 distinct channels (direct to
                    consumer, eCommerce, traditional distribution, direct to
                    retail, direct to monopoly and self-distribution) each
                    market is a unique blend of entry rules. The purpose of this
                    project is to help you to break the market down and make
                    choices about what suits you. If you look at the populations
                    of many individual states, you will see why we see it as 51
                    countries, not just 1.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openDtc ? "rgb(221 221 221)" : "#eeeeee",
                }}
                onClick={() => setDtc(!openDtc)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openDtc ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How can I set myself up to sell DTC (Direct to Consumers) in
                  the USA?
                </Typography>
              </ListItemButton>
              {openDtc && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    If you want to sell directly to consumers yourself through a
                    DTC platform or your own eCommerce you can set up in places
                    like California with a retail or winery license. Examples
                    are:
                  </Typography>
                  <ul>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Type 85 online retail retailer can ship to 14 states
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Type 17/20 retail license can ship to 16 states
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        component="div"
                        color="text.secondary"
                      >
                        Type 02 under Alternating Proprietorship can ship to as
                        many as 44 states
                      </Typography>
                    </li>
                  </ul>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Self-importers can set these structures up themselves,
                    otherwise you will need to work with an importer who is
                    structured to sell DTC through one of these mechanisms. If
                    you choose to do this, seek your own legal advice before
                    proceeding.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Explainer article for legal structures for DTC:{" "}
                    <Link
                      className="resource"
                      to="//copperpeaklogistics.com/so-you-want-to-be-in-the-wine-business/"
                      target={"_blank"}
                    >
                      https://copperpeaklogistics.com/so-you-want-to-be-in-the-wine-business/
                    </Link>
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openSelfDistribute
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setSelfDistribute(!openSelfDistribute)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openSelfDistribute
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How can I set myself up to self-distribute?
                </Typography>
              </ListItemButton>
              {openSelfDistribute && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    If you want to self-distribute, you will need to set
                    yourself with an importer or self-import, then set up
                    distribution infrastructure including warehousing,
                    transport, fulfillment, payment and billing. Fortunately,
                    there are several tools that you can use in supporting
                    self-distribution like SevenFifty, LibDib,{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Some useful articles and stories on self-distribution:
                  </Typography>
                  <ul>
                    <li style={{ marginBottom: "10px" }}>
                      <Link
                        className="resource"
                        to="//www.winemag.com/2021/03/04/self-distribution-wine-social-media/"
                        target={"_blank"}
                      >
                        https://www.winemag.com/2021/03/04/self-distribution-wine-social-media/
                      </Link>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <Link
                        className="resource"
                        to="//blog.libdib.com/an-alternative-to-self-distribution-of-wine-and-spirits-libdib"
                        target={"_blank"}
                      >
                        https://blog.libdib.com/an-alternative-to-self-distribution-of-wine-and-spirits-libdib
                      </Link>
                    </li>
                    <li style={{ marginBottom: "10px" }}>
                      <Link
                        className="resource"
                        to="//analytics.wine/wine-markets/self-distribution-for-independent-winegrowers-pros-and-cons/"
                        target={"_blank"}
                      >
                        https://analytics.wine/wine-markets/self-distribution-for-independent-winegrowers-pros-and-cons/
                      </Link>
                    </li>
                  </ul>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    LibDib have also set themselves as an alternative to
                    self-distribution.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Direct self-distribution to retailers is targeted at smaller
                    producers and subject to a range of rules and restrictions.
                    If you are self-distributing, consider working with
                    SevenFifty to support your sales and distribution efforts.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In CA only licensed instate manufacturers and wine growers
                    may sell directly to retailers.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In CT only licensed wineries producing less than 100,000
                    gallons per year can self-distribute.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In DC by holders of an importation permit where the ABRA
                    (Alcoholic Beverage Regulation Administration, District of
                    Columbia) has been satisfied that the brands being
                    distributed are not otherwise available.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In IL by holders of a valid wine shippers license that do
                    not produce more than 25,000 gallons of wine annually solely
                    or combined with another group and does not sell more than
                    5,000 gallons to retailers annually.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In ME only parties licensed as a small winery (who may not
                    produce more than 50,000 gallons annually) may
                    self-distribute.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In MD by holders of a Non-Resident Winery Permit, who may
                    not produce more than 27,500 gallons annually.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In MO holders of an “Out of State Winery License” may sell
                    to both wholesalers and retailers.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In NJ wineries producing less than 250,000 gallons per year
                    may apply for an Out-of-State winery license permitting DTC
                    and direct to retail sales. Wineries can also become
                    licensed as New Jersey wholesalers.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In NY out of state wineries that establish a business
                    location in New York can become licensed as a wholesaler
                    with the right to distribute to other wholesalers and
                    retailers.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In ND, licensed wineries that produce no more than 50,000
                    gallons per year may apply for additional licensing to sell
                    directly to retailers up to 4,500 cases per annum.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In OH only wineries that qualify for a Federal Wine Tax
                    credit and produce no more that 250,000 gallons per year can
                    self-distribute.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In OK wineries that produce no more than 15,000 gallons may
                    apply for a Winemaker Self-Distribution License but only if
                    the winery uses its own vehicles.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In OR wineries holding a certificate of approval may apply
                    for a Wine Self-Distribution license to retailers authorised
                    by the OLCC (Oregon Liquor and Cannabis Commission).
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In TN any party granted a Tennessee Winery License can sell
                    up to 5 cases of wine per day to any individual retailer.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In VT holders of a Vermont Direct Ship to Retail License may
                    sell up to 5,000 gallons a year directly to retailers, but
                    no more than 100 gallons to any individual retailer.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In WA holders of a Certificate of Approval can sell directly
                    to retailers if they receive an additional permit.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In WY holders of Out-of-State Shipper License for DTC
                    shipping may also sell directly to Wyoming retailers, but
                    only wines not already listed for sale by the Beverage
                    Control Division.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openSelfImport
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setSelfImport(!openSelfImport)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openSelfImport ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Should I self-import?
                </Typography>
              </ListItemButton>
              {openSelfImport && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    This is a decision for you. There are importers in the USA
                    who can enable you to self-import, so you do not need to
                    start from scratch. There is no doubt that it is more work
                    than working with a local importer, but many Australian
                    companies are choosing to do so. Here is a story of an
                    Italian producer who self-imports:{" "}
                    <Link
                      className="resource"
                      to="//dobianchi.com/2013/07/08/cogno-nascetta-anas-cetta/"
                      target={"_blank"}
                    >
                      https://dobianchi.com/2013/07/08/cogno-nascetta-anas-cetta/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Here’s a story from SevenFifty Daily about becoming a wine
                    importer:{" "}
                    <Link
                      className="resource"
                      to="//daily.sevenfifty.com/a-guide-to-becoming-a-wine-importer/"
                      target={"_blank"}
                    >
                      https://daily.sevenfifty.com/a-guide-to-becoming-a-wine-importer/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Another from SevenFifty Daily about the options for
                    importing:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//daily.sevenfifty.com/how-to-enter-the-u-s-market-with-a-new-alcohol-brand/"
                      target={"_blank"}
                    >
                      https://daily.sevenfifty.com/how-to-enter-the-u-s-market-with-a-new-alcohol-brand/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Here is a story from MyDrink about the essential steps in
                    importing:{" "}
                    <Link
                      className="resource"
                      to="//www.mydrinkbeverages.com/what-you-need-to-know-about-importing-alcohol-into-the-united-states"
                      target={"_blank"}
                    >
                      https://www.mydrinkbeverages.com/what-you-need-to-know-about-importing-alcohol-into-the-united-states
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Whatever you do, work with Wine Australia on labels and
                    export approvals as well as working with them to customise
                    your market research.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Self-importing gives you the ability to self-distribute,
                    sell mail-order, sell DTC and work with organisations like
                    LibDib, as well as directly working with distributors. Note
                    that you can do these with an importer if you choose the
                    right one.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    In 1985 Gruppo Mezzacorona, a cooperative of 1600 wineries
                    in Trentino, Italy established their own import company into
                    the USA (Prestige Wine Imports) to manage their own affairs
                    in the USA market.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openImporter
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setImporter(!openImporter)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openImporter ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  How do I find an importer?
                </Typography>
              </ListItemButton>
              {openImporter && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    It is surprisingly easy to access lists of importers.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Here are a few suggestions:
                  </Typography>
                  <List>
                    <ListItemText>
                      1. Join Beverage Trade Network (
                      <Link
                        className="resource"
                        to="//beveragetradenetwork.com/en/welcome-to-btn.htm"
                        target={"_blank"}
                      >
                        https://beveragetradenetwork.com/en/welcome-to-btn.htm
                      </Link>
                      ) who have searchable resources for importers and
                      distributors in their ‘Digital Directory’. Searching for
                      Wine Importer in the USA gives 769 results. Their
                      International Bulk Wine and Spirits Show, USA Wine Ratings
                      and USA Trade Tasting sites provide more details of
                      importers
                    </ListItemText>
                    <ListItemText>
                      2. Sommelier’s choice awards provide a list of 22 of the
                      most active importers in the USA here:{" "}
                      <Link
                        className="resource"
                        to="//sommelierschoiceawards.com/en/blog/insights-1/who-imports-wine-into-the-usa-136.htm"
                        target={"_blank"}
                      >
                        https://sommelierschoiceawards.com/en/blog/insights-1/who-imports-wine-into-the-usa-136.htm
                      </Link>
                    </ListItemText>
                    <ListItemText
                      primary={
                        "3.	Searching Google with the term USA wine importer will turn up a huge number of importers. "
                      }
                    />
                  </List>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    You might want to get an intern or use a company like
                    www.fiverr.com, www.freelancer.com, or www.upwork.com as a
                    low-cost option to hire someone to search and sort through
                    the options for you .
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Go to the OTHER RESOURCES tab to find further resources for
                    exporters.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openControlState
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setControlState(!openControlState)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openControlState
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  What is a control state?
                </Typography>
              </ListItemButton>
              {openControlState && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Seventeen states and jurisdictions in Alaska, Maryland,
                    Minnesota and South Dakota adopted forms of the "Control"
                    model. They control the sale of distilled spirits and, in
                    some cases, wine and beer through government agencies at the
                    wholesale level.  Thirteen of those jurisdictions also
                    exercise control over retail sales for off-premises
                    consumption; either through government-operated package
                    stores or designated agents.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Currently, control jurisdictions represent approximately
                    24.8% (Census Bureau) of the nation’s population and account
                    for roughly 23% (2017 Handbook Advance, Beverage Information
                    Group) of distilled spirit sales and a significantly smaller
                    percentage of beer and wine sales.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    For more information, the National Alcohol Beverage Control
                    Association (NABCA) has individual information sheets for
                    each control jurisdiction here:{" "}
                    <Link
                      className="resource"
                      to="//www.nabca.org/control-state-directory-and-info"
                      target={"_blank"}
                    >
                      https://www.nabca.org/control-state-directory-and-info
                    </Link>
                    .
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The US Wine Institute also has useful information on Control
                    States here:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//wineinstitute.org/our-work/policy/state/wholesale/"
                      target={"_blank"}
                    >
                      https://wineinstitute.org/our-work/policy/state/wholesale/
                    </Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Several Control States operate their own liquor stores.
                    These include ABC Alabama (AL), NH Liquor and Wine Outlet
                    (NH), Fine Wine and Good Spirits (PA), Idaho State Liquor
                    Store (ID) and DABC Utah State Liquor Store (UT).
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    (Text in first two paragraphs taken directly from NABCA
                    website).
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openFranchiseState
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setFranchiseState(!openFranchiseState)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openFranchiseState
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  What is a Franchise State?
                </Typography>
              </ListItemButton>
              {openFranchiseState && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    Monopoly Protection laws (known as ‘franchise laws’) make it
                    difficult for wineries to do business in 22 franchise states
                    as they safeguard the licensed distributor and significantly
                    restrict the ability of wineries to terminate the
                    relationship (see:{" "}
                    <Link
                      className="resource"
                      to="//wineinstitute.org/our-work/policy/state/wholesale/"
                      target={"_blank"}
                    >
                      https://wineinstitute.org/our-work/policy/state/wholesale/)
                    </Link>
                    .
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    SevenFifty.com provides a simple guide to doing business in
                    franchise states here:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Link
                      className="resource"
                      to="//daily.sevenfifty.com/how-to-do-business-in-a-franchise-state/"
                      target={"_blank"}
                    >
                      https://daily.sevenfifty.com/how-to-do-business-in-a-franchise-state/
                    </Link>
                    .
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown" variant="outlined">
              <ListItemButton
                sx={{
                  backgroundColor: openSmallAndLarge
                    ? "rgb(221 221 221)"
                    : "#eeeeee",
                }}
                onClick={() => setSmallAndLarge(!openSmallAndLarge)}
              >
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    transform: openSmallAndLarge
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "0.2s",
                    marginRight: "10px",
                  }}
                />
                <Typography variant="h6" component="div">
                  Which Small and Large Wine Sellers have you included and
                  excluded?
                </Typography>
              </ListItemButton>
              {openSmallAndLarge && (
                <CardContent className="faqContent">
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We have not exhaustively included every wine seller in the
                    USA.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    The small sellers (merchants and wine bars) were generated
                    from a Google Maps data scrape. We know this is at most 80%
                    accurate, but it creates a fantastic starting point. To
                    reduce the impact of data on site speed, we have clustered
                    the small stores – they resolve in detail as you zoom in.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We have included the following large wine sellers on the
                    map: Total Wines and More (multi-state), BevMo (CA, WA, AZ),
                    Spec’s (TX), Goody Goody Liquor (TX), ABC Fine Wine and
                    Spirits (FL), Liquor Barn (KY) and Binny’s (IL). There are
                    other multi-outlet stores that did not meet our cutoff
                    criteria for number of stores.
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    We have excluded the state monopoly sellers such as ABC
                    Alabama (AL), NH Liquor and Wine Outlet (NH), Fine Wine and
                    Good Spirits (PA), Idaho State Liquor Store (ID) and DABC
                    Utah State Liquor Store (UT), all of which have a large
                    number of outlets.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Card className="dropDown moreHelp" variant="outlined">
              <ListItemButton sx={{ backgroundColor: "rgb(221 221 221)" }}>
                <Typography variant="h6" component="div">
                  None of this helped!!
                </Typography>
              </ListItemButton>
              <CardContent className="faqContent">
                <Typography
                  variant="body2"
                  component="div"
                  color="text.secondary"
                >
                  If you need more help, email here for more support:{" "}
                  <a
                    className="email"
                    href="mailto:info@hydraconsulting.com.au"
                  >
                    HELP
                  </a>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="text.secondary"
                >
                  If you are still desperate call (08) 7079 0149{" "}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}

        <BsFillArrowUpCircleFill className="moveTop" onClick={backToTop} />
      </div>
    </div>
  );
};

export default Faq;
