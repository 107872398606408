import React, { useEffect, useState } from 'react';
import L from "leaflet";
import "leaflet-responsive-popup/leaflet.responsive.popup.css";
import "leaflet.heat";
import 'leaflet.markercluster';
import "./style.css";
import counties from "../../assets/json/us-counties.json";
import countyPopulation from "../../assets/json/county-populations.json";
import googleTrends from '../../assets/json/googleTrends.json';
import houseHoldIncomes from '../../assets/json/median-household-income-county-2019.json';
import { PDFMenu } from "../../copmonents"
import allStates from '../../assets/json/usestatesheatmap.json';
import { theme } from '../../ThemeFile';
import { API_URL } from '../../App';
import { ReactComponent as ComparisonTable } from '../../assets/images/table@1.svg';
import { IoIosArrowDown } from "react-icons/io";
import { FiShare2 } from "react-icons/fi";
import QRCode from "qrcode";

import {
    prepGenericStatesOverlay,
    prepHavingPotentialToShipViaVivino247SolutionStatesOverlay,
    prepHavingPotentialToShipViaWineDotComStatesOverlay,
    prepNonControlStateForWineStatesOverlay,
    prepNonFranchiseStateForWineStatesOverlay,
    prepSelfDistributionStatesOverlay,
    prepSellingDirectlyToUSConsumersMyselfStatesOverlayStatesOverlay,
    prepSellingDTCViaOutOfStateRetailersStatesOverlay,
    prepSellingThroughThreeTierDistributionStatesOverlay,
    prepSellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay,
    prepShippingViaLibDibStatesOverlay,
    prepStatesAllowDTCFromUSWineryStatesOverlay,
    prepStatesAllowSalesDTCFromIntrastateRetailersStatesOverlay
} from './prepareOverlays';
import { stateNumberToStateName } from './helper';
import MuiAlert from '@mui/material/Alert';
import { Alert, Badge, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getBackground, getBackgroundafterzoom, getGenericStateColors, getNonControlStateForWineStateColors, getStateImageColors } from './heatMapColorSelection';

export const HeatMap = ({
    setShowComparisonTable,
    setSelectedStateFromHeatMap,
    data,
    filter,
    formData,
    mapPos,
    setMapPos,
    mapZoom,
    setMapZoom,
    stateData,
    forceUpdate,
    selectedStates,
    setFilterChange,
    openOtherStates,
    setOpenOtherStates,
    setIsLoading
}) => {
    const [mymap, setmymap] = React.useState(null);
    const [legend, setlegend] = React.useState(null);

    // Overlays
    const [mapOverlay, setMapOverlay] = React.useState(null);
    const [overlay, setoverlay] = React.useState(null);
    const [normalOverlay, setNormalOverlay] = React.useState(null);
    const [popOverlay, setpopOverlay] = React.useState(null);
    const [dryOverlay, setdryOverlay] = React.useState(null);
    const [googleTrendsOverlay, setGoogleTrendsOverlay] = React.useState(null);

    // I'm Interested In [Overlays]
    const [selfDistributionStatesOverlay, setSelfDistributionStatesOverlay] = React.useState(null);
    const [sellingDTCViaOutOfStateRetailersStatesOverlay, setSellingDTCViaOutOfStateRetailersStatesOverlay] = React.useState(null);
    const [statesAllowSalesDTCFromIntrastateRetailersStatesOverlay, setStatesAllowSalesDTCFromIntrastateRetailersStateOverlay] = React.useState(null);
    const [sellingDirectlyToUSConsumersMyselfStatesOverlay, setSellingDirectlyToUSConsumersMyselfStatesOverlay] = React.useState(null);
    const [sellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay, setSellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay] = React.useState(null);
    const [sellingThroughThreeTierDistributionStatesOverlay, setSellingThroughThreeTierDistributionStatesOverlay] = React.useState(null);
    const [havingPotentialToShipViaWineDotComStatesOverlay, setHavingPotentialToShipViaWineDotComStatesOverlay] = React.useState(null);
    const [havingPotentialToShipViaVivino247SolutionStatesOverlay, setHavingPotentialToShipViaVivino247SolutionStatesOverlay] = React.useState(null);
    const [shippingViaLibDibStatesOverlay, setShippingViaLibDibStatesOverlay] = React.useState(null);
    const [genericStatesOverlay, setGenericStatesOverlay] = React.useState(null);

    const [statesAllowDTCFromUSWineryStatesOverlay, setStatesAllowDTCFromUSWineryStatesOverlay] = React.useState(null);

    // Constraints [Overlays]
    const [nonControlStateForWineStatesOverlay, setNonControlStateForWineStatesOverlay] = React.useState(null);
    const [nonFranchiseStateForWineStatesOverlay, setNonFranchiseStateForWineStatesOverlay] = React.useState(null);

    const [houseHoldIncomeOverlay, setHouseHoldIncomeOverlay] = React.useState(null);

    // Legends
    const [popLegend, setpopLegend] = React.useState(null);
    const [dryLegend, setdryLegend] = React.useState(null);
    const [googleTrendsLegend, setGoogleTrendsLegend] = React.useState(null);
    const [houseHoldIncomeLegend, setHouseHoldIncomeLegend] = React.useState(null);
    const [markers, setmarkers] = React.useState([]);

    const [maxhouseHoldIncomeNewHampshire, setMaxHouseHoldIncomeNewHampshire] = useState(0);
    let tmpMaxhouseHoldIncomeNewHampshire = 0;
    const [maxhouseHoldIncomeVermont, setMaxHouseHoldIncomeVermont] = useState(0);
    let tmpMaxhouseHoldIncomeVermont = 0;
    const [maxhouseHoldIncomeMassachusetts, setMaxHouseHoldIncomeMassachusetts] = useState(0);
    let tmpMaxhouseHoldIncomeMassachusetts = 0;
    const [maxhouseHoldIncomeRhodeIsland, setMaxHouseHoldIncomeRhodeIsland] = useState(0);
    let tmpMaxhouseHoldIncomeRhodeIsland = 0;
    const [maxhouseHoldIncomeConnecticut, setMaxHouseHoldIncomeConnecticut] = useState(0);
    let tmpMaxhouseHoldIncomeConnecticut = 0;
    const [maxhouseHoldIncomeNewJersey, setMaxHouseHoldIncomeNewJersey] = useState(0);
    let tmpMaxhouseHoldIncomeNewJersey = 0;
    const [maxhouseHoldIncomeDelaware, setMaxHouseHoldIncomeDelaware] = useState(0);
    let tmpMaxhouseHoldIncomeDelaware = 0;
    const [maxhouseHoldIncomeMaryland, setMaxHouseHoldIncomeMaryland] = useState(0);
    let tmpMaxhouseHoldIncomeMaryland = 0;
    const [maxhouseHoldIncomeDistrictofColumbia, setMaxHouseHoldIncomeDistrictofColumbia] = useState(0);
    let tmpMaxhouseHoldIncomeDistrictofColumbia = 0;
    const [maxhouseHoldIncomeAlaska, setMaxHouseHoldIncomeAlaska] = useState(0);
    let tmpMaxhouseHoldIncomeAlaska = 0;
    const [maxhouseHoldIncomeHawaii, setMaxHouseHoldIncomeHawaii] = useState(0);
    let tmpMaxhouseHoldIncomeHawaii = 0;

    const [maxPopulationNewHampshire, setMaxPopulationNewHampshire] = useState(0);
    let tmpMaxPopulationNewHampshire = 0;
    const [maxPopulationVermont, setMaxPopulationVermont] = useState(0);
    let tmpMaxPopulationVermont = 0;
    const [maxPopulationMassachusetts, setMaxPopulationMassachusetts] = useState(0);
    let tmpMaxPopulationMassachusetts = 0;
    const [maxPopulationRhodeIsland, setMaxPopulationRhodeIsland] = useState(0);
    let tmpMaxPopulationRhodeIsland = 0;
    const [maxPopulationConnecticut, setMaxPopulationConnecticut] = useState(0);
    let tmpMaxPopulationConnecticut = 0;
    const [maxPopulationNewJersey, setMaxPopulationNewJersey] = useState(0);
    let tmpMaxPopulationNewJersey = 0;
    const [maxPopulationDelaware, setMaxPopulationDelaware] = useState(0);
    let tmpMaxPopulationDelaware = 0;
    const [maxPopulationMaryland, setMaxPopulationMaryland] = useState(0);
    let tmpMaxPopulationMaryland = 0;
    const [maxPopulationDistrictofColumbia, setMaxPopulationDistrictofColumbia] = useState(0);
    let tmpMaxPopulationDistrictofColumbia = 0;
    const [maxPopulationAlaska, setMaxPopulationAlaska] = useState(0);
    let tmpMaxPopulationAlaska = 0;
    const [maxPopulationHawaii, setMaxPopulationHawaii] = useState(0);
    let tmpMaxPopulationHawaii = 0;

    const [imageGoogleNewHampshire, setImageGoogleNewHampshire] = useState();
    const [imageGoogleVermont, setImageGoogleVermont] = useState();
    const [imageGoogleMassachusetts, setImageGoogleMassachusetts] = useState();
    const [imageGoogleRhodeIsland, setImageGoogleRhodeIsland] = useState();
    const [imageGoogleConnecticut, setImageGoogleConnecticut] = useState();
    const [imageGoogleNewJersey, setImageGoogleNewJersey] = useState();
    const [imageGoogleDelaware, setImageGoogleDelaware] = useState();
    const [imageGoogleMaryland, setImageGoogleMaryland] = useState();
    const [imageGoogleDistrictofColumbia, setImageGoogleDistrictofColumbia] = useState();
    const [imageGoogleAlaska, setImageGoogleAlaska] = useState();
    const [imageGoogleHawaii, setImageGoogleHawaii] = useState();

    const [newHampshire, setNewHampshire] = useState(null);
    const [vermont, setVermont] = useState(null);
    const [massachusetts, setMassachusetts] = useState(null);
    const [rhodeIsland, setRhodeIsland] = useState(null);
    const [connecticut, setConnecticut] = useState(null);
    const [newJersey, setNewJersey] = useState(null);
    const [delaware, setDelaware] = useState(null);
    const [maryland, setMaryland] = useState(null);
    const [districtofColumbia, setDistrictofColumbia] = useState(null);
    const [alaska, setAlaska] = useState(null);
    const [hawaii, setHawaii] = useState(null);

    const [showEastCoastStates, setShowEastCoastStates] = React.useState(true);
    const [firtstTime, setFirtstTime] = useState(0);

    const [currentLink, setCurrentLink] = useState(window.document.location.href);
    const [show, setShow] = useState(false);
    const [clickedCopy, setclickedCopy] = useState(false);

    const handleClose = () => {
        setShow(false);
        setclickedCopy(false);
    };

    const [currentUrl, setCurrentUrl] = useState(window.document.location.href);

    const lowColour = "rgb(255,255,255)";
    const highColour = theme.palette.primary.light;

    let selectedFilterImage = [];
    //setting click handlers
    function onEachFeature(feature, layer) {
        layer.on({
            click: function () {
                setSelectedStateFromHeatMap(feature.properties.name);
            }
        });
    };

    var largeMarkers;

    const prepNormalOverlay = ({ myoverlay, mylegend }) => {
        // prep google states
        function styleOverlay(feature) {
            return {
                fillColor: getColorGoogleTrends(feature.properties),
                weight: 1,
                color: "#a8a8a8",
                opacity: 1,
                fillColor: "#ece1cb",
                fillOpacity: 0
            };
        };

        myoverlay = L.geoJSON(allStates, {
            style: styleOverlay,
            onEachFeature: onEachFeature
        });

        let group = new L.FeatureGroup();
        group.addLayer(myoverlay)
        setNormalOverlay(group);
    };

    const prepGoogleTrendsOverlay = ({ myoverlay, mylegend }) => {
        // prep google states
        function styleGoogle(feature) {
            return {
                fillColor: getColorGoogleTrends(feature.properties),
                weight: 1,
                opacity: 0.1,
                color: "black",
                fillOpacity: 0.65
            };
        };

        myoverlay = L.geoJSON(allStates, {
            style: styleGoogle,
            onEachFeature: onEachFeature
        });

        mylegend = L.control({ position: 'bottomleft' });

        mylegend.onAdd = function () {
            var div = L.DomUtil.create('div', 'info legend'),
                grades = ["100", "90", "80", "70", "60", "50", "40", "30", "20", "10"],
                labels = [];
            div.innerHTML += "<p><b>Google Trends Australia Visa</b></p>";
            // loop through our density intervals and generate a label with a colored square for each interval
            for (var i = 0; i < grades.length; i++) {
                let colors = interpolateColors(lowColour, highColour, (parseInt(grades[i].replace(/,/g, '')) / 15))
                div.innerHTML +=
                    '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.65"></i> ' +
                    (grades[i + 1] ? grades[i] + '&nbsp;&ndash;&nbsp;' + grades[i + 1] + '<br>' : '< ' + grades[i]);
            }

            return div;
        };

        setGoogleTrendsLegend(mylegend);

        let googleTrendsGroup = new L.FeatureGroup();
        googleTrendsGroup.addLayer(myoverlay)
        setGoogleTrendsOverlay(googleTrendsGroup);
    };

    useEffect(() => {
        //var mapboxtiles = L.tileLayer('https://api.mapbox.com/styles/v1/jackalksne/ckwzp7gsw1h0c14jmp4vcnk4z/tiles/512/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamFja2Fsa3NuZSIsImEiOiJja3d6cDVhMWQweDN6Mm9ybnIwem01Mnd0In0._72XTkf8vYbmMH7Jc8dkrg', { //original map
        var mapboxtiles = L.tileLayer('https://api.mapbox.com/styles/v1/jackalksne/ckzfcdrl9000915llymtmhdjn/tiles/512/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamFja2Fsa3NuZSIsImEiOiJja3d6cDVhMWQweDN6Mm9ybnIwem01Mnd0In0._72XTkf8vYbmMH7Jc8dkrg', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            preferCanvas: true,
            maxZoom: 13,
            minZoom: 3,
            tileSize: 512,
            zoomOffset: -1,
            tap: false,
            accessToken: 'pk.eyJ1IjoiamFja2Fsa3NuZSIsImEiOiJja3d6cDVhMWQweDN6Mm9ybnIwem01Mnd0In0._72XTkf8vYbmMH7Jc8dkrg'
        });

        setMapOverlay(mapboxtiles);
        let map = L.map('map').addLayer(mapboxtiles).setView(mapPos, mapZoom).on("moveend", (e) => {
            let pos = e.target.getCenter();
            setMapZoom(e.target._zoom);
            setMapPos([pos.lat, pos.lng]);
        });
        map.doubleClickZoom.disable();
        largeMarkers = L.markerClusterGroup({
            polygonOptions: {
                fillColor: '#E16EC4',
                color: '#E16EC4',
                weight: 0.5,
                opacity: 1,
                fillOpacity: 0.5
            }
        }).addTo(map);
        setmymap(map);

        // prep population
        function style(feature) {
            return {
                fillColor: getColor(feature.properties),
                weight: 1,
                opacity: 0.1,
                color: theme.palette.secondary.light,
                fillOpacity: 0.65
            };
        };

        let mylegend = L.control({ position: 'bottomleft' });

        mylegend.onAdd = function () {
            var div = L.DomUtil.create('div', 'info legend'),
                grades = ["100", "100,000", "200,000", "300,000", "400,000", "500,000", "600,000", "700,000", "800,000", "900,000", "1,000,000"],
                labels = [];
            div.innerHTML += "<p><b>Population total</b></p>";
            // loop through our density intervals and generate a label with a colored square for each interval
            for (var i = 0; i < grades.length; i++) {
                let colors = interpolateColors(lowColour, highColour, (parseInt(grades[i].replace(/,/g, '')) / 100000))
                div.innerHTML +=
                    '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.7"></i> ' +
                    grades[i] + (grades[i + 1] ? '&nbsp;&ndash;&nbsp;' + grades[i + 1] + '<br>' : '+');
            }

            return div;
        };

        setpopLegend(mylegend);

        var borderLine = L.geoJSON(allStates, {
            style: {
                weight: 1,
                color: "black",
                fillColor: "#ece1cb",
                fillOpacity: 0
            }
        });

        var myoverlay = L.geoJSON(counties, {
            style: style,
            onEachFeature: function (feature, layer) {
                layer.on({
                    click: function () {
                        if (feature && feature.properties && feature.properties.STATE) {
                            if (parseInt(feature.properties.STATE)) {
                                setSelectedStateFromHeatMap(stateNumberToStateName(parseInt(feature.properties.STATE)));
                            }
                        }
                    }
                });
            }
        });

        let group = new L.FeatureGroup();
        group.addLayer(borderLine);
        group.addLayer(myoverlay);
        setpopOverlay(group);

        prepNormalOverlay(myoverlay, mylegend);
        prepGoogleTrendsOverlay(myoverlay, mylegend);

        // I'm Interested In Overlays
        prepSelfDistributionStatesOverlay(googleTrends, allStates, (val) => setSelfDistributionStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepSellingDTCViaOutOfStateRetailersStatesOverlay(googleTrends, allStates, (val) => setSellingDTCViaOutOfStateRetailersStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepStatesAllowSalesDTCFromIntrastateRetailersStatesOverlay(googleTrends, allStates, (val) => setStatesAllowSalesDTCFromIntrastateRetailersStateOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepSellingDirectlyToUSConsumersMyselfStatesOverlayStatesOverlay(googleTrends, allStates, (val) => setSellingDirectlyToUSConsumersMyselfStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepSellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay(googleTrends, allStates, (val) => setSellingToRetailersWhereTheRetailerSelfDistributesStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepSellingThroughThreeTierDistributionStatesOverlay(googleTrends, allStates, (val) => setSellingThroughThreeTierDistributionStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepHavingPotentialToShipViaWineDotComStatesOverlay(googleTrends, allStates, (val) => setHavingPotentialToShipViaWineDotComStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepStatesAllowDTCFromUSWineryStatesOverlay(googleTrends, allStates, (val) => setStatesAllowDTCFromUSWineryStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);

        // Constraints Overlays
        prepNonControlStateForWineStatesOverlay(googleTrends, allStates, (val) => setNonControlStateForWineStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);
        prepNonFranchiseStateForWineStatesOverlay(googleTrends, allStates, (val) => setNonFranchiseStateForWineStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, mylegend);

        // prep house hold income overlay
        function styleHouseHoldIncome(feature) {
            return {
                fillColor: getColorHouseHoldIncome(feature.properties),
                weight: 1,
                opacity: 0.3,
                color: theme.palette.secondary.light,
                fillOpacity: 0.65
            };
        };

        var borderLine = L.geoJSON(allStates, {
            style: {
                weight: 1,
                color: "black",
                fillColor: "#ece1cb",
                fillOpacity: 0
            }
        });

        myoverlay = L.geoJSON(counties, {
            style: styleHouseHoldIncome,
            onEachFeature: function (feature, layer) {
                layer.on({
                    click: function () {
                        if (feature && feature.properties && feature.properties.STATE) {
                            if (parseInt(feature.properties.STATE)) {
                                setSelectedStateFromHeatMap(stateNumberToStateName(parseInt(feature.properties.STATE)));
                            }
                        }
                    }
                });
            }
        });

        mylegend = L.control({ position: 'bottomleft' });

        mylegend.onAdd = function () {
            var div = L.DomUtil.create('div', 'info legend'),
                grades = ["15000", "20000", "50000", "75000", "150000"],
                labels = [];
            div.innerHTML += "<p><b>Household Income - 2019</b></p>";
            // loop through our density intervals and generate a label with a colored square for each interval
            for (var i = 0; i < grades.length; i++) {
                let colors; // = interpolateColors(lowColour, highColour, (parseInt(grades[i].replace(/,/g, '')) / 10000));
                if (grades[i] == "15000") {
                    div.innerHTML += '<i style="background:' + `rgb(255, 255, 255)` + ';opacity:0.65"></i> < $20000<br>';
                } else if (grades[i] == "20000") {
                    colors = [176, 233, 166];
                    div.innerHTML +=
                        '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.65"></i> $' +
                        grades[i] + (grades[i + 1] ? '&nbsp;&ndash;&nbsp;$' + grades[i + 1] + '<br>' : '+');
                } else if (grades[i] == "50000") {
                    colors = [23, 151, 23];
                    div.innerHTML +=
                        '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.65"></i> $' +
                        grades[i] + (grades[i + 1] ? '&nbsp;&ndash;&nbsp;$' + grades[i + 1] + '<br>' : '+');
                } else if (grades[i] == "75000") {
                    colors = [-585, 68, -1050];
                    div.innerHTML +=
                        '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.65"></i> $' +
                        grades[i] + (grades[i + 1] ? '&nbsp;&ndash;&nbsp;$' + grades[i + 1] + '<br>' : '+');
                } else {
                    colors = [-1425, -120, -2355];
                    div.innerHTML +=
                        '<i style="background:' + `rgb(${colors[0]},${colors[1]},${colors[2]})` + ';opacity:0.65"></i> $' +
                        grades[i] + (grades[i + 1] ? '&nbsp;&ndash;&nbsp;$' + grades[i + 1] + '<br>' : '+');
                }
            }

            return div;
        };

        setHouseHoldIncomeLegend(mylegend);

        let houseHoldIncomeGroup = new L.FeatureGroup();
        houseHoldIncomeGroup.addLayer(borderLine);
        houseHoldIncomeGroup.addLayer(myoverlay);
        setHouseHoldIncomeOverlay(houseHoldIncomeGroup);

        QRCode.toDataURL(currentUrl).then(setCurrentUrl);
    }, []);

    let tmp = [];
    let otherStatesLabel = [];

    if (mymap !== null) {
        largeMarkers = L.markerClusterGroup({
            polygonOptions: {
                fillColor: '#E16EC4',
                color: '#E16EC4',
                weight: 0.5,
                opacity: 1,
                fillOpacity: 0.5
            }
        }).addTo(mymap);
        mymap.on('zoomend', function (e) {
            let currentZoom = mymap.getZoom();
            if (currentZoom > 5) {
                if (otherStatesLabel.length > 0) {
                    let exist = false;
                    otherStatesLabel = otherStatesLabel.filter((item, pos) => {
                        if (item._tooltip.options.className.includes("houseHoldIncome")) {
                            return item;
                        }
                    });
                    for (let key in mymap._layers) {
                        for (let item of otherStatesLabel) {
                            if ((mymap._layers[key]._content === item._tooltip._content) && (mymap._layers[key]._content !== undefined)) {
                                exist = true;
                                break;
                            }
                        }
                    }
                    if (!exist) {
                        L.layerGroup(otherStatesLabel).addTo(mymap);
                    }
                }

            } else {
                for (let idx of otherStatesLabel) {
                    if (idx._tooltip && (idx._tooltip._content === "MD" || idx._tooltip._content === "DE" || idx._tooltip._content === "NJ" || idx._tooltip._content === "CT" || idx._tooltip._content === "RI" || idx._tooltip._content === "MA" || idx._tooltip._content === "NH" || idx._tooltip._content === "VT")) {
                        mymap.removeLayer(idx);
                        for (let key in mymap._layers) {
                            if ((mymap._layers[key]._content === idx._tooltip._content) && (mymap._layers[key]._content !== undefined)) {
                                mymap.removeLayer(mymap._layers[key]);
                                break;
                            }
                        }
                    }
                }

                if (tmp.length > 0 && currentZoom === 4) {
                    let exist = false;
                    tmp = tmp.filter((item, pos) => {
                        if (item._tooltip.options.className.includes("houseHoldIncome")) {
                            return item;
                        }
                    });
                    for (let key in mymap._layers) {
                        for (let item of tmp) {
                            if ((mymap._layers[key]._content === item._tooltip._content) && (mymap._layers[key]._content !== undefined)) {
                                exist = true;
                                break;
                            }
                        }
                    }
                    if (!exist) {
                        L.layerGroup(tmp).addTo(mymap);
                    }
                }
            }
        });
    }

    // When the states are initialised - use the database instead
    const generateLayers = () => {
        if (mymap !== null) {
            mymap.eachLayer(function (layer) {
                mymap.removeLayer(layer);
            });

            mymap.addLayer(mapOverlay);

            overlay != null && overlay.remove();

            overlay !== null && mymap.removeControl(overlay);
            setoverlay(null);

            legend !== null && mymap.removeControl(legend);
            setlegend(null);

            for (let marker of markers) {
                mymap.removeLayer(marker)
            }

            let heatmapLayers = document.getElementsByClassName("leaflet-heatmap-layer");

            if (heatmapLayers.length > 0) {
                for (let layer of heatmapLayers) {
                    layer.remove()
                }
            }

            setmarkers([]);

            var myLabels = [];
            var label;
            largeMarkers = L.markerClusterGroup({
                polygonOptions: {
                    fillColor: '#E16EC4',
                    color: '#E16EC4',
                    weight: 0.5,
                    opacity: 1,
                    fillOpacity: 0.5
                }
            }).addTo(mymap);

            L.geoJson(allStates, {
                onEachFeature: function (feature, layer) {
                    var exceptions = {
                        'ID': [44.0, -114.144273],
                        'NV': [40.0, -117.0224055],
                        'OK': [35.318842000000004, -97.5],
                        'TX': [31.194705999999996, -99.0],
                        'MN': [46.442508000000004, -94.5],
                        'LA': [31.013967, -92.5],
                        'MI': [43.5, -85.0],
                        'KY': [37.5, -85.0],
                        'FL': [28.061895999999997, -81.5],
                        'WV': [38.91993050000001, -80.5],
                        'VA': [37.6, -78.5],
                        'NC': [35.5, -79.0],
                        'MD': [39.4, -76.9],
                        'NJ': [40.176888, -74.5],
                        'MA': [42.3, -71.9],
                        'VT': [43.8710845, -72.7],
                        'NH': [43.55, -71.62404699999999],
                        'AK': [64.55, -153.5],
                        'DE': [38.7, -75.4],
                        'RI': ['41.7', '-71.6']
                    }


                    label = L.marker(exceptions[feature.properties.abbr] || layer.getBounds().getCenter(), {
                        title: feature.properties.name,
                        icon: L.divIcon({
                            html: "",
                            iconSize: [0, 0]
                        })
                    });
                    if (feature.properties.abbr === "MD" || feature.properties.abbr === "DE" || feature.properties.abbr === "NJ" || feature.properties.abbr === "CT" || feature.properties.abbr === "RI" || feature.properties.abbr === "MA" || feature.properties.abbr === "NH" || feature.properties.abbr === "VT") {
                        otherStatesLabel.push(label.bindTooltip(feature.properties.abbr,
                            {
                                permanent: true,
                                direction: 'center',
                                className: `${formData.Overlay} stateLabel ${feature.properties.abbr}`
                            }
                        ));
                    } else if (feature.properties.abbr === "DC") {

                    } else {
                        myLabels.push(label.bindTooltip(feature.properties.abbr,
                            {
                                permanent: true,
                                direction: 'center',
                                className: `${formData.Overlay} stateLabel ${feature.properties.abbr}`
                            }
                        ));
                    }
                },
            })

            if (mymap.getZoom() > 5) {
                L.layerGroup(otherStatesLabel).addTo(mymap);
            }

            L.layerGroup(myLabels).addTo(mymap);
            setmarkers(myLabels);
        }

        function style(feature) {
            return {
                fillColor: getColor(feature.properties),
                weight: 1,
                opacity: 0.5,
                color: theme.palette.secondary.light,
                fillOpacity: 0.8
            };
        };

        var myoverlay = L.geoJSON(counties, {
            style: style,
            onEachFeature: function (feature, layer) {
                layer.on({
                    click: function () {
                        if (feature && feature.properties && feature.properties.STATE) {
                            if (parseInt(feature.properties.STATE)) {
                                setSelectedStateFromHeatMap(stateNumberToStateName(parseInt(feature.properties.STATE)));
                            }
                        }
                    }
                });
            }
        });

        var filterConstruction = [];

        var hasFilter = false;

        if (formData.directlySelfDistributingToRetailers) {
            filterConstruction.push("directlySelfDistributingToRetailers");
            hasFilter = true;
        }

        if (formData.statesAllowSalesDTCFromOutOfStateRetailers) {
            filterConstruction.push("statesAllowSalesDTCFromOutOfStateRetailers");
            hasFilter = true;
        }

        if (formData.sellingDTCViaIntrastateRetailers) {
            filterConstruction.push("sellingDTCViaIntrastateRetailers");
            hasFilter = true;
        }

        if (formData.sellingDirectlyToUSConsumersMyself) {
            filterConstruction.push("sellingDirectlyToUSConsumersMyself");
            hasFilter = true;
        }

        if (formData.sellingToRetailersWhereTheRetailerSelfDistributes) {
            filterConstruction.push("sellingToRetailersWhereTheRetailerSelfDistributes");
            hasFilter = true;
        }

        if (formData.sellingThroughThreeTierDistribution) {
            filterConstruction.push("sellingThroughThreeTierDistribution");
            hasFilter = true;
        }

        if (formData.shippingViaLibDib) {
            filterConstruction.push("shippingViaLibDib");
            hasFilter = true;
        }

        if (formData.havingPotentialToShipViaVivino247Solution) {
            filterConstruction.push("havingPotentialToShipViaVivino247Solution");
            hasFilter = true;
        }

        if (formData.havingPotentialToShipViaWineDotCom) {
            filterConstruction.push("havingPotentialToShipViaWineDotCom");
            hasFilter = true;
        }

        if (formData.nonControlStateForWine) {
            filterConstruction.push("nonControlStateForWine");
            hasFilter = true;
        }

        if (formData.nonFranchiseStateForWine) {
            filterConstruction.push("nonFranchiseStateForWine");
            hasFilter = true;
        }

        if (hasFilter) {
            prepGenericStatesOverlay(stateData, allStates, (val) => setGenericStatesOverlay(val), (val) => setSelectedStateFromHeatMap(val), myoverlay, null, filterConstruction);
        } else {
            if (formData.Overlay === "population") {
                mymap.addLayer(popOverlay);
                popLegend.addTo(mymap);
                setoverlay(popOverlay);
                setlegend(popLegend);

            } else if (formData.Overlay === "googleTrendsAustraliaVisa") {
                mymap.addLayer(googleTrendsOverlay);
                googleTrendsLegend.addTo(mymap);
                setoverlay(googleTrendsOverlay);
                setlegend(googleTrendsLegend);
            } else if (formData.Overlay === "houseHoldIncome") {
                mymap.addLayer(houseHoldIncomeOverlay);
                houseHoldIncomeLegend.addTo(mymap);
                setoverlay(houseHoldIncomeOverlay);
                setlegend(houseHoldIncomeLegend);
            } else {
                setGenericStatesOverlay(null);
                mymap.addLayer(normalOverlay);
                setoverlay(normalOverlay);
            }

        }

        if (formData["Overlay"] === "houseHoldIncome") {
            setNewHampshire(maxhouseHoldIncomeNewHampshire);
            setVermont(maxhouseHoldIncomeVermont);
            setMassachusetts(maxhouseHoldIncomeMassachusetts);
            setRhodeIsland(maxhouseHoldIncomeRhodeIsland);
            setConnecticut(maxhouseHoldIncomeConnecticut);
            setNewJersey(maxhouseHoldIncomeNewJersey);
            setDelaware(maxhouseHoldIncomeDelaware);
            setMaryland(maxhouseHoldIncomeMaryland);
            setDistrictofColumbia(maxhouseHoldIncomeDistrictofColumbia);
            setAlaska(maxhouseHoldIncomeAlaska);
            setHawaii(maxhouseHoldIncomeHawaii);

        } else if (formData["Overlay"] === "population") {
            setNewHampshire(maxPopulationNewHampshire);
            setVermont(maxPopulationVermont);
            setMassachusetts(maxPopulationMassachusetts);
            setRhodeIsland(maxPopulationRhodeIsland);
            setConnecticut(maxPopulationConnecticut);
            setNewJersey(maxPopulationNewJersey);
            setDelaware(maxPopulationDelaware);
            setMaryland(maxPopulationMaryland);
            setDistrictofColumbia(maxPopulationDistrictofColumbia);
            setAlaska(maxPopulationAlaska);
            setHawaii(maxPopulationHawaii);

        } else if (formData["Overlay"] === "googleTrendsAustraliaVisa") {
            setNewHampshire(imageGoogleNewHampshire);
            setVermont(imageGoogleVermont);
            setMassachusetts(imageGoogleMassachusetts);
            setRhodeIsland(imageGoogleRhodeIsland);
            setConnecticut(imageGoogleConnecticut);
            setNewJersey(imageGoogleNewJersey);
            setDelaware(imageGoogleDelaware);
            setMaryland(imageGoogleMaryland);
            setDistrictofColumbia(imageGoogleDistrictofColumbia);
            setAlaska(imageGoogleAlaska);
            setHawaii(imageGoogleHawaii);

        } else if (formData["Overlay"] === "none") {
            if (formData.directlySelfDistributingToRetailers) {
                selectedFilterImage.push("directlySelfDistributingToRetailers");
            }

            if (formData.statesAllowSalesDTCFromOutOfStateRetailers) {
                selectedFilterImage.push("statesAllowSalesDTCFromOutOfStateRetailers");
            }

            if (formData.sellingDTCViaIntrastateRetailers) {
                selectedFilterImage.push("sellingDTCViaIntrastateRetailers");
            }

            if (formData.sellingDirectlyToUSConsumersMyself) {
                selectedFilterImage.push("sellingDirectlyToUSConsumersMyself");
            }

            if (formData.sellingToRetailersWhereTheRetailerSelfDistributes) {
                selectedFilterImage.push("sellingToRetailersWhereTheRetailerSelfDistributes");
            }

            if (formData.sellingThroughThreeTierDistribution) {
                selectedFilterImage.push("sellingThroughThreeTierDistribution");
            }

            if (formData.shippingViaLibDib) {
                selectedFilterImage.push("shippingViaLibDib");
            }

            if (formData.havingPotentialToShipViaVivino247Solution) {
                selectedFilterImage.push("havingPotentialToShipViaVivino247Solution");
            }

            if (formData.havingPotentialToShipViaWineDotCom) {
                selectedFilterImage.push("havingPotentialToShipViaWineDotCom");
            }

            if (formData.nonControlStateForWine) {
                selectedFilterImage.push("nonControlStateForWine");
            }

            if (formData.nonFranchiseStateForWine) {
                selectedFilterImage.push("nonFranchiseStateForWine");
            }

            if (selectedFilterImage.length > 0) {
                setNewHampshire(getStateImageColors("New Hampshire", stateData, selectedFilterImage));
                setVermont(getStateImageColors("Vermont", stateData, selectedFilterImage));
                setMassachusetts(getStateImageColors("Massachusetts", stateData, selectedFilterImage));
                setRhodeIsland(getStateImageColors("Rhode Island", stateData, selectedFilterImage));
                setConnecticut(getStateImageColors("Connecticut", stateData, selectedFilterImage));
                setNewJersey(getStateImageColors("New Jersey", stateData, selectedFilterImage));
                setDelaware(getStateImageColors("Delaware", stateData, selectedFilterImage));
                setMaryland(getStateImageColors("Maryland", stateData, selectedFilterImage));
                setDistrictofColumbia(getStateImageColors("District Of Columbia", stateData, selectedFilterImage));
                setAlaska(getStateImageColors("Alaska", stateData, selectedFilterImage));
                setHawaii(getStateImageColors("Hawaii", stateData, selectedFilterImage));

            } else {
                setNewHampshire(null);
                setVermont(null);
                setMassachusetts(null);
                setRhodeIsland(null);
                setConnecticut(null);
                setNewJersey(null);
                setDelaware(null);
                setMaryland(null);
                setDistrictofColumbia(null);
                setAlaska(null);
                setHawaii(null);
            }
        }
    }

    function interpolateColor(color1, color2, factor) {
        if (arguments.length < 3) {
            factor = 0.5;
        }
        var result = color1.slice();
        for (var i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }
        return result;
    };

    // My function to interpolate between two colors completely, returning an array
    function interpolateColors(color1, color2, factor) {
        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        let interpolatedColor = interpolateColor(color1, color2, factor);

        return interpolatedColor;
    };

    function getColor(item) {
        // Get country populations
        let res = countyPopulation.find(s => (s.STATE === parseInt(item.STATE)) && (s.COUNTY === parseInt(item.COUNTY)));
        let factor = 0;
        if (!!res) {
            factor = res.CENSUS2019POP / 100000;
        }

        let colors;// = interpolateColors(lowColour, highColour, factor);

        //tiny box colour
        if (factor < 1) {
            colors = [255, 255, 255];
        } else if (factor < 2) {
            colors = [177, 238, 134];
        } else if (factor < 3) {
            colors = [99, 220, 77];
        } else if (factor < 4) {
            colors = [77, 203, 77];
        } else if (factor < 5) {
            colors = [77, 185, 77];
        } else if (factor < 6) {
            colors = [77, 168, 77];
        } else if (factor < 7) {
            colors = [77, 150, 77];
        } else if (factor < 8) {
            colors = [77, 133, 77];
        } else if (factor < 9) {
            colors = [77, 115, 77];
        } else if (factor < 10) {
            colors = [77, 98, 77];
        } else {
            colors = [77, 80, 77];
        }

        if (item.STATE === "33") {
            if (factor > tmpMaxPopulationNewHampshire) {
                setMaxPopulationNewHampshire(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationNewHampshire = factor;
            }
        } else if (item.STATE === "50") {
            if (factor > tmpMaxPopulationVermont) {
                setMaxPopulationVermont(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationVermont = factor;
            }
        } else if (item.STATE === "25") {
            if (factor > tmpMaxPopulationMassachusetts) {
                setMaxPopulationMassachusetts(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationMassachusetts = factor;
            }
        } else if (item.STATE === "44") {
            if (factor > tmpMaxPopulationRhodeIsland) {
                setMaxPopulationRhodeIsland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationRhodeIsland = factor;
            }
        } else if (item.STATE === "09") {
            if (factor > tmpMaxPopulationConnecticut) {
                setMaxPopulationConnecticut(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationConnecticut = factor;
            }
        } else if (item.STATE === "34") {
            if (factor > tmpMaxPopulationNewJersey) {
                setMaxPopulationNewJersey(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationNewJersey = factor;
            }
        } else if (item.STATE === "10") {
            if (factor > tmpMaxPopulationDelaware) {
                setMaxPopulationDelaware(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationDelaware = factor;
            }
        } else if (item.STATE === "24") {
            if (factor > tmpMaxPopulationMaryland) {
                setMaxPopulationMaryland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationMaryland = factor;
            }
        } else if (item.STATE === "11") {
            if (factor > tmpMaxPopulationDistrictofColumbia) {
                setMaxPopulationDistrictofColumbia(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationDistrictofColumbia = factor;
            }
        } else if (item.STATE === "02") {
            if (factor > tmpMaxPopulationAlaska) {
                setMaxPopulationAlaska(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationAlaska = factor;
            }
        } else if (item.STATE === "15") {
            if (factor > tmpMaxPopulationHawaii) {
                setMaxPopulationHawaii(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxPopulationHawaii = factor;
            }
        }

        //fill colour
        if (factor < 1) {
            colors = [255, 255, 255];
        } else if (factor < 2) {
            colors = [143, 230, 81];
        } else if (factor < 3) {
            colors = [31, 205, -93];
        } else if (factor < 4) {
            colors = [-81, 180, -267];
        } else if (factor < 5) {
            colors = [-193, 155, -441];
        } else if (factor < 6) {
            colors = [-305, 130, -615];
        } else if (factor < 7) {
            colors = [-417, 105, -789];
        } else if (factor < 8) {
            colors = [-529, 80, -963];
        } else if (factor < 9) {
            colors = [-641, 55, -1137];
        } else if (factor < 10) {
            colors = [-753, 30, -1311];
        } else {
            colors = [-865, 5, -1485];
        }

        return `rgb(${colors[0]},${colors[1]},${colors[2]})`;
    };

    function getColorGoogleTrends(item) {
        // Get country populations
        let res = googleTrends.find(s => (s.state === item.name));
        let factor = 0
        let visaTrend = 0;
        if (!!res) {
            visaTrend = res.australiaVisa;
            factor = visaTrend / 15;
        }

        let colors;//= interpolateColors(lowColour, highColour, factor);
        //tiny box colour
        if (visaTrend < 10) {
            colors = [206, 244, 180];
        } else if (visaTrend < 20) {
            colors = [159, 234, 105];
        } else if (visaTrend < 30) {
            colors = [110, 223, 90];
        } else if (visaTrend < 40) {
            colors = [90, 212, 90];
        } else if (visaTrend < 50) {
            colors = [90, 201, 90];
        } else if (visaTrend < 60) {
            colors = [90, 190, 90];
        } else if (visaTrend < 70) {
            colors = [90, 179, 90];
        } else if (visaTrend < 80) {
            colors = [90, 169, 90];
        } else if (visaTrend < 90) {
            colors = [90, 158, 90];
        } else if (visaTrend < 100 || visaTrend === 100) {
            colors = [90, 147, 90];
        }

        if (item.name === "New Hampshire") {
            setImageGoogleNewHampshire(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Vermont") {
            setImageGoogleVermont(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Massachusetts") {
            setImageGoogleMassachusetts(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Rhode Island") {
            setImageGoogleRhodeIsland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Connecticut") {
            setImageGoogleConnecticut(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "New Jersey") {
            setImageGoogleNewJersey(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Delaware") {
            setImageGoogleDelaware(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Maryland") {
            setImageGoogleMaryland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "District of Columbia") {
            setImageGoogleDistrictofColumbia(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Alaska") {
            setImageGoogleAlaska(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        } else if (item.name === "Hawaii") {
            setImageGoogleHawaii(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
        }

        //fill colour
        if (visaTrend < 10) {
            colors = [180, 238, 139];
        } else if (visaTrend < 20) {
            colors = [106, 222, 23];
        } else if (visaTrend < 30) {
            colors = [31, 205, -93];
        } else if (visaTrend < 40) {
            colors = [-44, 188, -209];
        } else if (visaTrend < 50) {
            colors = [-118, 172, -325];
        } else if (visaTrend < 60) {
            colors = [-193, 155, -441];
        } else if (visaTrend < 70) {
            colors = [-268, 138, -557];
        } else if (visaTrend < 80) {
            colors = [-342, 122, -673];
        } else if (visaTrend < 90) {
            colors = [-417, 105, -789];
        } else if (visaTrend < 100 || visaTrend === 100) {
            colors = [-492, 88, -905];
        }

        return `rgb(${colors[0]},${colors[1]},${colors[2]})`;
    };

    function getColorHouseHoldIncome(item) {
        // Get country populations
        let res = houseHoldIncomes.find(s => (s.state_code === item.STATE) && (s.county_code === item.COUNTY));
        let factor = 0
        if (!!res) {
            var incomeAmount = res.income2019;

            if (incomeAmount < 20000) incomeAmount = 15000

            factor = incomeAmount / 10000;
        }

        let colors; //= interpolateColors(lowColour, highColour, factor);

        //tiny box colour
        if (factor < 2) {
            colors = [255, 255, 255];
        } else if (factor < 5) {
            colors = [204, 241, 197];
        } else if (factor < 7.5) {
            colors = [105, 188, 105];
        } else if (factor < 15) {
            colors = [90, 134, 90];
        } else {
            colors = [90, 90, 90];
        }

        if (item.STATE === "33") {
            if (factor > tmpMaxhouseHoldIncomeNewHampshire) {
                setMaxHouseHoldIncomeNewHampshire(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeNewHampshire = factor;
            }
        } else if (item.STATE === "50") {
            if (factor > tmpMaxhouseHoldIncomeVermont) {
                setMaxHouseHoldIncomeVermont(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeVermont = factor;
            }
        } else if (item.STATE === "25") {
            if (factor > tmpMaxhouseHoldIncomeMassachusetts) {
                setMaxHouseHoldIncomeMassachusetts(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeMassachusetts = factor;
            }
        } else if (item.STATE === "44") {
            if (factor > tmpMaxhouseHoldIncomeRhodeIsland) {
                setMaxHouseHoldIncomeRhodeIsland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeRhodeIsland = factor;
            }
        } else if (item.STATE === "09") {
            if (factor > tmpMaxhouseHoldIncomeConnecticut) {
                setMaxHouseHoldIncomeConnecticut(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeConnecticut = factor;
            }
        } else if (item.STATE === "34") {
            if (factor > tmpMaxhouseHoldIncomeNewJersey) {
                setMaxHouseHoldIncomeNewJersey(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeNewJersey = factor;
            }
        } else if (item.STATE === "10") {
            if (factor > tmpMaxhouseHoldIncomeDelaware) {
                setMaxHouseHoldIncomeDelaware(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeDelaware = factor;
            }
        } else if (item.STATE === "24") {
            if (factor > tmpMaxhouseHoldIncomeMaryland) {
                setMaxHouseHoldIncomeMaryland(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeMaryland = factor;
            }
        } else if (item.STATE === "11") {
            if (factor > tmpMaxhouseHoldIncomeDistrictofColumbia) {
                setMaxHouseHoldIncomeDistrictofColumbia(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeDistrictofColumbia = factor;
            }
        } else if (item.STATE === "02") {
            if (factor > tmpMaxhouseHoldIncomeAlaska) {
                setMaxHouseHoldIncomeAlaska(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeAlaska = factor;
            }
        } else if (item.STATE === "15") {
            if (factor > tmpMaxhouseHoldIncomeHawaii) {
                setMaxHouseHoldIncomeHawaii(`rgb(${colors[0]},${colors[1]},${colors[2]})`);
                tmpMaxhouseHoldIncomeHawaii = factor;
            }
        }

        //fill colour
        if (factor < 2) {
            colors = [255, 255, 255];
        } else if (factor < 5) {
            colors = [176, 233, 166];
        } else if (factor < 7.5) {
            colors = [23, 151, 23];
        } else if (factor < 15) {
            colors = [-585, 68, -1050];
        } else {
            colors = [-1425, -120, -2355];
        }

        return `rgb(${colors[0]},${colors[1]},${colors[2]})`;
    };

    useEffect(() => {
        if (mymap !== null) {
            mymap.eachLayer(function (layer) {
                mymap.removeLayer(layer);
            });

            mymap.addLayer(mapOverlay);

            overlay != null && overlay.remove();

            overlay !== null && mymap.removeControl(overlay);
            setoverlay(null);

            legend !== null && mymap.removeControl(legend);
            setlegend(null);

            for (let marker of markers) {
                mymap.removeLayer(marker)
            }

            let heatmapLayers = document.getElementsByClassName("leaflet-heatmap-layer");

            if (heatmapLayers.length > 0) {
                for (let layer of heatmapLayers) {
                    layer.remove()
                }
            }

            setmarkers([]);
            largeMarkers = L.markerClusterGroup({
                polygonOptions: {
                    fillColor: '#E16EC4',
                    color: '#E16EC4',
                    weight: 0.5,
                    opacity: 1,
                    fillOpacity: 0.5
                }
            }).addTo(mymap);

            var appleIcon = L.icon({
                iconUrl: '/images/apple-icon.png',
                shadowUrl: '/images/apple-shadow.png',
                iconSize: [25],
                iconAnchor: [9, 40],
                shadowSize: [128, 128], // size of the shadow
            });

            var bevMoIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var binnyIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var abcIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var totalWineIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var specWineIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var goodyWineIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });

            var liquorBarnWineIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
                shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
                iconSize: [15, 25],
                iconAnchor: [9, 40],
                shadowSize: [0, 35],
            });


            if (formData.ShowSmallSellers2023Northeast === true || formData.ShowSmallSellers2023Midwest === true || formData.ShowSmallSellers2023South === true || formData.ShowSmallSellers2023West === true) {
                let url = '';
                if (formData.ShowSmallSellers2023Northeast) {
                    url = `${API_URL}/api/businesses/individual-stores-2023-northeast`;
                }
                if (formData.ShowSmallSellers2023Midwest) {
                    url = `${API_URL}/api/businesses/individual-stores-2023-midwest`;
                }
                if (formData.ShowSmallSellers2023South) {
                    url = `${API_URL}/api/businesses/individual-stores-2023-south`;
                }
                if (formData.ShowSmallSellers2023West) {
                    url = `${API_URL}/api/businesses/individual-stores-2023-west`;
                }

                fetch(url).then(res => {
                    return res.json()
                }).then(res => {
                    if (res.data.length > 0) {
                        var mymarkers = [], myaddressPoints = [];
                        for (let store of res.data) {
                            if (!!store.latitude && !!store.longitude) {

                                var marker = L.marker([store.latitude, store.longitude], {
                                    title: store.store[0].name
                                });

                                var icon = marker.options.icon;

                                icon.options.iconSize = [15, 25];
                                icon.options.shadowSize = [15, 25];
                                icon.options.iconAnchor = [0, 35];
                                icon.options.className = 'specialistIcon';

                                marker.setIcon(icon);

                                if (store.phone === undefined || store.phone == null || store.phone === "") {
                                    marker.bindPopup(`
                                            <h3>Store:</h3>
                                            ${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>\n`,
                                        { className: "specialist" }
                                    )
                                } else {
                                    marker.bindPopup(`
                                            <h3>Store:</h3>
                                            ${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>
                                            <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                        { className: "specialist" }
                                    )
                                }
                                largeMarkers.addLayer(marker);


                            }
                        }
                        L.layerGroup(mymarkers).addTo(mymap);

                        setmarkers(mymarkers);

                        L.heatLayer(myaddressPoints).addTo(mymap).redraw();

                        setIsLoading(false);
                    }
                });
            } else {
                fetch(`${API_URL}/api/businesses/individual-stores`).then(res => {
                    return res.json()
                }).then(res => {
                    if (res.data.length > 0) {
                        var mymarkers = [], myaddressPoints = [];
                        for (let store of res.data) {
                            if (!!store.latitude && !!store.longitude) {
                                if (formData.ShowAppleStores === true && store.store[0] && store.store[0].name == "Apple") {
                                    mymarkers.push(L.marker([store.latitude, store.longitude], {
                                        title: store.store[0].name,
                                        icon: appleIcon
                                    }).bindPopup(
                                        `<h3>Store:</h3>
                                        <p>${store.store[0].name}&nbsp;-&nbsp;${store.name}</p>
                                        <h3>Address:</h3>
                                        <p>${store.address}</p>
                                        <h3>Open Hours:</h3>
                                        <p>${store.openHours}</p>
                                        <h3>Store URL:</h3>
                                        <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                        <h3>Direction URL:</h3>
                                        <p><a target="_blank" href="${store.directionURL}">Google Map</a></p>
                                        <h3>Email:</h3><p><a target="_blank" href="mailto:${store.email}">${store.email}</a></p>
                                        <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                        { className: "appleIcon" }
                                    ));
                                }

                                if (formData.ShowLargeSellers === true && store.store[0].storeType == "Large Wine Store") {
                                    var marker = L.marker([store.latitude, store.longitude], {
                                        title: store.store[0].name
                                    });

                                    if (store.title === "BevMo!") {
                                        marker.setIcon(bevMoIcon);
                                        mymarkers.push(marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>\n`,
                                            { className: "largeSeller" }
                                        ));
                                    } else if (store.title === "Binny's Beverage Depot - Peoria") {
                                        marker.setIcon(binnyIcon);
                                        mymarkers.push(marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>\n`,
                                            { className: "largeSeller" }
                                        ));
                                    } else if (store.title === "ABC Fine Wine & Spirits") {
                                        marker.setIcon(abcIcon);
                                        mymarkers.push(marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>
                                            <h3>Open Hours:</h3>
                                            <p>${store.openHours}</p>
                                            <h3>Store URL:</h3>
                                            <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                            <h3>Direction URL:</h3>
                                            <p><a target="_blank" href="${store.directionURL}">Google Map</a></p>
                                            <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                            { className: "largeSeller" }
                                        ));
                                    } else if (store.title === "Total Wine & More") {
                                        marker.setIcon(totalWineIcon);
                                        mymarkers.push(marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>
                                            <h3>Open Hours:</h3>
                                            <p>${store.openHours}</p>
                                            <h3>Store URL:</h3>
                                            <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                            <h3>Direction URL:</h3>
                                            <p><a target="_blank" href="${store.directionURL}">Google Map</a></p>
                                            <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                            { className: "largeSeller" }
                                        ));
                                    } else if (store.title === "Spec's Wines, Spirits & Finer Foods") {
                                        marker.setIcon(specWineIcon);
                                        if (store.PriceRange === undefined || store.PriceRange === null || store.PriceRange === "") {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        } else {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Price Range:</h3>
                                                <p>${store.PriceRange}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        }
                                    } else if (store.title === "Goody Goody Liquor") {
                                        marker.setIcon(goodyWineIcon);
                                        if (store.PriceRange === undefined || store.PriceRange === null || store.PriceRange === "") {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        } else {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Price Range:</h3>
                                                <p>${store.PriceRange}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        }
                                    } else if (store.title === "Liquor Barn") {
                                        marker.setIcon(liquorBarnWineIcon);
                                        if (store.PriceRange === undefined || store.PriceRange === null || store.PriceRange === "") {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        } else {
                                            mymarkers.push(marker.bindPopup(`
                                                <h3>Store:</h3>
                                                <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                                <h3>Address:</h3>
                                                <p>${store.address}</p>
                                                <h3>Price Range:</h3>
                                                <p>${store.PriceRange}</p>
                                                <h3>Store URL:</h3>
                                                <p><a target="_blank" href="${store.URL}">Go to Store Website</a></p>
                                                <h3>Review:</h3>
                                                <p><a target="_blank" href="${store.Reviews_Link}">Leave a Review</a></p>
                                                <h3>Direction URL:</h3>
                                                <p><a target="_blank" href="${store.google_url}">Google Map</a></p>
                                                <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                                { className: "largeSeller" }
                                            ));
                                        }
                                    }
                                }

                                if (formData.ShowSmallSellers === true && store.store[0].storeType == "Small Wine Store") {
                                    var marker = L.marker([store.latitude, store.longitude], {
                                        title: store.store[0].name
                                    });

                                    var icon = marker.options.icon;

                                    icon.options.iconSize = [15, 25];
                                    icon.options.shadowSize = [15, 25];
                                    icon.options.iconAnchor = [0, 35];
                                    icon.options.className = 'specialistIcon';

                                    marker.setIcon(icon);

                                    /*mymarkers.push(marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>
                                            <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                            { className: "specialist" }
                                    ));*/
                                    if (store.phone === undefined || store.phone == null || store.phone === "") {
                                        marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>\n`,
                                            { className: "specialist" }
                                        )
                                    } else {
                                        marker.bindPopup(`
                                            <h3>Store:</h3>
                                            <p>${store.keyword}&nbsp;-&nbsp;${store.title}</p>
                                            <h3>Address:</h3>
                                            <p>${store.address}</p>
                                            <h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`,
                                            { className: "specialist" }
                                        )
                                    }
                                    largeMarkers.addLayer(marker);
                                }


                                if (formData.ShowSmallSellers === true && store.store[0].storeType == "Small Seller") {
                                    mymarkers.push(L.marker([store.latitude, store.longitude], {
                                        title: store.store[0].name
                                    }).bindPopup(`<h3>Store:</h3><p>${store.store[0].name}&nbsp;-&nbsp;${store.name}</p><h3>Email:</h3><p><a target="_blank" href="mailto:${store.email}">${store.email}</a></p><h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`));
                                }
                                if (formData.ShowLargeSellers === true && store.store[0].storeType == "Large Seller") {
                                    mymarkers.push(L.marker([store.latitude, store.longitude], {
                                        title: store.store[0].name
                                    }).bindPopup(`<h3>Store:</h3><p>${store.store[0].name}&nbsp;-&nbsp;${store.name}</p><h3>Email:</h3><p><a target="_blank" href="mailto:${store.email}">${store.email}</a></p><h3>Phone:</h3><p><a target="_blank" href="tel:${store.phone}">${store.phone}</a></p>\n`));
                                }
                                if (formData.Overlay === "starbucks" && store.store[0].storeType == "Starbucks") {
                                    myaddressPoints.push([store.latitude, store.longitude]);
                                }
                            }
                        }
                        L.layerGroup(mymarkers).addTo(mymap);

                        setmarkers(mymarkers);

                        L.heatLayer(myaddressPoints).addTo(mymap).redraw();

                        setIsLoading(false);
                    }
                });
            }



            generateLayers();

            var selectedOverlay = false;
        }

    }, [mymap, filter]);

    useEffect(() => {
        if (genericStatesOverlay) {
            mymap.addLayer(genericStatesOverlay);
            setoverlay(genericStatesOverlay);
        } else {
            console.log("No generic overlay");
        }


    }, [genericStatesOverlay])

    if (selectedStates === undefined || selectedStates === null) {
        selectedStates = [];
    }

    var user = navigator.userAgent;
    useEffect(() => {
        if (!((user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) && window.matchMedia('(orientation: portrait)').matches)) {
            openOtherStates ? setShowEastCoastStates(true) : setShowEastCoastStates(false);
        } else {
            setShowEastCoastStates(true);
        }
    }, [openOtherStates]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const copyLinkPopUp = () => {
        setShow(true);

        let url = '';
        let textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        url = window.document.location.href;
        textarea.value = url;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }

    const copyLink = () => {
        setclickedCopy(true);
    }

    return (
        <>
            <div id="map">
                <Badge className='parentBtn' badgeContent={localStorage.getItem("favStates") ? ((localStorage.getItem("favStates").split(",").length > 5 ? 5 : localStorage.getItem("favStates").split(",").length)) : 0} color="secondary" sx={{ position: 'absolute', top: 15, zIndex: 1000, right: 20 }}>
                    <Button className='comparisonTableBtn' variant="contained"
                        sx={{ border: '1px solid white', color: "black", backgroundColor: 'white', borderRadius: '5px', width: '13vw', padding: '6px 0px' }}
                        onClick={() => setShowComparisonTable()}
                    >
                        <ComparisonTable className='icon' /><Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw !important', margin: '0px !important' }}>See Comparison Table</Typography>
                    </Button>
                </Badge>
                <PDFMenu
                    formData={formData}
                    forceUpdate={forceUpdate}
                    stateData={stateData}
                    selectedStateData={selectedStates}
                />
                <div className="east-coast-buttons-mobile" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', zIndex: 1000, top: 10, right: 5 }}>
                    <Button className='otherStatesBtn'
                        sx={{
                            border: '1px solid white',
                            backgroundColor: showEastCoastStates ? '#b5dbf6 !important' : '#9acdf2 !important',
                            borderRadius: showEastCoastStates ? '5px' : '5px 5px 0px 0px',
                            color: "black", width: '13vw', padding: '6px 0px'
                        }}
                        onClick={() => setShowEastCoastStates(!showEastCoastStates)}
                    >
                        <Typography variant="body2" color={"black"}>
                            Other States
                        </Typography>
                        <IoIosArrowDown
                            className='icon'
                            style={{
                                mr: -1,
                                transform: showEastCoastStates ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                                margin: '0px -1vw 0px 5px'
                            }}
                        />
                    </Button>
                    {
                        (!showEastCoastStates) &&
                        <>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("New Hampshire"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: newHampshire === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: newHampshire, display: newHampshire === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: newHampshire === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >New Hampshire (NH)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Vermont"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: vermont === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: vermont, display: vermont === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: vermont === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Vermont (VT)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Massachusetts"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: massachusetts === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: massachusetts, display: massachusetts === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: massachusetts === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Massachusetts (MA)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Rhode Island"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: rhodeIsland === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: rhodeIsland, display: rhodeIsland === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: rhodeIsland === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Rhode Island (RI)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Connecticut"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: connecticut === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: connecticut, display: connecticut === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: connecticut === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Connecticut (CT)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("New Jersey"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: newJersey === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: newJersey, display: newJersey === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: newJersey === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >New Jersey (NJ)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Delaware"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: delaware === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: delaware, display: delaware === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: delaware === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Delaware (DE)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Maryland"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: maryland === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: maryland, display: maryland === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: maryland === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Maryland (MD)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("District Of Columbia"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: districtofColumbia === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: districtofColumbia, display: districtofColumbia === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: districtofColumbia === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >District Of Columbia (DC)</Typography>
                            </button>
                            <button className='childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Alaska"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: alaska === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: alaska, display: alaska === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: alaska === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Alaska (AK)</Typography>
                            </button>
                            <button className='lastOption childBtn' style={{ borderTop: '1px solid white' }} onClick={() => { setSelectedStateFromHeatMap("Hawaii"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: hawaii === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: hawaii, display: hawaii === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: hawaii === null ? '0px !important' : '0px 0px 0px 23px !important' }}
                                >Hawaii (HI)</Typography>
                            </button>
                        </>
                    }
                </div>
                <div className="east-coast-buttons" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', zIndex: 1000, bottom: 25, right: 20 }}>
                    {(showEastCoastStates) &&
                        <>
                            <button className='firstOption childBtn' onClick={() => { setSelectedStateFromHeatMap("New Hampshire"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: newHampshire === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: newHampshire, display: newHampshire === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: newHampshire === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >New Hampshire (NH)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Vermont"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: vermont === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: vermont, display: vermont === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: vermont === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Vermont (VT)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Massachusetts"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: massachusetts === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: massachusetts, display: massachusetts === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: massachusetts === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Massachusetts (MA)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Rhode Island"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: rhodeIsland === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: rhodeIsland, display: rhodeIsland === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: rhodeIsland === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Rhode Island (RI)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Connecticut"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: connecticut === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: connecticut, display: connecticut === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: connecticut === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Connecticut (CT)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("New Jersey"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: newJersey === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: newJersey, display: newJersey === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: newJersey === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >New Jersey (NJ)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Delaware"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: delaware === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: delaware, display: delaware === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: delaware === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Delaware (DE)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Maryland"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: maryland === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: maryland, display: maryland === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: maryland === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Maryland (MD)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("District Of Columbia"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: districtofColumbia === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: districtofColumbia, display: districtofColumbia === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: districtofColumbia === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >District Of Columbia (DC)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Alaska"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: alaska === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: alaska, display: alaska === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: alaska === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Alaska (AK)</Typography>
                            </button>
                            <button className='childBtn' onClick={() => { setSelectedStateFromHeatMap("Hawaii"); setShowEastCoastStates(false); }}>
                                <i className='scale' style={{ opacity: 1, backgroundColor: 'white', display: hawaii === null ? 'none' : '' }}></i>
                                <i className='scale' style={{ opacity: 1, backgroundColor: hawaii, display: hawaii === null ? 'none' : '' }}></i>
                                <Typography variant="body2" color={"black"}
                                    sx={{ margin: hawaii === null ? '0px !important' : '0px 0px 0px 23px !important', fontSize: '0.8vw !important' }}
                                >Hawaii (HI)</Typography>
                            </button>
                        </>}
                    <Button className='otherStatesBtn'
                        sx={{
                            border: '1px solid white',
                            backgroundColor: showEastCoastStates ? '#9acdf2 !important' : '#b5dbf6 !important',
                            borderRadius: showEastCoastStates ? '0px 0px 5px 5px' : '5px',
                            color: "black", width: '13vw', padding: '9.5px 0px'
                        }}
                        onClick={() => setShowEastCoastStates(!showEastCoastStates)}
                    >
                        <Typography variant="body2" color={"black"} sx={{ margin: '0px !important', fontSize: '0.8vw !important' }}>
                            Other States
                        </Typography>
                        <IoIosArrowDown
                            className='icon'
                            style={{
                                mr: -1,
                                transform: showEastCoastStates ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '0.2s',
                                margin: '0px -1vw 0px 5px'
                            }}
                        />
                    </Button>
                </div>
                <div className="mobileBestView" style={{ position: 'absolute', display: 'flex', flexDirection: 'column', zIndex: 1000, bottom: 20, right: 5 }}>
                    <button className="mobileBestViewBtn" onClick={copyLinkPopUp}>
                        <div className='bestView'>Best viewed on desktop</div>
                        <div className='shareLink'><FiShare2 className='shareIcon' />Share a link</div>
                    </button>
                </div>
            </div>

            <Dialog className='copyLinkDialog' open={show} onClose={handleClose}>
                <DialogTitle className='copyLinkDialogTitle'>
                    <CloseIcon className='closeCopyLinkDialog' onClick={handleClose} />
                </DialogTitle>
                <DialogContent className='copyLinkDialogContent'>
                    <Button className='copyLinkBtn' onClick={copyLink} data-clipboard-text={currentLink}>{clickedCopy ? 'COPIED' : 'COPY LINK'}</Button>
                    <img src={currentUrl}></img>
                    <DialogContentText className='copyLinkDialogText copyLinkQuesions'>
                        Have any questions?
                    </DialogContentText>
                    <DialogContentText className='copyLinkDialogText copyLinkContactUs'>
                        <div>
                            <a href="tel:+61-08-8133-4300">08 8133 4300</a>
                        </div>
                        <div>
                            <a href="mailto:info@agw.org.au">info@agw.org.au</a>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default HeatMap;