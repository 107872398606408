import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { getStateToolTipInfo } from "../copmonents/ComparisonTable/stateColumn/helper";
import { getImageForState } from "./getImageForState";

// const americaImage = require('../assets/svg/PDF/Blank_US_Map_(states_only).svg');  

const printContentInColumn = (heading, descriptionPoints, xAxis, yAxis, doc) => {
    // Print Heading
    doc.setFont(undefined, 'bold');
    doc.text(heading, xAxis, yAxis);
    doc.setFont(undefined, 'normal');

    yAxis += 6;

    // Print array of descriptive points
    for (var i = 0; i < descriptionPoints.length; i++) {
        doc.text(descriptionPoints[i], xAxis, yAxis);
        yAxis += 5;
    }

    yAxis += 7;

    return yAxis;
};

const GenerateComparisonTablePDF = (states) => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    var columns = [];
    let marginPopularMetro = [10, 0, 10, -15];
    let marginOthers = [10, 0, 10, 25];
    let heights = [];

    if (states.length === 3) {
        marginPopularMetro = [10, 0, 10, -10];
        marginOthers = [10, 0, 10, 20];
        heights = ["*", 30, 30, 30, 30, 30];
    }
    if (states.length === 4) {
        heights = ["*", 40, 40, 40, 40, 40];
    }
    if (states.length === 5) {
        heights = ["*", 50, 50, 50, 50, 50];
    }

    for(var i = 0; i < states.length; i++) {
        var state = states[i];

        columns.push(
            [
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Code', style: 'tableHeader' }],
                            [{
                                text: state.abrv, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: [10, 0, 10, 0],
                },
                {
                    table: {
                        headerRows: 1,
                        heights: [0, 350],
                        widths: ["*"],
                        body: [
                            [{ text: 'Description', style: 'tableHeader' }],
                            [state.name ? {text: getStateToolTipInfo(state.name), fontSize: 11} : ""],
                        ],
                    },
                    layout: 'noBorders',
                    margin: [10, 10, 10, 20]
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        heights: [0, 80],
                        body: [
                            [{ text: 'Most Populous Metro Areas', style: 'tableHeader' }],
                            [{
                                text: state.m1 + ", " + state.p1 + "\n" + 
                                    state.m2 + ", " + state.p2 + "\n" + 
                                    state.m3 + ", " + state.p3, fontSize: 11
                            }
                            ]
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginPopularMetro,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Sub Region', style: 'tableHeader' }],
                            [{
                                text: state.subRegion, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                }, {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: '2019 Population', style: 'tableHeader' }],
                            [{
                                text: state.population, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Per-capita wine consumption (L/person)', style: 'tableHeader' }],
                            [{
                                text: state.perCapitaWineConsumption, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Total Table Wine Consumption (`000 9L cases)', style: 'tableHeader' }],
                            [{
                                text: state.totalTableWineConsumption9LCases, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Imported share of table wine', style: 'tableHeader' }],
                            [{
                                text: state.importedShareTableWine, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Australian share of imported table wine', style: 'tableHeader' }],
                            [{
                                text: state.importedShareTableWine, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        heights: heights,
                        body: [
                            [{ text: 'Specialist Wine Chains', style: 'tableHeader' }],
                            [{
                                text: state.specialistWineChains, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Stability of price point below $7.50', style: 'tableHeader' }],
                            [{
                                text: state.below750TextualContent, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Stability of price point $7.50 to $14.99', style: 'tableHeader' }],
                            [{
                                text: state.between750_1499TextualContent, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Stability of price point $15 & above', style: 'tableHeader' }],
                            [{
                                text: state.fifteenAndAboveTextualContent, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                },
                /*{
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: 'Specialist Wine Chains', style: 'tableHeader' }],
                            [{
                                text: state.specialistWineChains, fontSize: 11
                            },
                            ],
                        ]
                    },
                    layout: 'noBorders',
                    margin: marginOthers,
                }*/
            ]
        )
    };

    var data = {
        pageOrientation: 'landscape',
        content: [
            {
                text: "Comparison Table",
                margin: [0, 0, 0, 20],
                style: "header",
            },
            {
                columns:
                    columns
                
            }
        ],
        styles: {
            header: {
                fontSize: 32,
                bold: true
            },

            table: {
                height: [20, 20, 20]
            },

            tableHeader: {
                bold: true,
                fontSize: 13
            }
        }
    }
    return data
}

const GenerateProvincePDF = (state, i = 0) => {
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    var data = {
        content: [
            {
                text: state.name + " (" + state.abrv + ")",
                margin: [0, 0, 0, 20],
                style: "header",
                pageBreak: i === 0 ? '' : 'before'
            },
            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 2 }] },
            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }] },
            {columns:[
                {image: getImageForState(state),
                fit:[200,200],
                margin: [0, 20],
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ["*"],
                        body: [
                            [{ text: '', style: 'tableHeader' }],
                            [getStateToolTipInfo(state.name)],
                        ]
                    },
                    layout: 'noBorders',
                    margin: [0, 10, 0, 20],
                },
            ]
        },
            {
                columns: [
                    [
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Code', style: 'tableHeader' }],
                                    [{
                                        text: state.abrv, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 0, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Most Populous Metro Areas', style: 'tableHeader' }],
                                    [{
                                        text: state.m1 + ", " + state.p1 + "\n" + 
                                            state.m2 + ", " + state.p2 + "\n" + 
                                            state.m3 + ", " + state.p3, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Sub Region', style: 'tableHeader' }],
                                    [{
                                        text: state.subRegion, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 4, 0, 20],
                        }, {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: '2019 Population', style: 'tableHeader' }],
                                    [{
                                        text: state.population, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Stability of price point below $7.50', style: 'tableHeader' }],
                                    [{
                                        text: state.below750TextualContent, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Stability of price point $7.50 to $14.99', style: 'tableHeader' }],
                                    [{
                                        text: state.between750_1499TextualContent, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Stability of price point $15 & above', style: 'tableHeader' }],
                                    [{
                                        text: state.fifteenAndAboveTextualContent, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        }
                    ],
                    [
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Per-capita wine consumption (L/person)', style: 'tableHeader' }],
                                    [{
                                        text: state.perCapitaWineConsumption, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 0, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Total Table Wine Consumption (`000 9L cases)', style: 'tableHeader' }],
                                    [{
                                        text: state.totalTableWineConsumption9LCases, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Imported share of table wine', style: 'tableHeader' }],
                                    [{
                                        text: state.importedShareTableWine, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 12, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Australian share of imported table wine', style: 'tableHeader' }],
                                    [{
                                        text: state.australianShareImportedTableWine, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: ["*"],
                                body: [
                                    [{ text: 'Specialist Wine Chains', style: 'tableHeader' }],
                                    [{
                                        text: state.specialistWineChains, fontSize: 11
                                    },
                                    ],
                                ]
                            },
                            layout: 'noBorders',
                            margin: [0, 10, 0, 20],
                        }
                    ]
                ],
            }
        ],
        styles: {
            header: {
                fontSize: 32,
                bold: true
            },

            tableHeader: {
                bold: true,
                fontSize: 13
            }
        }
    }
    return data
}
export const multiStates = states => {
    let docDefinition =  {content: [],
        styles: {
            header: {
                fontSize: 32,
                bold: true
            },

            tableHeader: {
                bold: true,
                fontSize: 13
            }
        }}
    for ( let i = 0; i < states.length; i ++) {
        for( let data  of GenerateProvincePDF(states[i], i).content){
            docDefinition.content.push(data)
        }

        
    }
    return docDefinition
}
export const printPDF = (state, multi=false) => {
    let docDefinition = null
    if(multi){
        docDefinition = multiStates(state)
    } else {
        docDefinition = GenerateProvincePDF(state);
    }

    pdfMake.createPdf(docDefinition).print();
}

export const printComparisonTable = (state) => {
    let docDefinition = null;

    docDefinition = GenerateComparisonTablePDF(state);

    pdfMake.createPdf(docDefinition).print();
}

export const savePDF = (state, multi=false) => {
    let docDefinition = GenerateProvincePDF(state)
    if(multi){
        docDefinition = multiStates(state)
    } else {
        docDefinition = GenerateProvincePDF(state);
    }

    pdfMake.createPdf(docDefinition).open();
}