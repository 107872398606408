export const getStateToolTipInfo = (stateName) => {
    switch (stateName) {
        case 'Alabama':
            return "Alabama is known for peanuts, southern hospitality, the beautiful gulf coast beaches, and college football. Alabama is known for being called “The Heart of Dixie.” You’ll know you’re in Alabama if you get that warm feeling accompanied by being called “honey,” Alabama is nicknamed the Yellowhammer State, after the state bird.";
        case 'Alaska':
            return "Alaska is known for glaciers, national parks, the vast wilderness, Northern lights, midnight sun, and cruises. Alaska is derived from the Aleut alaxsxa or alaxsxix, both meaning “mainland” or “great land.” There are more caribou living in the state than people";
        case 'Arizona':
            return "Arizona is the sixth largest state. Arizona has several national parks, monuments and forests in the state, including the Grand Canyon.  About a quarter of the state is made up of Native American reservations, including the Navajo Nation Reservation. Arizona originates from an earlier Spanish name, Arizonac, derived from the O'odham name alĭ ṣonak, meaning \"small spring\".";
        case 'Arkansas':
            return "Arkansas is known for its lakes, rivers, and hot springs, extreme weather and frequent storms, rice and poultry production, and the only active diamond mine in the United States. Arkansas is nicknamed the Natural State because of its beautiful lakes, rivers, mountains, and wildlife. Common trees include hickory, plum, hawthorn, and pine. The maple-leaf oak tree exists only in Arkansas.";
        case 'California':
            return "California is famous for the Golden Gate Bridge, Disneyland, and Hollywood. Nickname is the Golden State. California state is the top U.S. producer of lemons, apricots, avocados, dates, figs, grapes, kiwi, nectarines, peaches, raspberries, strawberries, and many others. California’s Death Valley is North America’s hottest desert. ";
        case 'Colorado':
            return "Colorado is known for its gorgeous landscapes, its wildlife, and the variety of activities it offers, from hiking, mountain biking, horse-riding, skiing to canoeing, or even just unwinding amidst nature. It is famous for its forests, mountains, mesas, hot springs, and sand dunes.  Nickname is The Centennial State. Spanish explorers named the river that ran through the area Colorado, meaning \"colored red,\" for its muddy, red hue.  Oil, coal, and natural gas are all mined here. Wind accounts for about 13 percent of the total electricity produced in Colorado.";
        case 'Connecticut':
            return "Connecticut is known for its beautiful fall foliage, Yale University, and being the home of ESPN.   Connecticut It is known as the \"Constitution State\", the \"Nutmeg State\", the \"Provisions State\", and the \"Land of Steady Habits\".  The most common tree in Connecticut is the red maple, but black birch, eastern hemlock, and sugar maple are also widespread. Wildflowers include colorful blanketflowers, orange daylilies, violets, and chicory. Nearly 60 percent of Connecticut is covered in woodland, providing lumber, firewood, and even maple syrup.";
        case 'Delaware':
            return "Delaware was the first State to ratify the Constitution of the United States.  Delaware has the longest twin span suspension bridge in the world is the Delaware Memorial Bridge that connects Delaware with New Jersey.  Delaware is the only state in the US without any national parks.  Known for is natural resources in it's mineral-rich soil. This dirt is ideal for farming. Soybeans, corn, potatoes, and peas are some of Delawares top crops. ";
        case 'District Of Columbia':
            return "District of Columbia (the city is often referred to as simply D.C.).  named after the first president of the United States, George Washington. But what about the initials? They stand for District of Columbia, named after Christopher Columbus. Most of the Smithsonian museums line the National Mall.   The National Mall receives about 24 million visitors each year.  D.C. is home to National Geographic.  The National Geographic Society was founded in D.C. in 1888.  American beauty rose is the states flower. ";
        case 'Florida':
            return "Florida is known for beach resorts, amusement parks, warm and sunny climate, and nautical recreation; attractions such as Walt Disney World.  Florida’s original Spanish name is La Florida, which means “place of flowers.” Florida’s most famous plant may be the orange tree—the orange blossom is the state flower. Sugarcane, fish, petroleum, and phosphate (used for fertilizer) are also top natural resources from Florida.";
        case 'Georgia':
            return "Georgia is known as the Peach State, but it's also the country's top producer of pecans, peanuts, and vidalia onions. Birthplace of Wine. Georgia is rich in hot spring and sulfur watersGeorgia was named after King George II, who approved the colony’s charter in 1732. Georgia grows the most peanuts in the United States, and it’s also the country’s largest producer of kaolin clay, which is often used in paper-making and beauty products.";
        case 'Hawaii':
            return "Hawaii’s nickname, the Aloha State, is no mystery: Aloha is a Hawaiian way to say hello and goodbye.  In 1810 Kamehameha became Hawaii’s first king. Known for its beautiful beaches—some of them with unusual colors. Many beaches are filled with white sand, but other Hawaiian shores are covered with green, red, pink, and even black sand.  Hawaii has only one native land mammal: the Hawaiian hoary bat. Sugarcane, pineapples, coffee, macadamia nuts, and flowers are all important sources of income for the state’s economy. Tourism is the state’s leading source of income.";
        case 'Idaho':
            return "Idaho’s name, some people thought it came from a Native American word meaning “gem of the mountains.” But it turns out the word “Idaho” was actually made up! Though “the Gem State” might come from a fake word, the state is actually full of gems. Idaho produces 72 types of gemstones, including rare star garnets, amethysts, rubies, and diamonds.The Columbia Plateau spreads across the southern part of the state. Volcanic eruptions that occurred between 15,000 and 2,000 years ago made this area almost entirely flat, with a few mountains.";
        case 'Illinois':
            return "The name Illinois comes from the Native American tribe living on the land when the area was first explored by Europeans.  Much of Illinois was once covered in prairie grass, earning the state its nickname, The Prairie State.  The state’s official snack food is popcorn, which can be served Chicago-style—that’s a mixture of cheese-covered and caramel-covered popcorn!  Illinois’ slogan is the Land of Lincoln because Abraham Lincoln lived there for 31 years.";
        case 'Indiana':
            return "Indiana is famous for its southern sensibilities, basketball, saying the word “ope,” and hosting the greatest spectacle in motor racing. The center of the state is covered by the Till Plains, which has low hills and valleys. Sycamore, eastern red cedar, white oak, and tulip poplar (the state tree) are a few of Indiana’s most widespread trees. The Indianapolis 500 car race, often called the Indy 500, has been held at a racetrack in the state capital nearly every year since 1911.";
        case 'Iowa':
            return "The official nickname of Iowa is the Hawkeye State.  There’s disagreement over the source of Iowa’s nickname, the Hawkeye State. Some say the name honors a Native American chief, Black Hawk. Others claim it was inspired by a character named Hawkeye in James Fenimore Cooper’s book, The Last of the Mohicans.  Sugar maple, sycamore, red cedar, and oak (the state tree) are a few of the most common trees that grow in Iowa. Indian blanketflower, squirrel corn, morning glory, and dalmatian toadflax are among the state’s wildflowers.  The house that inspired artist Grant Wood’s famous painting “American Gothic” stands in Eldon, Iowa. It’s a top selfie spot";
        case 'Kansas':
            return "Kansas, nicknamed the Sunflower State, but also known as the Jayhawk State, the Midway State, and the Wheat State. Kansas' nickname, the Sunflower State, comes from the fields of sunflowers that bloom here. These flowers are grown for their seeds and oil. People who live in or come from Kansas are called Kansans. Kansans are sometimes referred to as Jayhawkers.   Fertile farmland is one of the state’s top natural resources. The state’s most abundant crop is wheat—no other U.S. state produces as much.  Famous Kansans include pilot Amelia Earhart and environmental activist Erin Brockovich. ";
        case 'Kentucky':
            return "Kentucky is known as the Blue Grass State. Kentucky is also known for its unique blended culture, which includes horse racing, bourbon, moonshine, coal, \"My Old Kentucky Home\" historic state park, automobile manufacturing, tobacco, bluegrass music, college basketball, Louisville Slugger baseball bats & Kentucky Fried Chicken.  Nestled within the southeastern region of the United States, Kentucky is known for some of the richest cultural traditions in the country. The Kentucky Derby is the most famous horse race in the United States. Each year, roughly 150,000 people attend the race in-person. ";
        case 'Louisiana':
            return "Nicknamed the Pelican State.  Louisiana is a southeastern state that's a true “melting pot” of cultures: French, African, French-Canadian, and modern American. In 1861, during the Civil War, Louisiana left the Union. It rejoined the United States in 1868. Louisiana was named after King Louis XIV when the land was claimed for France in 1862. Louisiana’s top natural resources include fertile soil, natural gas, and oil. Louisiana is also the biggest U.S. producer of salt.";
        case 'Maine':
            return "Maine was given the nickname the Pine Tree State in honor of its many white pine trees.   Maine has 3,166 off-shore islands. Only about 1,200 Maine coast islands have an acre or more.  Maine harvests the majority of the lobster in the United States.  Several Native American tribes still exist in Maine, including descendants of the original Passamaquoddy, Maliseet, and Penobscot tribes.  The moose (the state animal), black bears, bobcats, white-tailed deer, and Canada lynx can be found in Maine.  Maine mines semi-precious stones including some of the world’s best tourmaline crystals. Maine produces a lot of gravel and limestone as well. Because of its rocky coastline, Maine is famous for lighthouses—it has more than 60 of them.";
        case 'Maryland':
            return "Nickname is the Old Line State.  Maryland was named after Queen Henrietta Maria of England. She was married to King Charles I, who granted permission for Maryland to become a colony.  Home to the Chesapeake Bay, Maryland is known for its blue crabs and the city of Baltimore, a major historic trading port, baseball power and birthplace of the national anthem. The Appalachian Plateau covers the northwestern corner of the state. It’s home to the Allegheny Mountains and Maryland’s highest point, Backbone Mountain. Maryland is known for fishing, and it produces the most blue crabs in the United States. The state is also known for mining coal, clays, natural gas, and limestone.";
        case 'Massachusetts':
            return "Known as The Bay State, it is one of the original 13 colonies and one of the six New England states, Massachusetts (officially called a commonwealth) is known for being the landing place of the Mayflower and the Pilgrims.  Mammals such as black bears, bobcats, eastern coyotes, moose, and white-tailed deer roam around Massachusetts.  Massachusetts is also filled with wildflowers such as New England asters, blue violets, wild bleeding hearts, black-eyed Susans, and oxeye daisies.  Massachusetts local farmers grow about 25 percent of the cranberries in the United States!";
        case 'Michigan':
            return "Nicknamed as the Great Lakes State.  Four of the Great Lakes share borders with Michigan: Superior, Huron, Michigan, and Erie.  Michigan is home to many types of animals—especially the widespread white-tailed deer (the state animal) and home to 360 bird species, including the rare Kirtland’s warbler.   Corn Flakes were “born” in Battle Creek in 1898. Michigan is known for fishing, thanks to its 3,288-mile coastline, the longest freshwater coastline in the United States. ";
        case 'Minnesota':
            return "Minnesota’s official nickname comes from its French state motto, adopted in 1861: l’étoile du nord meaning, “the star of the north.  Northern Minnesota boasts deep lakes and streams, rocky ridges, thick forests, and the state’s highest point, Eagle Mountain.  Minnesota’s Mesabi mountain range has been a huge producer of iron ore. Minnesota’s Mall of America is the biggest mall in the United States. It has more than 500 stores.  American martens, bobcats, muskrats, raccoons, and white-tailed deer are a few of Minnesota’s mammals. ";
        case 'Mississippi':
            return "Nicknamed the The Magnolia State and also known for its magnolias, catfish, bluegrass music, and southern charm.  Live oak, pine, hickory, pecan, and magnolia trees are common in Mississippi.  Mississippi’s super-fertile soil is another top resource, yielding soybeans, sweet potatoes, and other crops.  The musical style known as the blues started in Mississippi after the Civil War. It was inspired by songs sung by slaves as they worked in the fields as well as African spirituals, which are religious songs.";
        case 'Missouri':
            return "Show Me State is Missouris nickmame.  Ice-cream cones were first served in 1904 at the Louisiana Purchase Exposition world’s fair in St. Louis.  The tallest monument built in the U.S., the Gateway Arch, in St. Louis, is 630 feet tall.  Missouri is home to the biggest mammal in North America, the American bison. Missouri is the top producer of mined lead in the United States. The state also produces the most lime in the nation.";
        case 'Montana':
            return "Montana has no official nickname but several unofficial ones, most notably \"Big Sky Country\", \"The Treasure State\", \"Land of the Shining Mountains\", and \"The Last Best Place\".  Montana’s name comes from the Spanish word montaña, roughly meaning “mountainous.” That’s because the state has so many mountains—at least 300 peaks over 9,600 feet tall.  Many Native American tribes still live in Montana, including the Blackfeet, Crow, and Cheyenne.  Montana’s wildlife is very diverse. Its mountains are home to grizzly and black bears, bighorn sheep, gray wolves, and bison. Montana is the only state with river systems that empty into the Hudson Bay, Pacific Ocean, and the Gulf of Mexico.";
        case 'Nebraska':
            return "Nicknamed the Corn Husker State.  Nebraska is a midwestern state known for its farming, agricultural production, and natural attractions.  This state’s name comes from Native American words that mean “flat water.” The phrase refers to the Platte River, which runs through the state. In 1927, the ever-popular juice drink Kool-Aid was invented by Edwin Perkins in Hastings, Nebraska. Nebraska’s fertile soil is perfect for growing crops. That’s one reason why the area is called the “breadbasket of America.” Other natural resources include petroleum and gas. ";
        case 'Nevada':
            return "Known as the Silver State. Nevada’s name comes from the Spanish word nieve, which roughly means “snow-capped.” Flowering plants such as sagebrush (the state flower), tar-scented creosote bush, Indian blanketflower, and blooming cacti such as beavertail prickly pear also grow in Nevada. Nevada produces about three-quarters of all of the gold mined in the United Statesand some of the world’s best black opals.  Las Vegas is the state’s largest city, and it gets more than 42 million visitors a year. ";
        case 'New Hampshire':
            return "Known as the Granite State.  New Hampshire, a U.S. state in New England, is defined by its quaint towns and large expanses of wilderness.  The Mount Washington Cog Railway is the world's first mountain-climbing cog railway.   Although New Hampshire still has granite quarries, it’s better known for mining sand and gravel.  The orchid called pink lady’s slipper (the state wildflower).";
        case 'New Jersey':
            return "Nicknamed the Garden State.  New Jersey is known for many things including its beautiful beaches, busy roads, great food, intense politics, and diverse culture.  New Jersey has plenty of granite, sand, and gravel for mining; and is a leader in seafood production, particularly clams harvested off the coast.  New Jersey’s mammals include black bears, bobcats, red foxes, raccoons, and Tuckahoe masked shrews, which are similar to moles.";
        case 'New Mexico':
            return "The state has beautiful scenery from mountains to forests to deserts. This earned it the nickname the Land of Enchantment.  New Mexico is best known for its infamous hot air balloon festival held every year in early October.  The state can be divided into three regions. Sweeping across the east, the Great Plains region contains a high plateau with deep canyons.  About half of the state’s income from natural resources comes from oil natural gas.  New Mexico is the country’s top producer of perlite, a type of glass used in insulation and gardening. New Mexico is known for its turquoise. Today most mines have very little left, which is why a piece of turquoise can be worth thousands of dollars.";
        case 'New York':
            return "New York has to be one of the most famous cities in the world. Often referred to as the 'Big Apple', this vibrant city is known for its exclusive shops, flashy Broadway performances, and high-flying business tycoons.  New York was named after the British Duke of York. Many experts believe it’s nicknamed the Empire State because George Washington called New York “the seat of the Empire.”  Also famous for The Statue of Liberty, Central Park and The Empire State Building.  New York is known for supplying construction materials such as limestone, salt, sand, and gravel. It’s also one of the top states for garnets, though they’re used for industrial purposes instead of jewelry. ";
        case 'North Carolina':
            return "Nicknamed the Tar Heel State.  North Carolina got the nickname the Tar Heel State because workers here used to sell tar, pitch, and turpentine from the state's longleaf pine trees to be used in wooden ships. The state boasts 300 species of trees—including longleaf pine, shortleaf pine, and the American chestnut tree.  Brother inventors Wilbur and Orville Wright complete the first successful airplane ride in the dunes of Kitty Hawk, North Carolina, in 1903.";
        case 'North Dakota':
            return "Known as The Peace Garden State.  Dakota is a Native American Sioux word that roughly means “friend” or “ally.”  The world's largest french fry feed is held every year in Grand Forks, during Potato Bowl USA.  Heading west, the Drift Prairie region is dotted with hills, valleys, lakes and wetlands.  North Dakota contains the world’s biggest deposit of lignite, a type of soft, brown coal.   North Dakota’s wide-open land is home to mammals such as bison, bighorn sheep, moose, and pronghorns, plus bobcats, eastern spotted skunks, arctic shrews, and muskrats.";
        case 'Ohio':
            return "Ohio is historically known as the \"Buckeye State\" after its Ohio buckeye trees. The Appalachian Plateau is Ohio’s largest region and covers almost the entire eastern half of the state. It’s also the state’s most rugged area, with high hills and plunging valleys. Black bears, coyotes, bobcats, American beavers, and white-tailed deer (the state animal) are some of Ohio’s most common mammals. Besides Ohio’s fertile soil, the state is also known for coal, natural gas, and rock salt called halite. Mined from beneath Lake Erie, the state produces about five million tons of the salt a year.";
        case 'Oklahoma':
            return "Nicknamed the Sooner State.  In 1889 settlers were allowed to race into parts of Oklahoma and claim land for themselves. But some managed to get to these spots before the territory was officially open to them. They were called “sooners,” which eventually became the state’s nickname: the Sooner State.  It’s also a popular road trip destination, especially along the famous stretch of highway known as Route 66.  Oklahoma has more major tornadoes per square mile than any other state in the country.  Common trees that grow here include red maple, sweetgum, Ponderosa pine, hickory, and eastern redbud (Oklahoma’s state tree). Oklahoma is one of America’s top producers of petroleum, crude oil, and natural gas.  ";
        case 'Oregon':
            return "Known as the Beaver State and founded in 1859, Oregon is known for its wild west past, its quirky present-day traditions.  Oregon was nicknamed the Beaver State because early settlers used to trap these animals for their fur.  Crater Lake in south-central Oregon is the deepest lake in the United States (and one of the top 10 deepest in the world).  Oregon’s most common type of tree is the Douglas fir (the state tree). Oregon is also known for hemlock, red alder, bigleaf maple, and ponderosa pine.  Oregon provides the most softwood lumber in the United States.";
        case 'Pennsylvania':
            return "Pennsylvania’s nickname, The Keystone State comes from its central location among the 13 colonies.  Pennsylvania is one of the top states for coal mining. The Declaration of Independence, U.S. Constitution, and the Gettysburg Address were written in Pennsylvania.  ennsylvania has the highest number of Amish communities in the United States with the most significant and oldest Amish community in the world residing in Lancaster County.  Pennsylvania is known for handmade pretzels, which were originally brought by German settlers, whoopie pies, and Philly cheesesteaks.";
        case 'Rhode Island':
            return "Rhode Island is nicknamed the Ocean State because it has more than 400 miles of coastline. Everyone in the state lives within a half-hour drive to the sea.  The smallest state in the United States, Rhode Island is only about 48 miles long and 37 miles wide.  During colonial times many of Rhode Island’s mammals were hunted almost to extinction in the state. But some of those animals have made a comeback. Black bears, beavers, and fishers (a type of weasel) have all returned to the land.   The shipping gateway to the Atlantic Ocean.  Rhode Island’s Narragansett Bay is famous for its clams. Fried clam cakes, clam chowder, steamers (steamed clams), and stuffed clams are favorite snacks.";
        case 'South Carolina':
            return "The state’s nickname, the Palmetto State, was coined in honor of the state tree, the sabal palmetto.   South Carolina is known for its beaches, golf courses, and historic districts.  South Carolina was the first state to leave the Union in the years leading up to the Civil War, which started in 1861. It rejoined the United States in 1868.  South Carolina’s forests cover more than 67 percent of the state, and they’re also one of the state’s biggest natural resources—particularly loblolly pine.  ";
        case 'South Dakota':
            return "Known as The Mount Rushmore State.  Home to Mount Rushmore and the Badlands, the state is known for tourism and agriculture.  Today nine Native American tribes still reside in South Dakota.  Dakota is a Native American Sioux word that means roughly “friendly” or “allies.”  South Dakota’s number one industry is agriculture, thanks to its fertile soil.  Construction materials such as limestone, granite, sand, and gravel are among its most-mined resources.";
        case 'Tennessee':
            return "The Volunteer State, the state got its nickname from some impressive volunteer work during the War of 1812.  Tennessee's State Capital, Nashville, is known as \"Music City\" – the country music capital of the world. It lies on a fault line and in 1812 was the site of the worst earthquake in the continental United States.  One of Tennessee’s top resources is its fertile soil—in fact, almost half the state is farmland. The state is also known for mining minerals such as fluorite, calcite, pyrite (also called fool’s gold), marble, and zinc.  The “Grand Ole Opry” started as a country-music radio show in 1925. Singers Aretha Franklin, Dolly Parton, Kenny Chesney, and Justin Timberlake, were all born in Tennessee.";
        case 'Texas':
            return "Texas is known as the \"Lone Star State\" and is famous for its BBQ, live music, hot temperatures, and more. Texas is one of the biggest states by population and area in the United States.  Famous for Texas’ oil rigs—hundreds of them pump beneath the earth—the state is also famous for another natural resource: cattle. Texas has about 12 million cattle, more than any other state in the country. Texas also produces wool and cotton, and has one of the largest wind-power-producing farms in the world with more than 100,000 acres of wind turbines.  ";
        case 'Utah':
            return "Utah is nicknamed the Beehive State because the early pioneers considered themselves as hardworking as bees.  The state is known for its skiing, with the mountains near Salt Lake City collecting an average of 500 inches of snow per year. The Sundance Film Festival, an annual independent film festival is held in Park City, Utah. The sego lily is the state flower. Copper is the state mineral, and Utah contains one of the world’s largest open-pit copper mines. ";
        case 'Vermont':
            return "The nickname, The Green Mountain State honors the Green Mountain Boys, an army first created to protect Vermont’s land from New York.  Vermont is one of the six New England states (states whose first European settlers were Puritans from England).  Vermont’s famous maple syrup is made from sap from the sugar maple, the state tree. Vermont is the largest producer of maple syrup in the United States, turning out almost two million gallons a year.  The Ben & Jerry’s ice cream factory is in Waterbury. ";
        case 'Virginia':
            return "Virginia was named after Queen Elizabeth I, who was called the Virgin Queen. Since Virginia was the site of the first permanent English settlement, the state is known as “the birthplace of a nation or \"Old Dominion State\". One of Virginia’s top natural resources is its forests, which cover 62 percent of the state.  Virginia is known for the Appalachian Trail, oysters and Presidential homes.  Eight U.S. presidents were born in Virginia. ";
        case 'Washington':
            return "It’s nicknamed the Evergreen State because of its many forests, which cover over half the state.  Washington’s state tree is the western hemlock.  Washington is the only state to be named after a president.  Washington is home to Boeing, the world's largest aircraft maker. Washington produces more sweet cherries, apples, pears, and red raspberries than any other state. ";
        case 'West Virginia':
            return "Located in the Appalachian region, West Virginia has some of the most rugged land in the country. The state's rolling mountains, hills and valleys earned it the nickname of The Mountain State.  It is well-known for its range of outdoor activities, including hiking, mountain biking, skiing and whitewater rafting. It's the United States’ third most-forested state, West Virginia is known for its timber and protected woodlands.  Coal, oil, and natural gas are also important to the state’s economy.  The New River Gorge Bridge in Fayetteville is the longest steel-arch bridge in the western hemisphere. Built in 1977, it turned a 40-minute mountain drive into a one-minute crossing.";
        case 'Wisconsin':
            return "Wisconsin remains a center of German American and Scandinavian American culture. The state is one of the nation's leading dairy producers and is known as \"America's Dairyland\"; it is particularly famous for its cheese. The state is also famous for its beer, particularly and historically in Milwaukee.";
        case 'Wyoming':
            return "Wyoming is nicknamed \"The Equality State\" as it was the first state in the United States to give women the right to vote. Wyoming is known for its famous national parks, diverse wildlife, prairie and cowboy towns, pioneer museums, and hot springs. Wyoming is the least populated state in the Union. Wyoming produces the most coal in the United States.";
    }
}