import { ThemeProvider } from '@emotion/react';
import { Button, createTheme, Grid, Slide, Stack, Typography } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import React from 'react';
import { printComparisonTable } from '../../PDF/ProvinceGeneration';
import { StateColumnComponent } from './stateColumn';
import {ReactComponent as PrinterIcon} from '../../assets/images/print@1.svg';
import { useEffect } from 'react';

const backButtonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    borderRadius: '2px',
                    color: 'black',
                    height: '36px',
                    '&:hover': {
                        backgroundColor: 'lightgrey'
                    },
                    margin: "10px 0px 0px",
                    minWidth: '150px',
                    marginLeft: 'auto',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '15px',
                    marginRight: '15px'
                }
            }
        }
    }
});

export const ComparisonTableComponent = ({ display, setShowComparisonTable, selectedStates, stateData, setSelectedStates }) => {
    useEffect(() => {
        if (localStorage.getItem("favStates")) {
            setSelectedStates(localStorage.getItem("favStates").split(","));
            selectedStates = localStorage.getItem("favStates").split(",");
        }
    },[])
    
    const renderStateColumn = () => {
        var dom = [];
        let lastIdx;
        let padding = "0px";
        let width;
        if(selectedStates.length === 2) {
            padding = "10vw";
            width= "calc(100% / 3)";
        } else if (selectedStates.length === 3) {
            padding = "3vw";
            width= "calc(100% / 5)";
        } else if (selectedStates.length === 4) {
            padding = "0vw";
            width= "calc(100% / 6)";
        } else if (selectedStates.length === 5) {
            padding = "0vw";
            width= "calc(100% / 6)";
        }

        if (stateData.length > 0) {

            for (var i = 0; i < selectedStates.length; i++) {
                var stateId = selectedStates[i];
                
                if (i > 4) {
                    return dom;
                }
                if (selectedStates.length - 1 === i) {
                    lastIdx = "last";
                    padding = "0px";
                    if(selectedStates.length === 2) {
                        width= "calc(100% / 5)";
                    }
                }
                
                for (var j = 0; j < stateData.length; j++) {
                    var stateDataObj = stateData[j];

                    if (stateDataObj.FIPSState == stateId) {
                        dom.push(<StateColumnComponent key={stateId} stateObject={stateDataObj} last = {lastIdx} padding = {padding} width = {width} setSelectedStates={(val) => setSelectedStates(val)}/>)
                    }
                }
            }
        }

        return dom;
    };

    const getStatesAndPrintComparisonTable = () => {
        var statesToPrint = [];

        if (stateData.length > 0) {

            for (var i = 0; i < selectedStates.length; i++) {
                var stateId = selectedStates[i];

                for (var j = 0; j < stateData.length; j++) {
                    var stateDataObj = stateData[j];

                    if (stateDataObj.FIPSState == stateId) {
                        statesToPrint.push(stateDataObj);
                    }
                }
            }
        }

        printComparisonTable(statesToPrint);
    }

    return (
        <Slide direction="up" in={display} mountOnEnter unmountOnExit>
            <Grid className='comparisonTable' container sx={{
                position: 'fixed',
                zIndex: 1100,
                backgroundColor: '#f1f1f1',
                height: 'calc(100% - 80px)',
                width: '100vw',
                top: '80px',
                padding: '0px 80px'
            }}>
                <Grid item xs={12}>
                    {/** Heading */}
                    <Stack className='comparisonTableHeader' direction="row" sx={{
                        padding: '15px 0px',
                        justifyContent: 'space-between',
                        display: 'flex'
                    }}>
                        <Typography className='comparisonTableTitle' sx={{ fontSize: '27px', fontWeight: 'bold'}}>
                            Comparison table
                        </Typography>
                        <div className='child' >
                            <ThemeProvider theme={backButtonTheme}>
                                <Button className='printComparisonTable' variant="contained" sx={{ width: '15vw', borderRadius: '5px', padding: '6px 0px' }} onClick={() => getStatesAndPrintComparisonTable()}>
                                    <PrinterIcon style={{ width: '1.5vw', margin: '0 5px 0 0' }}/>
                                    <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                                        Print Comparison Table
                                    </Typography>
                                </Button>
                            </ThemeProvider>

                            <ThemeProvider theme={backButtonTheme}>
                                <Button className='backComparisonTable' variant="contained" sx={{ width: '15vw', borderRadius: '5px', padding: '6px 0px' }} onClick={() => setShowComparisonTable(false)}>
                                    <DoubleArrowIcon sx={{ width: "1.5vw", color: "black", height: "20px", transform: "rotate(180deg)" }} />
                                    <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                                        Back
                                    </Typography>
                                </Button>
                            </ThemeProvider>
                        </div>
                    </Stack>

                    <Grid className='comparisonTableContents' sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        paddingTop: '15px',
                        overflowY: 'scroll',
                        height: 'calc(100% - 75px)'
                    }}>
                        <Stack direction="row" justifyContent={"space-evenly"}>
                            {renderStateColumn()}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}