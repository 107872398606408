import React, { useState, useEffect } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Modal } from 'react-bootstrap';
import { Switch, Card, Typography, CardContent, CardHeader, Checkbox, Collapse, FormControlLabel, FormControl, FormGroup, RadioGroup, Radio } from '@mui/material';
import './collapsableCard.css';
import { Link } from 'react-router-dom';

export const CollapsableCard = ({ title, reset, index, formData, setFormData, selectedFilterValues, clickedReset, setClickedReset }) => {
    const [collapse, setCollapse] = useState(true);
    const [gottaSet, setGottaSet] = useState(false);
    const [show, setShow] = useState(false);

    const [getHeader, setHeader] = useState();
    const [getBody, setBody] = useState();

    const handleClose = () => setShow(false);
    const handleShow = (header, body) => {
        setShow(true);
        setHeader(header);
        setBody(body);
    }

    // TODO: Make this better (not hard-coded)
    const overlayFieldsToIgnore = [
        "ShowLargeSellers",
        "ShowSmallSellers",
        'ShowSmallSellers2023Northeast',
        'ShowSmallSellers2023Midwest',
        'ShowSmallSellers2023South',
        'ShowSmallSellers2023West',
        "ShowAppleStores"
    ]

    useEffect(() => {
        if (clickedReset) {
            !collapse && setCollapse(true);
            setClickedReset(false);
        }

        setGottaSet(!gottaSet);
    }, [reset]);

    const setAllFieldsButSectionToFalse = (sectionToIgnore) => {
        var keysOfFormData = Object.keys(formData);

        if (sectionToIgnore == "Overlay") {
            for (var i = 0; i < keysOfFormData.length; i++) {
                if (keysOfFormData[i] != sectionToIgnore && !overlayFieldsToIgnore.includes(keysOfFormData[i]))
                    formData[keysOfFormData[i]] = false;
            }
        }

        if (sectionToIgnore == "OtherFilters") {
            if (formData["Overlay"]) {
                formData["Overlay"] = "none";
            }
        }
    }

    const setMarkersToFalse = (sectionToIgnore) => {
        let markers = ['ShowLargeSellers', 'ShowSmallSellers', 'ShowAppleStores', 'ShowSmallSellers2023Northeast',
            'ShowSmallSellers2023Midwest',
            'ShowSmallSellers2023South',
            'ShowSmallSellers2023West',];

        for (let i = 0; i < markers.length; i++) {
            if (sectionToIgnore !== markers[i]) {
                formData[markers[i]] = false;
            } else {
                formData[markers[i]] = true;
            }
        }
    }

    const handleFormChange = async (e) => {
        if (e.target.name !== undefined) {
            if (e.target.name == "Overlay") {
                setAllFieldsButSectionToFalse("Overlay");
            } else if (e.target.name != "Overlay" && e.target.name != "ShowLargeSellers" && e.target.name != "ShowSmallSellers" && e.target.name != "ShowAppleStores" && e.target.name != 'ShowSmallSellers2023Northeast' && e.target.name != 'ShowSmallSellers2023Midwest' && e.target.name != 'ShowSmallSellers2023South' && e.target.name != 'ShowSmallSellers2023West') {
                setAllFieldsButSectionToFalse("OtherFilters");
            }

            if (e.target.name === 'ShowLargeSellers' || e.target.name === 'ShowSmallSellers' || e.target.name === 'ShowAppleStores' || e.target.name === 'ShowSmallSellers2023Northeast' || e.target.name === 'ShowSmallSellers2023Midwest' || e.target.name === 'ShowSmallSellers2023South' || e.target.name === 'ShowSmallSellers2023West') {
                if (formData[e.target.name] === true) {
                    formData[e.target.name] = false;
                } else {
                    setMarkersToFalse(e.target.name);
                }
            } else {
                formData[e.target.name] = (e.target.type === "checkbox" || e.target.type === "Toggle") ? e.target.checked : e.target.value;
            }
            setGottaSet(!gottaSet)
            setFormData(formData)
        }
    };

    return (
        <>
            <Card className='collapsableCard' sx={{ width: "100%" }}>
                <CardHeader className='filterHeader'
                    action={<KeyboardArrowDownOutlinedIcon className='filterArrowDown' sx={{
                        mr: -1,
                        transform: collapse ? 'rotate(-180deg)' : 'rotate(0)',
                        transition: '0.2s'
                    }} />}
                    onClick={() => setCollapse(!collapse)}
                    sx={{
                        cursor: 'pointer',
                        backgroundColor: "rgb(255,255,255)",
                        fontSize: "20px",
                        borderBottom: '1px solid lightgrey',
                        margin: "0px 5px 0px 5px",
                        padding: "10px 10px 10px 0px"
                    }}
                    color="primary"
                    title={title}
                    titleTypographyProps={{ variant: "h6", fontSize: "1.5rem" }}
                >
                </CardHeader>
                <Collapse in={collapse}>
                    <CardContent className='filterContent' sx={{ padding: '5px' }}>
                        <FormGroup sx={{ width: "100%" }} id={"ref-" + (title.replace(/\s/g, ""))} onClick={handleFormChange}>
                            {selectedFilterValues[index].map((value, index) => {
                                if (value[5] != false)
                                    switch (value[2]) {
                                        case "Title": {
                                            return (
                                                <div>
                                                    <FormControlLabel
                                                        className='label'
                                                        control={
                                                            <Radio className="radio"
                                                                style={{ visibility: 'hidden' }}
                                                                disabled
                                                            />
                                                        }
                                                        label={
                                                            <Typography className='filterOption' sx={{ display: "block", fontSize: "0.8vw" }}>
                                                                {value[0]}
                                                            </Typography>
                                                        }
                                                    />
                                                    {
                                                        !!value[4] &&
                                                        <HelpOutlineIcon className={navigator.userAgent.includes("Win") ? 'windows helper' : 'mac helper'} onClick={() => handleShow(value[0], value[4])} sx={{
                                                            marginLeft: "5px", height: "auto", width: "0.8vw", color: "#3f3f3f", cursor: "pointer"
                                                        }} />
                                                    }
                                                </div>
                                            )
                                        }
                                        case "Checkbox": {
                                            return (
                                                <div key={index}>
                                                    <div>
                                                        <FormControlLabel
                                                            className='label'
                                                            key={value[1]}
                                                            control={
                                                                (value[1] === 'ShowLargeSellers' || value[1] === 'ShowSmallSellers' || value[1] === 'ShowAppleStores' || value[1] === 'ShowSmallSellers2023Northeast' || value[1] === 'ShowSmallSellers2023Midwest' || value[1] === 'ShowSmallSellers2023South' || value[1] === 'ShowSmallSellers2023West') ?
                                                                    <Radio className="radio"
                                                                        name={value[1]}
                                                                        value={formData[value[1]]}
                                                                        checked={formData[value[1]]}
                                                                        style={{ marginLeft: (value[1] === 'ShowSmallSellers2023Northeast' || value[1] === 'ShowSmallSellers2023Midwest' || value[1] === 'ShowSmallSellers2023South' || value[1] === 'ShowSmallSellers2023West') ? '20px' : '0px' }}
                                                                    /> :
                                                                    <Checkbox className="radio"
                                                                        name={value[1]}
                                                                        value={formData[value[1]]}
                                                                        checked={formData[value[1]]}
                                                                    />
                                                            }
                                                            label={
                                                                <Typography className='filterOption' sx={{ display: "block", fontSize: "0.8vw" }}>
                                                                    {value[0]}
                                                                </Typography>
                                                            }
                                                        />
                                                        {
                                                            !!value[4] &&
                                                            <HelpOutlineIcon className={navigator.userAgent.includes("Win") ? 'windows helper' : 'mac helper'} onClick={() => handleShow(value[0], value[4])} sx={{
                                                                marginLeft: "5px", height: "auto", width: "0.8vw", color: "#3f3f3f", cursor: "pointer"
                                                            }} />
                                                        }
                                                    </div>
                                                    {/*
                                                    value[0] === 'Show Large Wine Sellers' && 
                                                    (
                                                        <div className='markerType'>
                                                            <img className='bevMoMarker' src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png'/>
                                                            : BevMo!
                                                            <img className='binnyMarker' src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png'/>
                                                            : Binny's
                                                            <img className='abcMarker' src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png'/>
                                                            : ABC
                                                            <img className='totalWineMarker' src='https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png'/>
                                                            : Total Wine
                                                        </div>
                                                    )
                                                    */}
                                                </div>
                                            );
                                        }
                                        case "Toggle": {
                                            return (<FormControlLabel key={value[1]} control={<Switch name={value[1]} value={formData[value[1]] ? formData[value[1]] : false} />} name={value[1]} label={value[0]} />);
                                        }
                                        case "Radio": return (
                                            <FormControl component="fieldset" key={index}>
                                                <RadioGroup
                                                    value={formData[value[1]]}
                                                    name={value[1]}
                                                    defaultValue={formData[value[1]]}
                                                >
                                                    {value[3].map((radioVal, index) => (
                                                        <div key={index}>
                                                            <FormControlLabel
                                                                className='label'
                                                                key={radioVal[1]}
                                                                control={
                                                                    <Radio className="radio"
                                                                        key={radioVal[1] + "-button"}
                                                                        value={radioVal[1]}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography className='filterOption' sx={{ display: "block", fontSize: "0.8vw" }}>
                                                                        {radioVal[0]}
                                                                    </Typography>
                                                                }
                                                            />
                                                            {
                                                                !!radioVal[2] &&
                                                                <HelpOutlineIcon className={navigator.userAgent.includes("Win") ? 'windows helper' : 'mac helper'} onClick={() => handleShow(radioVal[0], radioVal[2])} sx={{
                                                                    marginLeft: "5px", height: "auto", width: "0.8vw", color: "#3f3f3f", cursor: "pointer"
                                                                }} />
                                                            }
                                                        </div>
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        );
                                        default: return (<FormControlLabel label={value[0]} control={<Checkbox name={value[1]} />} />); break;
                                    }
                            })}
                        </FormGroup>
                    </CardContent>
                </Collapse>
            </Card>
            <Modal className='filterPopupContent' centered show={show} onHide={handleClose}>
                <Modal.Header className='filterPopupHeader' style={{ padding: "0px 5px 10px" }} closeButton>
                    <Modal.Title className='filterPopupTitle' style={{ fontSize: "20px" }}>{getHeader}</Modal.Title>
                </Modal.Header>
                {
                    getHeader && (!getHeader.includes("Show Median Household Income") &&
                        !getHeader.includes("Directly self distributing to retailers") &&
                        !getHeader.includes("Selling DTC via Out of State Retailers") &&
                        !getHeader.includes("Selling DTC via Intrastate Retailers") &&
                        !getHeader.includes("Selling directly to US consumers myself") &&
                        !getHeader.includes("Selling to retailers where the retailer self distributes") &&
                        !getHeader.includes("Having potential to ship via Wine.com") &&
                        !getHeader.includes("Having potential to sell via Vivino") &&
                        !getHeader.includes("Shipping via Lib Dib") &&
                        !getHeader.includes("Non-Control State for Wine") &&
                        !getHeader.includes("Non-Franchise State for Wine") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>{getBody}</Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Show Median Household Income") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "0px", fontSize: "1.7vh" }}>
                            <p>In the USA wine consumption increases with wealth and education. Higher median household income correlates with higher purchase frequency, higher wine involvement, higher curiosity, higher spend per occasion and higher use of online channels, (<Link to="//statistica.com" target={"_blank"}>statistica.com</Link>, Wine Intelligence)</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Directly self distributing to retailers") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "0px", fontSize: "1.7vh" }}>
                            <p>Direct self-distribution to retailers is targeted at smaller producers and subject to a range of rules and restrictions. If you are self-distributing, consider working with SevenFifty to support your sales and distribution efforts.</p>
                            <p>In CA only licensed instate manufacturers and wine growers may sell directly to retailers.</p>
                            <p>In CT only licensed wineries producing less than 100,000 gallons per year can self-distribute.</p>
                            <p>In DC by holders of an importation permit where the ABRA (Alcoholic Beverage Regulation Administration, District of Columbia) has been satisfied that the brands being distributed are not otherwise available.</p>
                            <p>In IL by holders of a valid wine shippers license that do not produce more than 25,000 gallons of wine annually solely or combined with another group and does not sell more than 5,000 gallons to retailers annually.</p>
                            <p>In ME only parties licensed as a small winery (who may not produce more than 50,000 gallons annually) may self-distribute.</p>
                            <p>In MD by holders of a Non-Resident Winery Permit, who may not produce more than 27,500 gallons annually.</p>
                            <p>In MO holders of an “Out of State Winery License” may sell to both wholesalers and retailers.</p>
                            <p>In NJ wineries producing less than 250,000 gallons per year may apply for an Out-of-State winery license permitting DTC and direct to retail sales. Wineries can also become licensed as New Jersey wholesalers.</p>
                            <p>In NY out of state wineries that establish a business location in New York can become licensed as a wholesaler with the right to distribute to other wholesalers and retailers.</p>
                            <p>In ND, licensed wineries that produce no more than 50,000 gallons per year may apply for additional licensing to sell directly to retailers up to 4,500 cases per annum.</p>
                            <p>In OH only wineries that qualify for a Federal Wine Tax credit and produce no more that 250,000 gallons per year can self-distribute.</p>
                            <p>In OK wineries that produce no more than 15,000 gallons may apply for a Winemaker Self-Distribution License but only if the winery uses its own vehicles.</p>
                            <p>In OR wineries holding a certificate of approval may apply for a Wine Self-Distribution license to retailers authorised by the OLCC (Oregon Liquor and Cannabis Commission).</p>
                            <p>In TN any party granted a Tennessee Winery License can sell up to 5 cases of wine per day to any individual retailer.</p>
                            <p>In VT holders of a Vermont Direct Ship to Retail License may sell up to 5,000 gallons a year directly to retailers, but no more than 100 gallons to any individual retailer.</p>
                            <p>In WA holders of a Certificate of Approval can sell directly to retailers if they receive an additional permit.</p>
                            <p>In WY holders of Out-of-State Shipper License for DTC shipping may also sell directly to Wyoming retailers, but only wines not already listed for sale by the Beverage Control Division.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Selling DTC via Out of State Retailers") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "0px", fontSize: "1.7vh" }}>
                            <p>Selling DTC via out-of-state retailers is a strategy that you can use to get into a state if you are already listed in a neighbouring state, either through your own DTC efforts, but more likely through an eCommerce or trade platform like <Link to="//wine.com" target={"_blank"}>Wine.com</Link>, <Link to="//pix.wine" target={"_blank"}>Pix.wine</Link>, Vivino or SevenFifty.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Selling DTC via Intrastate Retailers") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "0px", fontSize: "1.7vh" }}>
                            <p>Selling DTC via intrastate retailers is a strategy that you can within a state, if you are already distributed there, through your own DTC efforts or through an eCommerce or trade platform like <Link to="//wine.com" target={"_blank"}>Wine.com</Link>, <Link to="//pix.wine" target={"_blank"}>Pix.wine</Link>, Vivino or SevenFifty.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Selling directly to US consumers myself") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>If you want to sell directly to consumers yourself through a DTC platform or your own eCommerce you can set up in places like California with a retail or winery license. Examples are:</p>
                            <p>Type 85 online retail retailer can ship to 14 states</p>
                            <p>Type 17/20 retail license can ship to 16 states</p>
                            <p>Type 02 under Alternating Proprietorship can ship to as many as 44 states</p>
                            <p>If you choose to do this, seek your own legal advice before proceeding.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Selling to retailers where the retailer self distributes") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>In the small number of states where you can sell directly to retail under some circumstances, there are opportunities for both large and small sellers.</p>
                            <p>For large sellers, where there are very substantial direct to retail opportunities with large retailers, the retailer may choose to direct import, meaning that you do not need to build distribution infrastructure into that state.</p>
                            <p>For small sellers, there is a practice in those states of specialist retailers going out to seek products that they want to stock and direct importing them. It may be possible for you to generate such an opportunity.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Having potential to ship via Wine.com") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p><Link to="//wine.com" target={"_blank"}>Wine.com</Link> is headquartered in San Francisco, CA and operates Fulfillment Centres in seven states, listed by order of size: Westbury NY (Long Island), Berkeley CA, Medley, FL (Miami Metro), Houston TX, Maplewood NJ (New York Suburb), Avon MA (Boston Metro) and Columbus OH. These seven retail-licensed locations operate as distribution centres that legally sell and ship to over 96% of the U.S. wine drinking population. They operate under the Three-Tier Ecommerce (3TE) business model – as a licensed retailer, sourcing from local wholesalers in multiple states, carrying inventory, conducting pick/pack/ship operations, and delivering to the end-consumer.</p>
                            <p><i>“For items to be considered for our assortment, they must be available to us from state-licensed wholesalers who are capable of delivering to our <Link to="//wine.com" target={"_blank"}>Wine.com</Link> fulfillment centres on a weekly basis. Items are not required to be available in all markets but covering our two key markets of California and New York is critical for success. Note: <Link to="//wine.com" target={"_blank"}>Wine.com</Link> will not facilitate wholesaler or importer introductions on your behalf.</i></p>
                            <p><i>All Suppliers are required to submit vintage-specific content to support their items and brands—see Content Checklist file. If you are new to <Link to="//wine.com" target={"_blank"}>Wine.com</Link> and your items meet these criteria for addition to our assortment, email <a href='mailto:trade@wine.com'>trade@wine.com</a> with the following information:</i></p>
                            <ul>
                                <li><i>Name of winery</i></li>
                                <li><i>Name of wine(s)</i></li>
                                <li><i>Importer (if applicable)</i></li>
                                <li><i>Licensed Wholesaler for our seven markets (NY, CA, TX, FL, NJ, MA, OH)”</i></li>
                            </ul>
                            <p><Link to="//wine.com" target={"_blank"}>Wine.com</Link> requires and will link vintage-specific, ratings from Wine Spectator, Whiskey Advocate, Wine & Spirits, Robert Parker’s The Wine Advocate, Wine Enthusiast, Connoisseur’s Guide, Allen Meadows’ <Link to="//burghound.com" target={"_blank"}>Burghound.com</Link>, The Tasting Panel, Decanter, <Link to="//jamesSuckling.com" target={"_blank"}>JamesSuckling.com</Link>, Jeb Dunnuck, Vinous and or Tim Atkin. They also require good quality imagery, bottle shots and a brand video.</p>
                            <p>Wilfred Wong is <Link to="//wine.com" target={"_blank"}>Wine.com</Link>’s Chief Storyteller. Email him at Wilfred Wong, <Link to="//wine.com" target={"_blank"}>Wine.com</Link>’s storyteller at <a href='mailto:wilfred@wine.com'>wilfred@wine.com</a> to determine if his schedule allows for reviewing your wine. His reviews are a filter search option, as well as other reviews, like Wine Spectator.</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Having potential to sell via Vivino") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>Wineries can be available to be purchased by consumers through various retailers (merchants) who are registered on Vivino or can set themselves up as merchants (marketplace partners) on Vivino if they are appropriately licensed to sell in the USA. Vivino takes advantage of a range of DTC laws including companies selling directly to US consumers, out of state shipping, intrastate shipping and direct importing to make products as widely available as possible. They will work with you to set up as effectively as possible including the 24Seven shipping labels solution in the USA.</p>
                            <p>Vivino has potential to work internationally, however it is best to get yourself set up in Australia as a Vivino merchant to prepare for your international launch, by contacting the Australian team: <a href='mailto:james.fildes@vivino.com'>james.fildes@vivino.com</a></p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Shipping via Lib Dib") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>LibDib is a one-stop distribution partner that specialises in ‘innovative wine and spirits brands’ seeking to access new markets. It provides common carriers, just in time fulfillment and a platform for ordering and re-ordering by on- and off- trade.</p>
                            <p>As of December 2021, LibDib was available in CA, CO, CT, FL, NY, WI, Il, TX and MD. They are growing quickly so it is worth checking on <Link to="//libdib.com/states/" target={"_blank"}>https://libdib.com/states/</Link> where they are available Wine producers should check out LibDib here: <Link to="//libdib.com/sellers/" target={"_blank"}>https://libdib.com/sellers/</Link></p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Non-Control State for Wine") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>There are 17 states and four local jurisdictions that operate some form of control market for alcohol sales, both at wholesale and off-sale retail levels. Where retail is controlled as well as wholesale, the markets operate in a similar manner to the Scandinavian and Canadian provincial monopolies.</p>
                            <p>The US control jurisdictions mostly control the sale of spirits. However, some control wine sales as well and four jurisdictions have government operated retail sales of wine (Pennsylvania, Utah, New Hampshire and Montgomery County, Maryland).</p>
                            <p>The most interesting of these is Pennsylvania, as its state control body, the Pennsylvania Liquor Control Board (PLCB), is one of the top five largest purchasers of wine in the world and it is one of the USA’s top five most populous states. Sales of wine there have been on the increase since grocery stores were authorised to sell wine on a limited basis in 2016 (the stores still must purchase wine only through the PLCB).</p>
                            <p>Giving consumers easier access to wine purchases has benefited the sales of wine generally. All of this makes the Pennsylvania market a target worth considering. It is not easy to obtain a listing with the state, but with a listing and an in-state marketing approach, it can be very profitable.</p>
                            <p>(Adapted from Wine Australia Market Bulleting 240 “A brief guide to the USA’s three tier system”)</p>
                        </Modal.Body>)
                }
                {
                    getHeader && (getHeader.includes("Non-Franchise State for Wine") &&
                        <Modal.Body className='filterPopupBody' style={{ marginBottom: "7px", fontSize: "1.7vh" }}>
                            <p>Franchise laws create a legal relationship between a seller or brand of wine and its in-state wholesaler that make it extremely difficult to terminate, change or refuse to sell to a wholesaler to whom you have previously sold in a particular market.</p>
                            <p>In combination with laws only allowing one wholesaler to sell a brand by market, a wine can be tied up with a wholesaler who does not necessarily have much buy-in to your brand. These laws exist in around 19 states for wine, including some key markets like Massachusetts and Nevada.</p>
                            <p>Many of the laws require large compensation payments for terminated wholesalers, or simply prohibit termination other than in exceptional circumstances. For example, in Georgia, if you wish to change wholesaler because they aren’t performing, you can be forced to leave the market for four years before changing.</p>
                            <p>(Adapted from Wine Australia Market Bulleting 240 “A brief guide to the USA’s three tier system”)</p>
                        </Modal.Body>)
                }
                <Modal.Footer className='filterPopupFooter'>
                </Modal.Footer>
            </Modal>
        </>
    )
}