import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { API_URL } from "../../../App";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const PriceCalCMS = (param) => {
  const navigate = useNavigate();
  const editorRef = useRef([]);
  const [content, setContent] = useState(''); // Initialize with an empty string
  const [editorComponents, setEditorComponents] = useState([]);
  const [nextEditorId, setNextEditorId] = useState(1); 

  useEffect(() => {
    fetch(`${API_URL}/api/cms`)
      .then((res) => res.json())
      .then((res) => {
        if (res.data) {
          if (res.data.length>0 && res.data[0].priceCal.length>0) {
            setNextEditorId(res.data[0].priceCal.components[res.data[0].priceCal.components.length - 1].id + 1);
            setContent(res.data[0].priceCal.content); // Set content for page description
            setEditorComponents(res.data[0].priceCal.components); // Set editor components
          }
          else {
            const json = {
              content: 'We have created the USA price calculators to help you get started on working out your USA retail price from your FOB. These should be used as an estimate only. Final process can only be determined in consultation with your distribution partners.',
              components: [{ id: 1, content: '<p><strong>USA - Three Tier System</strong></p><p><a class="download" href="static/media/USA three tier model.a580e8cc.xlsx" target="_blank" rel="noopener" download="">USA three tier model.xlsx</a></p>' },
              { id: 2, content: '<p><strong>USA - Direct to retailer</strong></p><p><a class="download" download="" href="/static/media/USA Direct to retail.af5debb2.xlsx" target="_blank">USA Direct to retail.xlsx</a></p>' },
              { id: 3, content: '<p><strong>USA - eCommerce and DTC</strong></p><p><a class="download" download="" href="/static/media/USA eCommerce and Direct to consumer.67db3a9e.xlsx" target="_blank">USA eCommerce and Direct to consumer.xlsx</a></p>' },
              { id: 4, content: '<p><strong>USA - LibDib Model</strong></p><p><a class="download" download="" href="/static/media/USA LibDib.9ea86af2.xlsx" target="_blank">USA LibDib.xlsx</a></p>' }]
            };
            setNextEditorId(5);
            setContent(json.content); // Set content for page description
            setEditorComponents(json.components); // Set editor components      
          }
        }

      });
  }, []);

  const handleEditorChange = (content, index) => {
    const updatedEditorComponents = [...editorComponents];
    updatedEditorComponents[index].content = content;
    setEditorComponents(updatedEditorComponents);
  };

  const saveContent = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content, components: editorComponents }),
    };

    fetch(`${API_URL}/api/cms/priceCal`, requestOptions)
      .then((data) => {
        alert("Updated successfully");
      })
      .catch((err) => {
        alert("Update failed, please try again later");
      });
  };

  const addEditor = (index) => {
    const updatedEditorComponents = [...editorComponents];
    updatedEditorComponents.splice(index + 1, 0, { id: nextEditorId, content: "" });
    setEditorComponents(updatedEditorComponents);
    setNextEditorId(nextEditorId + 1);
  };

  const deleteEditor = (index) => {
    const updatedEditorComponents = [...editorComponents];
    updatedEditorComponents.splice(index, 1);
    setEditorComponents(updatedEditorComponents);
  };

  return (
    <div className="editor-body" style={{ margin: "10px 10%" }}>
      <h4>Page Description</h4>
      <Editor
        onInit={(evt, editor) => (editorRef.current[0] = editor)}
        initialValue={content}
        apiKey="vrd3z10hzn5xvtnx3jjtl03qkp9c029776f1cbjxit0oniy5"
        init={{
          height: 300,
          menubar: false,
          selector: "textarea",
          plugins: "anchor autolink charmap code emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | removeformat | code",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onChange={(e) => setContent(e.target.getContent())}
      />
      <h4 style={{ marginTop: '15px' }}>Calculators</h4>
      {editorComponents.map((component, index) => (
        <div style={{ marginTop: '15px', marginBottom: '15px', display:'flex',flexDirection:'row', alignItems:'center' }}>
          <Editor
            key={component.id}
            onInit={(evt, editor) => (editorRef.current[index + 1] = editor)}
            initialValue={component.content}
            apiKey="vrd3z10hzn5xvtnx3jjtl03qkp9c029776f1cbjxit0oniy5"
            init={{
              width: '100%',
              height: 200,
              menubar: false,
              selector: "textarea",
              plugins: "anchor autolink charmap code emoticons image link lists media searchreplace table visualblocks wordcount",
              toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | removeformat | code",
              content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
            onChange={(e) => handleEditorChange(e.target.getContent(), index)}
          />
           <div style={{float:'right', marginLeft:'20px'}}>
            <button type="button" onClick={() => addEditor(index)} style={{color:'green',marginBottom:'10px'}}><AddCircleOutlineIcon></AddCircleOutlineIcon></button>
            <button type="button" onClick={() => deleteEditor(index)} style={{color:'red',marginBottom:'10px'}}><RemoveCircleOutlineIcon></RemoveCircleOutlineIcon></button>
          </div>
        </div>
      ))}
      <button type="submit" id="saveBtn" style={{ marginTop: "10px", fontSize:'18px' }} onClick={saveContent}>
        Save
      </button>
    </div>
  );
};

export default PriceCalCMS;