import { Button, Card, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StateList from "./stateList";
import OtherResourcesCMS from "./OtherResourcesContent/otherResources";
import FaqCMS from "./FapContent/faq";
import PriceCalCMS from "./PriceCalContent/priceCal";
import InterestedInMenuItems from "./InterestedInMenuItems/interestedInMenuItems"
import './index.css';


const Settings = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isInterestedIn, setIsInterestedIn] = useState(true);
  const [isDirectSelfDistributing, setIsDirectSelfDistributing] = useState(false);
  const [isOutOfState, setIsOutOfState] = useState(false);
  const [isIntrastateRetailers, setIsIntrastateRetailers] = useState(false);
  const [isDirectlyToUsConsumers, setIsDirectlyToUsConsumers] = useState(false);
  const [isToSelfDistributes, setIsToSelfDistributes] = useState(false);
  const [is3Tier, setIs3Tier] = useState(false);
  const [isWine, setIWine] = useState(false);
  const [isVivino, setIsVivino] = useState(false);
  const [isLib, setIsLib] = useState(false);
  const [isNonControl, setNonControl] = useState(false);
  const [isNonFranchise, setNonFranchise] = useState(false);
  const [isOtherResources, setOtherResources] = useState(false);
  const [isFaq, setFaq] = useState(false);
  const [isPriceCal, setPriceCal] = useState(false);

  const navigate = useNavigate();
  var user = navigator.userAgent;

  const openMobileMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <div className="body" id="settings-body">
      <Toolbar
        className="topBar"
        sx={{ backgroundColor: "#ffffff", paddingTop: "5px" }}
      >
        <Link to="/">
          <img className="logo" src="/images/AGW.png" />
        </Link>
        <Typography variant="h5" component="div">
          Settings
        </Typography>
      </Toolbar>

      <Card variant="outlined">
        <div
          className="cal-toggle"
          style={{ margin: "10px 3%", display: "flex" }}
        >
          <p
            style={
              isInterestedIn
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(true);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            I'm interested in menu items
          </p>
          <p
            style={
              isDirectSelfDistributing
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(true);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Directly self distributing to retailers
          </p>
          <p
            style={
              isOutOfState
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(true);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Selling DTC via Out of State Retailers
          </p>
          <p
            style={
              isIntrastateRetailers
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(true);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Selling DTC via Intrastate Retailers
          </p>
          <p
            style={
              isDirectlyToUsConsumers
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(true);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Selling directly to US consumers myself
          </p>
          <p
            style={
              isToSelfDistributes
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(true);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Selling to retailers where the retailer self distributes
          </p>
          <p
            style={
              is3Tier
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(true);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Selling through 3 tier distribution
          </p>
          <p
            style={
              isWine
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={() => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(true);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Wine.com
          </p>
          <p
            style={
              isVivino
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(true);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Vivino
          </p>
          <p
            style={
              isLib
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(true);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Lib Dib
          </p>
          <p
            style={
              isNonControl
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(true);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Non-Control State
          </p>
          <p
            style={
              isNonFranchise
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(true);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Non-Franchise State
          </p>
          <p
            style={
              isOtherResources
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(true);
              setFaq(false);
              setPriceCal(false);
            }}
          >
            Other Resources Page
          </p>
          <p
            style={
              isFaq
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(true);
              setPriceCal(false);
            }}
          >
            FAQ Page
          </p>
          <p
            style={
              isPriceCal
                ? {
                  border: "2px solid",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
                : {
                  border: "none",
                  marginBottom: 0,
                  padding: "0 8px",
                  cursor: "pointer",
                }
            }
            onClick={async () => {
              setIsInterestedIn(false);
              setIsDirectSelfDistributing(false);
              setIsOutOfState(false);
              setIsIntrastateRetailers(false);
              setIsDirectlyToUsConsumers(false);
              setIsToSelfDistributes(false);
              setIs3Tier(false);
              setIWine(false);
              setIsVivino(false);
              setIsLib(false);
              setNonControl(false);
              setNonFranchise(false);
              setOtherResources(false);
              setFaq(false);
              setPriceCal(true);
            }}
          >
            US Retail Price Calculators Page
          </p>
        </div>
      </Card>
      <div style={{ marginTop: "10px" }}>
        {isInterestedIn ? <InterestedInMenuItems></InterestedInMenuItems> : <></>}
        {isDirectSelfDistributing ? <StateList type="directlySelfDistributingToRetailers"></StateList> : <></>}
        {isOutOfState ? <StateList type="statesAllowSalesDTCFromOutOfStateRetailers"></StateList> : <></>}
        {isIntrastateRetailers ? <StateList type="sellingDTCViaIntrastateRetailers"></StateList> : <></>}
        {isDirectlyToUsConsumers ? <StateList type="sellingDirectlyToUSConsumersMyself"></StateList> : <></>}
        {isToSelfDistributes ? <StateList type="sellingToRetailersWhereTheRetailerSelfDistributes"></StateList> : <></>}
        {is3Tier ? <StateList type="sellingThroughThreeTierDistribution"></StateList> : <></>}
        {isWine ? <StateList type="havingPotentialToShipViaWineDotCom"></StateList> : <></>}
        {isVivino ? <StateList type="havingPotentialToShipViaVivino247Solution"></StateList> : <></>}
        {isLib ? <StateList type="shippingViaLibDib"></StateList> : <></>}
        {isNonControl ? <StateList type="nonControlStateForWine"></StateList> : <></>}
        {isNonFranchise ? <StateList type="nonFranchiseStateForWine"></StateList> : <></>}
        {isOtherResources ? <OtherResourcesCMS></OtherResourcesCMS> : <></>}
        {isFaq ? <FaqCMS></FaqCMS> : <></>}
        {isPriceCal ? <PriceCalCMS></PriceCalCMS> : <></>}
      </div>
    </div>
  );
};

export default Settings;
