import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, Button, Snackbar } from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { HeatMap } from "../../copmonents"
import "./style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Section } from '../../copmonents/StatePopup/Section';
import { printPDF } from '../../PDF/ProvinceGeneration';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as AddToTable } from '../../assets/images/Group 1491-1.svg';
import { ReactComponent as PrintStateData } from '../../assets/images/Group 1491.svg';
import { ImBin } from "react-icons/im";
import ModalVideo from 'react-modal-video';
// import sampleVedio from '../../assets/video/Help recording.mp4';

export const MapPage = ({
    selectedResultsList,
    setSelectedResultsList,
    setSelectedResults,
    forceUpdate,
    setForceUpdate,
    selectedStates,
    setSelectedStates,
    handleBoxClick,
    drawerOpen,
    drawerWidth,
    distData,
    heatData,
    formData,
    filter,
    stateData,
    selectedPage = "dist",
    setShowComparisonTable,
    setIsLoading
}) => {
    var user = navigator.userAgent;
    const [mapPos, setMapPos] = React.useState(
        () => {
            if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
                if (window.matchMedia('(orientation: landscape)').matches) {
                    return [33.51201543411602, -94.34098123732997];
                } else {
                    return [33.51201543411602, -94.34098123732997];
                }
            } else {
                return [49.96619744521987, -98.16746338332771];
            }
        }
    );
    const [mapZoom, setMapZoom] = React.useState(3);
    const [selectedStateData, setSelectedStateData] = React.useState();
    const [positionForPopup, setPositionForPopup] = React.useState({ coordinates: [0, 0] });
    const [loadedBoxes, setLoadedBoxes] = React.useState(3);
    const [displayStateBox, setDisplayStateBox] = React.useState([0, false]);
    const [selectedState, SetSelectedState] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [firstAccess, setFirstAccess] = React.useState(Cookies.get('firstAccess'));

    const [selectedStateFromHeatMap, setSelectedStateFromHeatMap] = React.useState("");
    const [openOtherStates, setOpenOtherStates] = React.useState(true);
    const [open, setOpen] = useState(false);
    const [maxStates, setMaxStates] = useState(false);
    const [duplicateState, setDuplicateState] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [added, setAdded] = useState(false);

    const [showWelcome, setShowWelcome] = useState(true);
    const closeWelcome = () => {
        setShowWelcome(false);
        Cookies.set('firstAccess', 'true');
    }
    const handleShowWelcome = () => {
        setShowWelcome(true);
    }

    useEffect(() => {
        if (firstAccess === undefined) {
            setModalShow(true);
        }
    }, [firstAccess]);


    useEffect(() => {
        setSelectedStateData(stateData.find(s => s.name === selectedStateFromHeatMap));
    }, [selectedStateFromHeatMap]);

    useEffect(() => {
        if (selectedStateData !== undefined && selectedStateData !== null) {
            setOpenOtherStates(false);
        } else if (selectedStateData == null) {
            setOpenOtherStates(true);
        }
    }, [selectedStateData]);

    useEffect(() => {
        setLoadedBoxes(distData[0].length < loadedBoxes ? distData[0].length : loadedBoxes < 3 ? 3 : loadedBoxes)
    }, [distData]);

    React.useEffect(() => {
    }, [forceUpdate]);

    const ButtonStyle = {
        background: 'white',
        color: 'black',
        padding: '8px',
        height: '40px'
    };

    useEffect(() => {
        if (selectedStateData && localStorage.getItem("favStates")) {
            let tmp = localStorage.getItem("favStates").split(",");
            let tmpDuplicate = false;
            for (var i = 0; i < tmp.length; i++) {
                if (tmp[i] === selectedStateData.FIPSState.toString()) {
                    setAdded(true);
                    tmpDuplicate = true;
                    break;
                }
            }
            if (!tmpDuplicate) {
                setAdded(false);
            }
        }
    }, [selectedStateData])

    useEffect(() => {
        if (!localStorage.getItem("favStates")) {
            setAdded(false);
        }
        if (selectedStateData !== undefined && localStorage.getItem("favStates")) {
            let tmp = localStorage.getItem("favStates").split(",");
            let tmpDuplicate = false;
            for (var i = 0; i < tmp.length; i++) {
                if (tmp[i] === selectedStateData.FIPSState.toString()) {
                    tmpDuplicate = true;
                    break;
                }
            }
            if (!tmpDuplicate) {
                setAdded(false);
            }
        }
    }, [localStorage.getItem("favStates")])

    const deleteState = () => {
        if (selectedStateData) {
            setOpen(false);
            setDuplicateState(false);
            setMaxStates(false);

            if (localStorage.getItem("favStates")) {
                let tmp = localStorage.getItem("favStates").split(",");
                tmp = tmp.filter(value => value !== selectedStateData.FIPSState.toString());
                localStorage.setItem("favStates", tmp);
                setSelectedStates(tmp);
                setOpen(true);
                setDeleted(true);
                setAdded(false);

                clearTimeout(window.timeout);
                window.timeout = setTimeout(function () {
                    setOpen(false);
                }, 2000);
            }
        }
    }

    let timeout;
    const handleComparison = () => {
        if (selectedStateData) {
            setOpen(false);
            setDeleted(false);
            let tempArr = selectedStates;
            if (localStorage.getItem("favStates")) {
                setSelectedStates(localStorage.getItem("favStates").split(","));
                tempArr = localStorage.getItem("favStates").split(",");
            }
            var selectedStateID = selectedStateData.FIPSState;

            let index = tempArr.indexOf(selectedStateID);
            setDuplicateState(false);
            setMaxStates(false);

            if (!localStorage.getItem("favStates")) { //create localStorage and set item
                tempArr.splice(0, 0, selectedStateID);
                setSelectedStates(tempArr, "add");
                localStorage.setItem("favStates", tempArr);
                setOpen(true);
                setAdded(true);

                clearTimeout(window.timeout);
                window.timeout = setTimeout(function () {
                    setOpen(false);
                }, 2000);

            } else if (localStorage.getItem("favStates").split(",").length < 5) { //num item < 5
                let tmpDuplicate = false;
                let tmp = localStorage.getItem("favStates").split(",");
                for (var i = 0; i < tmp.length; i++) {
                    if (tmp[i] === selectedStateID.toString()) {    // duplicate item
                        setDuplicateState(true);
                        setOpen(true);
                        setMaxStates(false);
                        tmpDuplicate = true;

                        clearTimeout(window.timeout);
                        window.timeout = setTimeout(function () {
                            setOpen(false);
                        }, 2000);
                        break;
                    }
                }
                if (!tmpDuplicate) {  //if not duplicate, then add to localStorage
                    tempArr.splice(0, 0, selectedStateID);
                    setSelectedStates(tempArr, "add");
                    setOpen(true);
                    setAdded(true);
                    setDuplicateState(false);
                    setMaxStates(false);
                    localStorage.setItem("favStates", tempArr);

                    clearTimeout(window.timeout);
                    window.timeout = setTimeout(function () {
                        setOpen(false);
                    }, 2000);
                }
                // reach the max num of items
            } else if (localStorage.getItem("favStates").split(",").length === 5 || localStorage.getItem("favStates").split(",").length > 5) {
                setOpen(true);
                setMaxStates(true);
                setDuplicateState(false);

                clearTimeout(window.timeout);
                window.timeout = setTimeout(function () {
                    setOpen(false);
                }, 2000);
            }
        }
    };

    const emptySelectedState = () => {
        setSelectedStateData(null);
        setSelectedStateFromHeatMap(null);
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [videoOpen, setVideoOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');

    // const videoPlayer = () => {
    //     setVideoOpen(true);
    //     setVideoSrc(sampleVedio);
    // }

    return (
        <Grid className='mapPage' container spacing={2} sx={{ width: selectedStateData ? "calc(100% + 32px)" : "calc(100% + 16px)" }}>
            {selectedPage === "heat" &&
                <Grid className={selectedStateData ? "state-container containedData" : "state-container"} item xs={3} sx={{ maxWidth: '27%', flex: 'initial', width: selectedStateData ? '100%' : '0' }}>
                    <Stack className='statePopupHeader' spacing={0} sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: "1.5vw" }}>
                        <Typography className='headerText' style={{ fontSize: '1rem', fontWeight: 'bold', marginLeft: '5px' }}>{selectedStateData && (selectedStateData.name + " (" + selectedStateData.abrv + ")")}</Typography>
                        <CloseIcon className='statePopupClose' sx={{ cursor: 'pointer', width: '1rem', marginTop: '2px' }} onClick={emptySelectedState} />
                    </Stack>
                    <Stack spacing={2} sx={{
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                            width: "10px"
                        },
                        "&::-webkit-scrollbar-track": {
                            borderRadius: "10px"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#EFEFEF",
                            borderRadius: 10,
                        },
                        marginLeft: "1.5vw",
                        paddingBottom: "1px",

                    }}>
                        <div className="main-page-section-containers">
                            <Section
                                area="mainPage"
                                heading={"Total table wine consumption (’000 9L cases)"}
                                values={[selectedStateData && selectedStateData.totalTableWineConsumption9LCases]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Per-capita wine consumption (L/person) "}
                                values={[selectedStateData && selectedStateData.perCapitaWineConsumption]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Imported share of table wine"}
                                values={[selectedStateData && selectedStateData.importedShareTableWine]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Australian share of imported table wine"}
                                values={[selectedStateData && selectedStateData.australianShareImportedTableWine]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Stability of price point Below $7.50"}
                                values={[selectedStateData && selectedStateData.below750TextualContent]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Stability of price point $7.50 to $14.99"}
                                values={[selectedStateData && selectedStateData.between750_1499TextualContent]}
                            />
                            <Section
                                area="mainPage"
                                heading={"Stability of price point $15 & Above"}
                                values={[selectedStateData && selectedStateData.fifteenAndAboveTextualContent]}
                            />
                        </div>
                        {
                            added &&
                            <Button className='removeState' variant="contained"
                                sx={ButtonStyle}
                                onClick={deleteState}
                            >
                                <ImBin style={{ width: '1vw', height: 'auto', marginRight: '5px', color: '#4d4e4c' }} />
                                <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                                    Remove from Comparison Table
                                </Typography>
                            </Button>
                        }
                        {
                            !added &&
                            <Button className='addState' variant="contained"
                                sx={ButtonStyle}
                                onClick={handleComparison}
                            >
                                <AddToTable className='icon' style={{ width: '4vw' }} />
                                <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                                    Add to Comparison Table
                                </Typography>
                            </Button>
                        }
                        <Button className='printState' variant="contained"
                            sx={ButtonStyle}
                            onClick={() => printPDF(selectedStateData, false)}
                        >
                            <PrintStateData className='icon' style={{ width: '4vw' }} />
                            <Typography variant="body2" color={"black"} sx={{ fontSize: '0.8vw' }}>
                                Print Fact Sheet
                            </Typography>
                        </Button>
                        {
                            (!maxStates && !duplicateState && !deleted) &&
                            <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={'top' + 'right'} sx={{ position: 'absolute', top: '0px !important', right: '27.5vw !important' }}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontWeight: 'bold', backgroundColor: 'rgb(120 191 69) !important' }}>
                                    Added to comparison table
                                </Alert>
                            </Snackbar>

                        }
                        {
                            (maxStates && !deleted) &&
                            <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={'top' + 'right'} sx={{ position: 'absolute', top: '0px !important', right: '27.5vw !important' }}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontWeight: 'bold', backgroundColor: '#b41e8e !important' }}>
                                    Maximum 5 states can be added
                                </Alert>
                            </Snackbar>
                        }
                        {
                            (duplicateState && !deleted) &&
                            <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={'top' + 'right'} sx={{ position: 'absolute', top: '0px !important', right: '27.5vw !important' }}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontWeight: 'bold', backgroundColor: '#b41e8e !important' }}>
                                    This state is already in the comparison table
                                </Alert>
                            </Snackbar>
                        }
                        {
                            (deleted && !maxStates && !duplicateState) &&
                            <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={'top' + 'right'} sx={{ position: 'absolute', top: '0px !important', right: '27.5vw !important' }}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontWeight: 'bold', backgroundColor: 'rgb(120 191 69) !important' }}>
                                    Deleted from the comparison table
                                </Alert>
                            </Snackbar>
                        }
                    </Stack>
                </Grid>}
            <Grid className="map-container" item xs={12} sx={{ flex: 'initial', width: selectedStateData ? 'calc(73% - 16px)' : 'calc(100% - 16px)' }}>
                <Stack spacing={2.5} direction="row" sx={{ maxWidth: "200px" }}>
                    <Stack spacing={2.5} direction="column">
                        {!Cookies.get('firstAccess') && <Modal className='welcomeContent'
                            show={showWelcome} onHide={closeWelcome}
                            centered
                        >
                            <Modal.Header className='welcomeHeader' style={{ padding: "0px 5px 5px" }} closeButton>
                                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "20px" }}>
                                    <p style={{ marginBottom: "7px" }}>Welcome to <b>the US wine states analyser</b>.</p>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='welcomeBody' style={{ fontSize: "1.7vh" }}>
                                <ModalVideo channel='custom' isOpen={videoOpen} url={videoSrc} onClose={() => setVideoOpen(false)} />
                                <p>This tool was created to help Australian wine businesses explore the complex US market. The idea is that it will help you to identify which states and which channels you are interested in pursuing, simplifying your overall market effort from ‘the USA’ to specific targets within the USA.</p>

                                <p>The product includes:</p>
                                <ul className='productList'>
                                    <li>A zoomable and filterable map</li>
                                    <li>Information on each state</li>
                                    <li>A compare tool</li>
                                    <li>FAQ’s</li>
                                    <li>RRP calculators</li>
                                    <li>Information on identifying a distributor</li>
                                    <li>Other resources</li>
                                </ul>

                                <p><b>To get started</b>, we suggest that you Zoom in a little and either click a State to get some detailed market information or start trying out the filters.</p>
                                <p>The <b>overlay</b> filters help you to understand the market characteristics – household income, county population and interest in Australia.</p>
                                <p>The <b>markers</b> help you to identify cluster of shops, whether they are large wine sellers, small merchants/bars or Apple stores.</p>
                                <p><b>I’m interested in</b> helps you explore the various channels and which states they operate in.</p>
                                <p><b>Constraints</b> helps you to understand which marjets have ‘franchise’ and ‘control state’ constraints on distribution and sales. The help and FAQ’s will help you understand these terms.</p>
                                {/*<button onClick={videoPlayer}>Video</button>*/}
                                {/* <a className='helpRecording' onClick={videoPlayer}>Watch Help Recording</a> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='welcomeClose' variant="contained" sx={{ width: "13vw", padding: "6px 0px" }} onClick={closeWelcome}>
                                    <Typography variant="body2" color={"white"} sx={{ fontSize: '0.8vw', lineHeight: '20px' }}>
                                        Close
                                    </Typography>
                                </Button>
                            </Modal.Footer>
                        </Modal>}
                    </Stack>
                </Stack>

                <HeatMap
                    setShowComparisonTable={() => setShowComparisonTable(true)}
                    setSelectedStateFromHeatMap={(val) => setSelectedStateFromHeatMap(val)}
                    selectedPage={selectedPage}
                    stateData={stateData}
                    formData={formData}
                    filter={filter}
                    mapPos={mapPos}
                    setMapPos={setMapPos}
                    mapZoom={mapZoom}
                    setMapZoom={setMapZoom}
                    forceUpdate={forceUpdate}
                    selectedStates={selectedStates}
                    openOtherStates={openOtherStates}
                    setOpenOtherStates={setOpenOtherStates}
                    setIsLoading={setIsLoading}
                />
            </Grid>
        </Grid>
    )
}