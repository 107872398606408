import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';

export const Section = ({ heading, values, area }) => {
    const [show, setShow] = useState(false);
    const [mobile, setMobile] = useState(false);

    let desc;
    var user = navigator.userAgent;

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
            setMobile(true);
        }
    }, []);
    

    if (heading.includes("Total table wine consumption (’000 9L cases)")) {
        desc = "Total wine consumption in the state (9L cases)";
    } else if (heading.includes('Per-capita wine consumption (L/person)')) {
        desc = 'State wine consumption in L/person. The US average is 9.8L/person, which has been steadily increasing for 30 years. Wine consumption is higher in women than in men and increases with both education and wealth.';
    } else if (heading.includes('Imported share of table wine')) {
        desc = 'Percentage of wine consumption that is imported as a percentage of all wine consumed.';
    } else if (heading.includes('Australian share of imported table wine')) {
        desc = 'Australian share of imports as a percentage of all imports.';
    } else if (heading.includes('Stability of price point Below $7.50') || heading.includes('Stability of price point $7.50 to $14.99') || heading.includes('Stability of price point $15 & Above')) {
        desc = "$/L FOB (ex-Australia) from Wine Australia export reports. These should be treated carefully and used only as a guide as they represent those states listed in WALAS as a final destination - there is significant interstate movement of Australian wine after receival as ‘final destination’ in the US.";
    } else {
        desc = "";
    }
    
    return (
        <div className="section-container">
            <Typography className='statePopupSubtitle' variant={area == "mainPage" ? 'subtitle2' : 'body2'} sx={{ fontSize: "0.8vw" }}>
                <b>{heading}</b>
                {
                    area === "mainPage" && 
                    <HelpOutlineIcon onClick={() => setShow(true)} sx={{marginLeft:"0.2vw", height: "auto", width: "0.8vw", color: "#3f3f3f", cursor: "pointer"}}/> 
                }
            </Typography> 
            <div>
                <ul className="list-container">
                    {values.map((value, index) => {
                        return (
                            <li key={index}>
                                <Typography className='statePopupFigure' variant={area == "mainPage" ? 'subtitle2' : 'body2'} sx={{ fontSize: "0.8vw" }}>{value}</Typography>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <Dialog className='stateDialog' open={show} onClose={handleClose}>
                <DialogTitle className='stateDialogTitle'>
                    {heading}
                <CloseIcon className='closeStateDialog' onClick={handleClose}/>
                </DialogTitle>
                <DialogContent className='stateDialogContent'>
                    <DialogContentText className='stateDialogText'>
                        {desc}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}