import './App.css';
import { MapPage } from "./pages/MapPage/MapPage"
import { theme } from "./ThemeFile"
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ThemeProvider } from '@mui/system';
import { Button } from '@mui/material';
import { FilterDrawer } from './copmonents';
import { ComparisonTableComponent } from './copmonents/ComparisonTable';
import { Routes, Route, Link, useNavigate } from 'react-router-dom'
import Faq from './copmonents/FAQ/Faq';
import Calculators from './copmonents/Calculator/Calculators';
import OtherResources from './copmonents/OtherResources/OtherResources';
import Settings from './copmonents/Settings/index';
import { useState } from 'react';
import {ReactComponent as FilterIcon} from './assets/images/filters.svg';
import {ReactComponent as MenuIcon} from './assets/images/menu.svg';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

const drawerWidth = 370;

export const API_URL = "https://usawinemarkettool.com";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "calc(100vh - 154px)",
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: "-27.5vw",
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "calc(100% - 27.5vw)",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    marginRight: "27.5vw"
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export function PersistentDrawerRight() {
  const [open, setOpen] = React.useState(window.matchMedia('(orientation: landscape)').matches ? true : false);
  const [distData, setDistData] = React.useState([[], false])
  const [heatData, setHeatData] = React.useState([])
  const [filterChange, setFilterChange] = React.useState(false);
  const [stateData, setStateData] = React.useState([])
  const [selectedStates, setSelectedStates] = React.useState([])
  const [formData, setFormData] = React.useState({})
  const [selectedPage, setSelectedPage] = React.useState("heat");
  const [selectedResultsList, setSelectedResultsList] = React.useState("results");
  const [forceUpdate, setForceUpdate] = React.useState(0);
  const [showComparisonTable, setShowComparisonTable] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFilter, setOpenFilter]  = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [showAddedToComparisonPopup, setShowAddedToComparisonPopup] = React.useState(false);
  const [faveStates, setFaveStates] = useState(localStorage.getItem("favStates"));
  const navigate = useNavigate();

  React.useEffect(() => {
    fetch(`${API_URL}/api/states`)
      .then(res => res.json())
      .then(res => {
        setStateData(res.data);
        if(localStorage.getItem("favStates") !== null) {
          setFaveStates(localStorage.getItem("favStates"));
          let faves = faveStates.split(","),
          temp =[];
          for (let i = 0; i < faveStates.split(",").length; i++) {
            if(!Number.isNaN(parseInt(faves[i]))){
              temp.push(faves[i])
            }
          }
          setSelectedStates(temp)
          setForceUpdate(Math.random())
          
        }
        
        handleFilterApply()
      })
  }, []);
  
  const handleSetData = (data) => {
    setFormData(data)
  }

  const handleBoxClick = (state, SetSelectedState) => {
    SetSelectedState("geo-" + (state.FIPSState < 10 ? "0" : "") + state.FIPSState);
    selectedPage !== "dist" && setSelectedPage("dist")
  }
  const clicked = () => {
    setFilterChange(!filterChange);
  }
  const handleFilterApply = () => {
    if (selectedPage === "dist") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      for(let field of Object.keys(formData)){
        urlencoded.append(field, formData[field]);
      } 

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      fetch(`${API_URL}/api/filters/distribution-filter`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setDistData([result.data, true])
        })
        .catch(error => console.log('error', error));
    }
  }
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMobileMenu = () => {
    setOpenFilter(false);
    setOpenMenu(!openMenu);
  }

  const openMobileFilter = () => {
    setOpenMenu(false);
    setOpenFilter(!openFilter);
  }

  var user = navigator.userAgent;
  React.useEffect(() => {
    if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
      if (window.matchMedia('(orientation: portrait)').matches) {
        setOpenMenu(false);
        setOpenFilter(false);
      }
    }
  }, [filterChange])
  
  return (
    <div className='app'>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', height: "100%" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} sx={{ boxShadow: "none", background: theme.palette.background.default, paddingTop: '5px' }}>
            <Toolbar className='topBar'>
              <Link to="/" onClick={() => setOpenMenu(false)}><img className='logo' src="/images/AGW.png"/></Link>
              {/*<Link to="/" onClick={() => setOpenMenu(false)}><img className='logo' src="/images/HYDRA+LOGO_CMYK transparent.png"/></Link>*/}
              <FilterIcon className='filterIcon' onClick={openMobileFilter}/>
              <MenuIcon className='mobileHamburger' onClick={openMobileMenu}/>
              <Button className='topBarButton' onClick={()=> navigate("/")} sx={{  display: "flex", color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap sx={{color: "black" }} component="div">
                  <Link to="/" className="link">Home</Link>
                </Typography>
              </Button>
              <Button className='topBarButton' onClick={()=> navigate("/calculators")} sx={{  display: "flex", color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap sx={{color: "black" }} component="div">
                  <Link to="/calculators" className="link">US retail price calculators</Link>
                </Typography>
              </Button>
              <Button className='topBarButton' onClick={()=> navigate("/otherresources")} sx={{ display: "flex",  color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ display: "flex", color: "black" }} >
                  <Link to="/otherresources" className="link">Other Resources</Link>
                </Typography>
              </Button>
              <Button className='topBarButton' onClick={()=> navigate("/faq")} sx={{ display: "flex", textAlign: 'left', color: '#fefefe'}} >
                <Typography className='topBarText' variant="body1" noWrap sx={{ Wcolor: "black" }} component="div">
                  <Link to="/faq" className="link">FAQ</Link>
                </Typography>
              </Button>
              <Typography sx={{flexGrow: "1", display: "flex", textAlign: 'left' }}></Typography>
              <Button className={(open ? "openDrawer topBarButton" : "closeDrawer topBarButton")} sx={{ color: '#fefefe', zIndex: 87, transition: "100ms", transform: (open ? "translateX(130px)" : "none") }} onClick={!open ? handleDrawerOpen : handleDrawerClose}>
                <DoubleArrowIcon className='doubleArrow' sx={{ color: "black", transform: (!open && "rotate(180deg)"), height: "20px" }} />
                <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ color: "black", display: "flex", fontWeight: "bold" }}>
                  Filters
                </Typography>
              </Button>
            </Toolbar>
          </AppBar>
          <Main className="Container" open={open} sx={{ paddingLeft: '0', paddingRight: '0' }}>
            <div className={openMenu ? 'mobileMenu active' : 'mobileMenu'}>
              <Button className='mobileMenuButton' onClick={()=> navigate("/")} sx={{  display: "flex", color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap sx={{color: "black" }} component="div">
                  <Link to="/" className="link" onClick={() => setOpenMenu(false)}>Home</Link>
                </Typography>
              </Button>
              <Button className='mobileMenuButton' onClick={()=> navigate("/calculators")} sx={{  display: "flex", color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap sx={{color: "black" }} component="div">
                  <Link to="/calculators" className="link">US retail price calculators</Link>
                </Typography>
              </Button>
              <Button className='mobileMenuButton' onClick={()=> navigate("/otherresources")} sx={{ display: "flex",  color: '#fefefe' }} >
                <Typography className='topBarText' variant="body1" noWrap component="div" sx={{ display: "flex", color: "black" }} >
                  <Link to="/otherresources" className="link">Other Resources</Link>
                </Typography>
              </Button>
              <Button className='mobileMenuButton' onClick={()=> navigate("/faq")} sx={{ display: "flex", textAlign: 'left', color: '#fefefe'}} >
                <Typography className='topBarText' variant="body1" noWrap sx={{ Wcolor: "black" }} component="div">
                  <Link to="/faq" className="link">FAQ</Link>
                </Typography>
              </Button>
            </div>
            <DrawerHeader className='drawerHeader'/>
            <MapPage 
              selectedResultsList={selectedResultsList} 
              setSelectedResultsList={setSelectedResultsList} 
              forceUpdate={forceUpdate} 
              setForceUpdate={setForceUpdate} 
              selectedStates={selectedStates} 
              setSelectedStates={(val, addOrRemove) => {
                setSelectedStates(val);
                if(addOrRemove == "add") setShowAddedToComparisonPopup(true);
              }} 
              handleBoxClick={handleBoxClick} 
              drawerWidth={drawerWidth} 
              drawerOpen={open} 
              stateData={stateData} 
              formData={formData} 
              distData={distData} 
              heatData={heatData} 
              filter={filterChange} 
              selectedPage={selectedPage} 
              setIsLoading={setIsLoading}
              setShowComparisonTable={(val) => setShowComparisonTable(val)}
            />
          </Main>

          <FilterDrawer 
            formData={formData} 
            setFilterChange={clicked} 
            setFormData={handleSetData} 
            setSelectedPage={setSelectedPage} 
            selectedPage={selectedPage} 
            open={open} 
            handleDrawerClose={handleDrawerClose} 
            handleDrawerOpen={handleDrawerOpen} 
            drawerWidth={drawerWidth} 
            DrawerHeader={DrawerHeader} 
            mobileOpen = {openFilter}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          <ComparisonTableComponent 
            display={showComparisonTable} 
            selectedStates={selectedStates}
            stateData={stateData}
            setShowComparisonTable={(val) => setShowComparisonTable(val)} 
            setSelectedStates={(val) => setSelectedStates(val)}
          />
        </Box>
        <Paper className='footer' sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation className='footerNav' showLabels>
            <BottomNavigationAction className='footerNavAct' label="Developed for Australian wine in partnership with Hydra Consulting | 2022" />  
            </BottomNavigation>
        </Paper>
      </ThemeProvider >
    </div>
  );
}

const App = () => {
  return (
    <Routes>
      <Route exact path='/' element={<PersistentDrawerRight/>}></Route>
      <Route path='/faq' element={<Faq/>}/>
      <Route path='/calculators' element={<Calculators/>}/>
      <Route path='/otherresources' element={<OtherResources/>}/>
      <Route path='/settings' element={<Settings/>}/>
    </Routes>
  )
}

export default App